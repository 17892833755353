import { LocalizationContext } from 'Services/LocalizationService';
import { lazy, Suspense, useContext } from 'react';
const OrdersIcon = lazy(() => import('Components/Icons/Orders'));
const AddressesIcon = lazy(() => import('Components/Icons/Addresses'));
const FavoritesIcon = lazy(() => import('Components/Icons/Favorites'));
const SettingsIcon = lazy(() => import('Components/Icons/Settings'));
import Loader from 'Components/View/Loader';
import ThreeBottlesIcon from 'Components/Icons/ThreeBottles';

import { UserSectionLink } from './interfaces';
import PercentIcon from './ClubDiscounts/PercentIcon/PercentIcon';

const useUserSectionLinks = (isLoggedIn: boolean): UserSectionLink[] => {
	const t = useContext(LocalizationContext).useFormatMessage();

	if (isLoggedIn) {
		return [
			{
				route: `${t({ id: 'routes.user-section.club-coupons.route' })}`,
				name: `${t({ id: 'routes.user-section.club-coupons.name' })}`,
				icon: (
					<Suspense fallback={<Loader blank />}>
						<PercentIcon />
					</Suspense>
				),
				flags: ['clubCoupons'],
			},
			{
				route: `${t({ id: 'routes.user-section.orders.route' })}`,
				name: `${t({ id: 'routes.user-section.myOrders.name' })}`,
				icon: (
					<Suspense fallback={<Loader blank />}>
						<OrdersIcon />
					</Suspense>
				),
			},
			{
				route: `${t({ id: 'routes.user-section.bought-products.route' })}`,
				name: `${t({ id: 'routes.user-section.bought-products.name' })}`,
				icon: (
					<Suspense fallback={<Loader blank />}>
						<ThreeBottlesIcon />
					</Suspense>
				),
			},
			{
				route: `${t({ id: 'routes.user-section.favorites.route' })}`,
				name: `${t({ id: 'routes.user-section.favorites.name' })}`,
				icon: (
					<Suspense fallback={<Loader blank />}>
						<FavoritesIcon />
					</Suspense>
				),
			},
			{
				route: `${t({ id: 'routes.user-section.addresses.route' })}`,
				name: `${t({ id: 'routes.user-section.addresses.name' })}`,
				icon: (
					<Suspense fallback={<Loader blank />}>
						<AddressesIcon />
					</Suspense>
				),
			},
			{
				route: `${t({ id: 'routes.user-section.settings.route' })}`,
				name: `${t({ id: 'routes.user-section.settings.name' })}`,
				icon: (
					<Suspense fallback={<Loader blank />}>
						<SettingsIcon />
					</Suspense>
				),
			},
		];
	}

	return [
		{
			route: `${t({ id: 'routes.user-section.favorites.route' })}`,
			name: `${t({ id: 'routes.user-section.favorites.name' })}`,
			icon: (
				<Suspense fallback={<Loader blank />}>
					<FavoritesIcon />
				</Suspense>
			),
		},
	];
};

export default useUserSectionLinks;
