import cx from 'classnames';
import { Link } from 'react-router-dom';
import { isExternalLink } from 'vinisto_shared';

import { TopBannerProps } from './interfaces';
import styles from './styles.module.css';

const TopBanner = ({
	url,
	title,
	subtitle,
	imageOriginalUrl,
	srcSet,
	ctaLabel,
	cardOrder,
	isMatchingNarrowestBreakpoint,
	setLoadedImages,
}: TopBannerProps) => {
	const bannerContent = (
		<div
			className={cx(styles.card, {
				[styles.primaryCard]: cardOrder === 1 || !cardOrder,
				[styles.secondaryCard]: cardOrder > 1,
			})}
		>
			{
				<div className={styles.heading}>
					{title && <div className={styles.title}>{title}</div>}
					{subtitle && <div className={styles.subtitle}>{subtitle}</div>}
				</div>
			}

			<div className={styles.imgContainer}>
				<img
					srcSet={srcSet}
					sizes={`(max-width: 1199.98px) 500px, (min-width: 1200px) ${
						cardOrder === 1 ? '950px' : '500px'
					},  100vw`}
					src={imageOriginalUrl}
					className={styles.img}
					alt=""
					{...(cardOrder === 1 && { fetchpriority: 'high' })}
					{...(isMatchingNarrowestBreakpoint &&
						cardOrder !== 1 && { loading: 'lazy' })}
					onLoad={() => setLoadedImages?.((prev) => prev.map(() => true))}
				/>
			</div>

			{ctaLabel && <span className={styles.cta}>{ctaLabel}</span>}
		</div>
	);

	return isExternalLink(url) ? (
		<a
			href={url}
			className={cx(styles.card)}
			target="_blank"
			rel="noreferrer"
		>
			{bannerContent}
		</a>
	) : (
		<Link
			to={url}
			className={cx(styles.card)}
		>
			{bannerContent}
		</Link>
	);
};

export default TopBanner;
