import { lazy, Suspense, useContext, useEffect, useMemo } from 'react';
import { forEach, get, isArray, map, set } from 'lodash-es';
import {
	Navigate,
	RouteObject,
	useLocation,
	useRoutes,
} from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { LOGIN_MODAL, REGISTRATION_MODAL } from 'Components/Modal/constants';
import { GA_EVENT } from 'Hooks/useAnalytics/constants';
import useAnalytics from 'Hooks/useAnalytics';
import { DocumentHeaderContext } from 'Components/DocumentHeader/context';
import NavbarContextProvider from 'Components/Navbar/context';
import { LocalizationContext } from 'Services/LocalizationService';
import BasketServiceProvider from 'Services/BasketService';
import GoPayServiceProvider from 'Services/GoPayService';
import Modal from 'Components/Modal';
import StorageUTM from 'Services/RoutingService/Components/StorageUTM';
import CookieServiceProvider from 'Services/CookieService';
import RequireNoAuth from 'Services/AuthorizationService/requireNoAuth';
import RequireAuth from 'Services/AuthorizationService/requireAuth';
import BottomNavigation from 'Components/BottomNavigation';
import Footer from 'Components/Footer';
import Navbar from 'Components/Navbar';
import Advisor from 'Components/Advisor';
import HomePage from 'Pages/Home';
import AboutUs from 'Pages/AboutUs';
import Promo from 'Pages/Promo';
import Category from 'Pages/Category';
import Tag from 'Pages/Tag';
import Wines from 'Pages/Wines';
import NotFoundPage from 'Pages/NotFound';
import AuthorizationService from 'Services/AuthorizationService';
import CrossSell from 'Pages/CrossSell';
import Product from 'Pages/Bundle';
import SellerLanding from 'Pages/SellerLanding';
import Contest from 'Pages/Contest';
import ContestEaster from 'Pages/ContestEaster';
import Blog from 'Pages/Blog';
import TermsAndConditions from 'Pages/TermsAndConditions';
import PaymentOptions from 'Pages/PaymentOptions';
import DeliveryOptions from 'Pages/DeliveryOptions';
import DataProtection from 'Pages/DataProtection';
import ComplaintsReturns from 'Pages/ComplaintsReturns';
import Faq from 'Pages/Faq';
import FaqSeller from 'Pages/FaqSeller';
import Contact from 'Pages/Contact';
import BecomeSeller from 'Pages/BecomeSeller';
import Producers from 'Pages/Producers';
import UserSection from 'Pages/UserSection';
import UserSectionOrders from 'Pages/UserSection/Orders';
import UserSectionAddresses from 'Pages/UserSection/Addresses';
import UserSectionFavorites from 'Pages/UserSection/Favorites';
import UserSectionSettings from 'Pages/UserSection/Settings';
import UserSectionBoughtProducts from 'Pages/UserSection/BoughtProducts';
// import UserSectionClubDiscounts from 'Pages/UserSection/ClubDiscounts';
import ConfirmEmail from 'Pages/ConfirmEmail';
import ResetPassword from 'Pages/ResetPassword';
import Cart from 'Pages/Cart';
import CartShippingPayment from 'Pages/CartShippingPayment';
import CartShippingData from 'Pages/CartShippingData';
import CartConfirmation from 'Pages/CartConfirmation';
import Search from 'Pages/Search';
import Vinistoteque from 'Pages/Vinistoteque';
import SommelierAdvice from 'Pages/SommelierAdvice';
import UserSectionDashboard from 'Pages/UserSection/Dashboard';
import UserSectionClubDiscounts from 'Pages/UserSection/ClubDiscounts';
import PromoPage from 'Pages/Promo';

import OpenModal from './Components/OpenModal';
import TranslateURLOnLanguageChange from './Components/TranslateURLOnLanguageChange';
import ScrollToTop from './Components/ScrollToTop';
import { IRouteProps } from './interfaces';

const ComponentTestingPage = lazy(() => import('Pages/ComponentTestingPage'));

/**
 * Takes custom routes and converts them recursively to React Router DOM routes
 * @param {IRouteProps[]} routes Custom routes
 * @returns {RouteObject[]} React Router DOM compatible routes
 */
const createRoutes = (routes: IRouteProps[]): RouteObject[] => {
	// Recursive iterator
	const loopOverRoutes = (routes: IRouteProps[]): RouteObject[] => {
		// Create empty new routes array
		const newRoutes: any[] = [];
		// Add routes to newRoutes array based on path property
		forEach(routes, (route: IRouteProps) => {
			if (isArray(get(route, 'path', undefined))) {
				// If path is array, create route for each path
				newRoutes.push(
					...map(get(route, 'path', []), (path: string) => ({
						...route,
						path,
					}))
				);
			} else {
				newRoutes.push({ ...route });
			}
		});
		// If children are setted, iterate over them and repeat
		forEach(newRoutes, (route) => {
			if (get(route, 'children', false)) {
				set(route, 'children', loopOverRoutes(get(route, 'children')));
			}
		});
		return newRoutes;
	};
	return loopOverRoutes(routes);
};

const uiUrls = { UI_PRODUCT_BOX: '/ui/product-box' };

const Routing = () => {
	const localizationContext = useContext(LocalizationContext);
	const tAll = localizationContext.useFormatMessageAllStrings();
	const t = localizationContext.useFormatMessage();
	const { pushToDataLayer } = useAnalytics();
	const documentHeaderContext = useContext(DocumentHeaderContext);

	const location = useLocation();

	useEffect(() => {
		pushToDataLayer({
			event: GA_EVENT.PAGE_VIEW,
			page_title: documentHeaderContext.state.title,
			page_location: window.location.href,
			page_path: location.pathname,
		});
		// disabling rules because we don't care about title change, location is enough
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname, pushToDataLayer]);

	const routes = useMemo(
		() =>
			createRoutes([
				{
					path: '/',
					element: <HomePage />,
					children: [
						{
							path: tAll({ id: 'routes.advisor.route' }),
							element: <Advisor />,
						},
					],
				},
				// Search
				{
					path: tAll({ id: 'routes.search.route' }),
					children: [
						{ path: '', element: <Navigate to={'/'} /> },
						{ path: ':searchTerm', element: <Search /> },
					],
				},
				// User Section
				{
					path: tAll({ id: 'routes.user-section.route' }),
					element: <UserSection />,
					children: [
						{
							path: tAll({
								id: 'routes.user-section.club-coupons.route',
							}),
							element: (
								<RequireAuth>
									<UserSectionClubDiscounts />
								</RequireAuth>
							),
						},
						{
							path: tAll({
								id: 'routes.user-section.orders.route',
							}),
							element: (
								<RequireAuth>
									<UserSectionOrders />
								</RequireAuth>
							),
						},
						{
							path: tAll({
								id: 'routes.user-section.bought-products.route',
							}),
							element: (
								<RequireAuth>
									<UserSectionBoughtProducts />
								</RequireAuth>
							),
						},
						{
							path: tAll({
								id: 'routes.user-section.addresses.route',
							}),
							element: (
								<RequireAuth>
									<UserSectionAddresses />
								</RequireAuth>
							),
						},
						{
							path: tAll({
								id: 'routes.user-section.favorites.route',
							}),
							element: <UserSectionFavorites />,
						},
						{
							path: tAll({
								id: 'routes.user-section.settings.route',
							}),
							element: (
								<RequireAuth>
									<UserSectionSettings />
								</RequireAuth>
							),
						},
						{
							path: '',
							element: (
								<RequireAuth>
									<UserSectionDashboard />
								</RequireAuth>
							),
						},
					],
				},
				// Reset Password
				{
					path: tAll({ id: 'routes.resetPassword.route' }),
					children: [
						{ path: '', element: <Navigate to={'/'} /> },
						{ path: ':resetHash', element: <ResetPassword /> },
					],
				},
				// Cross sell
				{
					path: tAll({ id: 'routes.crossSell.route' }),
					children: [
						{ path: '', element: <Navigate to={'/'} /> },
						{ path: ':itemUrl', element: <CrossSell /> },
					],
				},
				// Cart
				{ path: tAll({ id: 'routes.cart.route' }), element: <Cart /> },
				{
					path: tAll({ id: 'routes.cart.shippingPayment.route' }),
					element: <CartShippingPayment />,
				},
				{
					path: tAll({ id: 'routes.cart.shipping.route' }),
					element: <CartShippingData />,
				},
				{
					path: tAll({ id: 'routes.cart.confirmation.route' }),
					element: <CartConfirmation />,
				},
				// Category
				{
					path: tAll({ id: 'routes.category.route' }),
					children: [
						{ path: ':categoryUrl/*', element: <Category /> },
						{ path: '', element: <Navigate to={'/'} /> },
					],
				},
				// Confirm e-mail
				{
					path: tAll({ id: 'routes.confirmEmail.route' }),
					children: [
						{ path: ':hash', element: <ConfirmEmail /> },
						{ path: '', element: <Navigate to={'/'} /> },
					],
				},
				// Product
				{
					path: tAll({ id: 'routes.product.route' }),
					children: [
						{ path: ':itemUrl', element: <Product /> },
						{ path: '', element: <Navigate to={'/'} /> },
					],
				},
				// Top Nav
				{
					path: tAll({ id: 'routes.aboutUs.route' }),
					element: <AboutUs />,
				},
				{
					path: tAll({ id: 'routes.promo.route' }),
					element: <PromoPage />,
				},
				{
					path: tAll({ id: 'routes.login.route' }),
					element: (
						<>
							<RequireNoAuth>
								<OpenModal modalName={LOGIN_MODAL} />
							</RequireNoAuth>
							<Navigate
								to={'/'}
								replace
							/>
						</>
					),
				},
				{
					path: tAll({ id: 'routes.registration.route' }),
					element: (
						<>
							<RequireNoAuth>
								<OpenModal modalName={REGISTRATION_MODAL} />
							</RequireNoAuth>
							<Navigate
								to={'/'}
								replace
							/>
						</>
					),
				},
				// Main Nav
				{
					path: tAll({ id: 'routes.news.route' }),
					children: [
						{
							path: '',
							element: <Tag tagUrl={`${t({ id: 'routes.news.route' })}`} />,
						},
						{
							path: '*',
							element: <Tag tagUrl={`${t({ id: 'routes.news.route' })}`} />,
						},
					],
				},
				{
					path: tAll({ id: 'routes.offers.route' }),
					children: [
						{
							path: '',
							element: (
								<Tag
									tagUrl={`${t({
										id: 'routes.offers.route',
									})}`}
								/>
							),
						},
						{
							path: '*',
							element: (
								<Tag
									tagUrl={`${t({
										id: 'routes.offers.route',
									})}`}
								/>
							),
						},
					],
				},
				{
					path: tAll({ id: 'routes.collectibleWines.route' }),
					children: [
						{
							path: '',
							element: (
								<Tag
									tagUrl={`${t({
										id: 'routes.collectibleWines.route',
									})}`}
								/>
							),
						},
						{
							path: '*',
							element: (
								<Tag
									tagUrl={`${t({
										id: 'routes.collectibleWines.route',
									})}`}
								/>
							),
						},
					],
				},
				{
					path: tAll({ id: 'routes.producers.route' }),
					element: <Producers />,
				},
				{
					path: tAll({ id: 'routes.community.route' }),
					element: <Blog />,
					children: [
						{ path: ':comunityArticleDetailUrl/*', element: <Blog /> },
						{ path: '', element: <Navigate to={'/'} /> },
					],
				},
				{
					path: tAll({ id: 'routes.community.tagList.route' }),
					children: [
						{ path: ':comunityTagUrl', element: <Blog /> },
						{ path: '', element: <Navigate to={'/'} /> },
					],
				},
				{
					path: tAll({ id: 'routes.contact.route' }),
					element: <Contact />,
				},
				// Tag
				{
					path: tAll({ id: 'routes.tag.route' }),
					children: [
						{ path: ':tagUrl/*', element: <Tag /> },
						{ path: '', element: <Navigate to={'/'} /> },
					],
				},
				// Products
				{
					path: tAll({ id: 'routes.products.route' }),
					children: [
						{ path: '*', element: <Wines /> },
						{ path: '', element: <Wines /> },
					],
				},
				// Landing Pages
				{
					path: tAll({ id: 'routes.sellerLanding.route' }),
					element: <SellerLanding />,
				},
				{
					path: tAll({ id: 'routes.contest.route' }),
					element: <Contest />,
				},
				{
					path: tAll({ id: 'routes.easterContest.route' }),
					element: <ContestEaster />,
				},
				// Footer
				{
					path: tAll({ id: 'routes.delivery.route' }),
					element: <DeliveryOptions />,
				},
				{
					path: tAll({ id: 'routes.payments.route' }),
					element: <PaymentOptions />,
				},
				{
					path: tAll({ id: 'routes.termsAndConditions.route' }),
					element: <TermsAndConditions />,
				},
				{
					path: tAll({ id: 'routes.privacyProtection.route' }),
					element: <DataProtection />,
				},
				{
					path: tAll({ id: 'routes.returns.route' }),
					element: <ComplaintsReturns />,
				},
				{ path: tAll({ id: 'routes.faq.route' }), element: <Faq /> },
				{
					path: tAll({ id: 'routes.faqSeller.route' }),
					element: <FaqSeller />,
				},
				{
					path: tAll({ id: 'routes.becomeSupplier.route' }),
					element: <BecomeSeller />,
				},
				{
					path: tAll({ id: 'routes.vinistoteque.route' }),
					element: <Vinistoteque />,
				},
				{
					path: tAll({ id: 'routes.sommelierAdvice.route' }),
					element: <SommelierAdvice />,
					children: [
						{
							path: tAll({ id: 'routes.advisor.route' }),
							element: <Advisor />,
						},
					],
				},

				// Error Pages
				{ path: '/404', element: <NotFoundPage /> },
				// Promo or not found pages
				{ path: '*', element: <Promo /> },
				// Component Testing
				{
					path: uiUrls.UI_PRODUCT_BOX,
					element: (
						<Suspense fallback={<>Loading</>}>
							<ComponentTestingPage />
						</Suspense>
					),
				},
			]),
		[t, tAll]
	);

	return useRoutes(routes);
};

/**
 * Browser Router Service
 * @class RoutingService
 * @return JSX Router Component
 */
const RoutingService = () => {
	// Check if we are on UI page
	for (const key in uiUrls) {
		if (window.location.pathname === uiUrls[key as keyof typeof uiUrls]) {
			return (
				<NavbarContextProvider>
					<ScrollToTop />
					<StorageUTM />
					<TranslateURLOnLanguageChange />
					<Routing />
				</NavbarContextProvider>
			);
		}
	}

	return (
		<NavbarContextProvider>
			<Navbar />
			<BottomNavigation />
			<ScrollToTop />
			<StorageUTM />
			<TranslateURLOnLanguageChange />
			<Routing />
			<Footer />
		</NavbarContextProvider>
	);
};

/**
 * Browser Router High Order Wrapper
 * @class ConnectedBrowserRouter
 * @return JSX Browser Router Component
 */
const ConnectedBrowserRouter = () => {
	return (
		<QueryParamProvider adapter={ReactRouter6Adapter}>
			<AuthorizationService>
				<BasketServiceProvider>
					<GoPayServiceProvider>
						<CookieServiceProvider>
							<Modal />
							<RoutingService />
						</CookieServiceProvider>
					</GoPayServiceProvider>
				</BasketServiceProvider>
			</AuthorizationService>
		</QueryParamProvider>
	);
};

export default ConnectedBrowserRouter;
