import {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import cx from 'classnames';
import AliceCarousel from 'react-alice-carousel';
import Skeleton from 'react-loading-skeleton';
import { DeviceServiceContext } from 'Services/DeviceService';
import Config from 'Config';
import { Banner } from 'Services/Banner/interfaces';

import TopBanner from '..';

import {
	CAROUSEL_MODE_BREAKPOINT,
	CONTAINER_PADDING_X,
	PRIMARY_CARD_ASPECT_RATIO,
} from './constants';
import styles from './styles.module.css';

interface CarouselProps {
	carouselType: string;
	data?: Banner[] | undefined;
	isLoading?: boolean;
}

const BannerCarousel = ({ carouselType, data, isLoading }: CarouselProps) => {
	const { layoutWidth } = useContext(DeviceServiceContext);

	const isMatchingNarrowestBreakpoint = layoutWidth <= 500;

	// Reference: https://maxmarinich.github.io/react-alice-carousel/#stage-padding-percents
	const section = useRef<HTMLDivElement | null>(null);
	const [padding, setPadding] = useState(0);
	const [loadedImages, setLoadedImages] = useState(() =>
		(data ?? []).map(() => false)
	);

	const syncState = useCallback(() => {
		const { current } = section;
		if (current) {
			setPadding(
				(current.offsetWidth - CAROUSEL_MODE_BREAKPOINT + CONTAINER_PADDING_X) /
					2
			);
		}
	}, []);

	useEffect(syncState, [syncState]);

	const currentCarouselConfig = useMemo(
		() =>
			Config?.carousels?.[carouselType as keyof (typeof Config)['carousels']] ??
			{},
		[carouselType]
	);

	const handleOnLoadImage = useCallback(
		(i: number) => () => {
			setLoadedImages((prev) =>
				prev.map((loaded, index) => (index === i ? true : loaded))
			);
		},
		[]
	);

	const items = useMemo(() => {
		if (isLoading)
			return new Array(3).fill(() => (
				<Skeleton style={{ aspectRatio: PRIMARY_CARD_ASPECT_RATIO }} />
			));
		return (
			data?.map((banner, index) => (
				<TopBanner
					{...banner}
					key={`${banner.title}-${banner.position}-${index}`}
					cardOrder={index + 1}
					isMatchingNarrowestBreakpoint={isMatchingNarrowestBreakpoint}
					setLoadedImages={handleOnLoadImage(index)}
				/>
			)) ?? []
		);
	}, [isLoading, data, isMatchingNarrowestBreakpoint, handleOnLoadImage]);

	const isAllImagesLoaded = loadedImages.every(Boolean);

	const memoizedCarousel = useMemo(
		() => (
			<AliceCarousel
				{...currentCarouselConfig}
				disableDotsControls={false}
				mouseTracking
				controlsStrategy="alternate"
				infinite
				paddingRight={isMatchingNarrowestBreakpoint ? undefined : padding}
				paddingLeft={isMatchingNarrowestBreakpoint ? undefined : padding}
				onResized={syncState}
				items={items}
				autoPlay={isAllImagesLoaded}
			/>
		),
		[
			currentCarouselConfig,
			isMatchingNarrowestBreakpoint,
			items,
			padding,
			syncState,
			isAllImagesLoaded,
		]
	);

	return (
		<div
			ref={section}
			className={cx('TopBannerCarousel', styles.topBannerCarousel)}
		>
			{memoizedCarousel}
		</div>
	);
};

export default BannerCarousel;
