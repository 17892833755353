import { useCallback, useContext } from 'react';
import { get } from 'lodash-es';
import { LoginFormData } from 'Components/Modal/Components/Login/interfaces';
import { LOGIN_PASSWORD_TYPE } from 'Components/Form/Components/Password/constants';
import { requireEmail } from 'Components/Form/validators';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { LocalizationContext } from 'Services/LocalizationService';
import { Form, InputEmail, InputPassword } from 'Components/Form';
import { VinistoSpanLink } from 'Components/VinistoLink';
import useLoginRedirect from 'Hooks/useLoginRedirect';
import { useNavigate } from 'react-router-dom';
import OAuthButton from 'vinisto_ui/src/components/oauth-button';
import { NotificationsContext } from 'Services/NotificationService';
import { useOAuthLogin } from 'vinisto_shared/src/oauth';

import { ModalContext } from '../../context';
import * as modalTypes from '../../constants';

import RegisterCta from './RegisterCta';
import styles from './styles.module.css';

import {
	VinistoHelperDllEnumsUserLoginHashType,
	VinistoHelperDllEnumsUserTokenType,
} from '@/api-types/user-api';

const isOauthFacebookEnabled =
	import.meta.env.VITE_OAUTH_FACEBOOK_ENABLED === 'true';

const isOauthGoogleEnabled =
	import.meta.env.VITE_OAUTH_GOOGLE_ENABLED === 'true';

const isOauthEnabled = isOauthFacebookEnabled || isOauthGoogleEnabled;

const LoginModal = () => {
	const modalContext = useContext(ModalContext);
	const modalData = modalContext.modalData ?? {};
	const authenticationContext = useContext(AuthenticationContext);
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const navigateFromAuthRequired = modalData?.navigateFromAuthRequired ?? false;
	const onCloseCallback = modalContext?.modalData?.onCloseCallback;
	const loginRedirect = useLoginRedirect(navigateFromAuthRequired);
	const navigate = useNavigate();

	const { handleOnOAuthLogIn } = useContext(AuthenticationContext);

	localizationContext.countryOfSale;

	const { handleShowErrorNotification } = useContext(NotificationsContext);

	const { handleGoogleLogin, handleFacebookLogin } = useOAuthLogin({
		requestData: {
			hashType: VinistoHelperDllEnumsUserLoginHashType.SHOP,
			countryOfSale: localizationContext.countryOfSale,
			currency: localizationContext.activeCurrency.currency,
			language: localizationContext.activeLanguageKey,
		},
		onSuccess: handleOnOAuthLogIn,
		onError: () => {
			handleShowErrorNotification('notification.message.logIn.error');
		},
	});

	const { showRegisterCta = true, showForgottenPasswordLink = true } =
		modalData;

	const handleOnLogIn = useCallback(
		(formValues: LoginFormData) => {
			authenticationContext.handleOnLogIn(
				formValues.email,
				formValues.password
			);
			loginRedirect();
		},
		[authenticationContext, loginRedirect]
	);

	const handleOpenRegistrationModal = useCallback(() => {
		const { REGISTRATION_MODAL } = modalTypes;

		modalContext.handleCloseModal();
		if (typeof onCloseCallback === 'function') {
			navigate(-1);
		}
		setTimeout(() => {
			modalContext.handleOpenModal(REGISTRATION_MODAL, {});
		}, 200);
	}, [modalContext, navigate, onCloseCallback]);

	const handleOpenForgottenModal = useCallback(() => {
		const { FORGOTTEN_PASSWORD_MODAL } = modalTypes;
		modalContext.handleCloseModal();
		if (typeof onCloseCallback === 'function') {
			navigate(-1);
		}
		setTimeout(() => {
			modalContext.handleOpenModal(FORGOTTEN_PASSWORD_MODAL, {});
		}, 200);
	}, [modalContext, navigate, onCloseCallback]);

	return (
		<div className="vinisto-popup__split">
			<div className="vinisto-popup__split__form">
				<Form
					submitCallback={handleOnLogIn}
					submitText={'modal.logIn.submitButtonText'}
					initializationValues={{
						email: get(modalContext, 'modalData.email', ''),
					}}
				>
					<div className="mb-3">
						<InputEmail validate={requireEmail} />
						<InputPassword type={LOGIN_PASSWORD_TYPE} />
					</div>
				</Form>
				<button
					onClick={handleOpenRegistrationModal}
					className="vinisto-btn vinisto-bg-outline-green tablet-mobile-only w-100 mt-3"
				>
					{t({ id: 'modal.registration.submitButtonText' })}
				</button>
				{isOauthEnabled && (
					<div className={styles.oauthWrapper}>
						<div className={styles.oauthTextWrapper}>
							<span>{t({ id: 'modal.logIn.oauth.text' })}</span>
						</div>
						<div className={styles.oauthButtonsWrapper}>
							{isOauthGoogleEnabled && (
								<OAuthButton
									onClick={() => handleGoogleLogin()}
									provider={VinistoHelperDllEnumsUserTokenType.Google}
								/>
							)}
							{isOauthFacebookEnabled && (
								<OAuthButton
									onClick={() => handleFacebookLogin()}
									provider={VinistoHelperDllEnumsUserTokenType.Facebook}
								/>
							)}
						</div>
					</div>
				)}
				{showForgottenPasswordLink && (
					<div className="vinisto-popup__links vinisto-popup__links--login">
						<VinistoSpanLink
							className="vinisto-popup__link--gray"
							onClick={handleOpenForgottenModal}
						>
							{t({ id: 'modal.logIn.moveForgottenPasswordButton' })}
						</VinistoSpanLink>
					</div>
				)}
			</div>
			{showRegisterCta && (
				<RegisterCta
					handleOpenRegistrationModal={handleOpenRegistrationModal}
				/>
			)}
		</div>
	);
};

export default LoginModal;
