import { v4 as uuidv4 } from 'uuid';

import Api from '@/strapi-api/client';

export const getBundleNotes = async (bundleId: string, userEmail: string) => {
	const data = await Api.bundleNotes.getBundleNotes({
		// @ts-expect-error wrongly typed
		['filters[bundleId][$eq]']: bundleId,
		['filters[userEmail][$eq]']: userEmail,
		sort: 'createdAt:desc',
	});

	return data.data.data;
};

export const createBundleNote = async (
	bundleId: string,
	userEmail: string,
	note: string
) => {
	const data = await Api.bundleNotes.postBundleNotes({
		data: {
			bundleId,
			note,
			userEmail,
			noteId: uuidv4(),
		},
	});

	return data.data.data;
};

export const updateBundleNote = async (
	id: number,
	bundleId: string,
	userEmail: string,
	note: string
) => {
	const data = await Api.bundleNotes.putBundleNotesId(id, {
		data: {
			bundleId,
			note,
			userEmail,
		},
	});

	return data.data.data;
};

export const deleteBundleNote = async (id?: number) => {
	if (!id) throw new Error('Id not provided');

	const data = await Api.bundleNotes.deleteBundleNotesId(id);

	return data.data;
};
