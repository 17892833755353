import { FC } from 'react';

import { IIconProps } from './Interfaces';

const HeaderBottleDestilIcon: FC<IIconProps> = ({ className }) => {
	return (
		<svg
			className={className}
			viewBox="0 0 69.56 151.77"
			width={10}
			height={18}
		>
			<path
				fill="#901441"
				d="M69.56,120.81l-.08-12.06v-7.93c0-.38-.03-.76-.06-1.14l-.18-25.86v-13.18c0-4.49-2.4-8.64-6.27-10.88l-17.32-10.04v-22.23c.07-.31.12-.63.12-.95v-8.3c0-1.46-.78-2.8-2.04-3.53l-7.16-4.15c-1.26-.73-2.81-.73-4.07,0l-7.15,4.15c-1.1.64-1.82,1.74-1.99,2.98h-.03v.38c0,.06-.01.12-.01.18v8.3s.01.07.01.11v23.24l-17.02,9.87C2.42,52,.03,56.15.04,60.64v25.57c.01.17.02.34.03.51l-.07,24.62.3,15.05c0,4.49,2.39,8.64,6.26,10.88l22.07,12.8c3.88,2.25,8.67,2.25,12.55,0l22.05-12.79c3.88-2.25,6.27-6.4,6.26-10.9v-5.58s.07,0,.07,0Z"
			/>
		</svg>
	);
};

export default HeaderBottleDestilIcon;
