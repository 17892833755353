import { useContext, useRef, useState } from 'react';
import cx from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { useInViewport } from 'react-in-viewport';
import { QuantityBoxTypes } from 'Components/QuantityBox/constants';
import getFlagSpecification from 'Helpers/getFlagSpecification';
import usePreventDragRedirect from 'Hooks/use-prevent-drag-redirect';
import { LocalizationContext } from 'Services/LocalizationService';
import { WarehouseContext } from 'Services/WarehouseService';
import { DirectQuantityBox, StandardQuantityBox } from 'Components/QuantityBox';
import StockAvailability from 'Components/StockAvailability';
import quantityBoxStyles from 'Components/QuantityBox/styles.module.css';
import { useProductBasketCount } from 'Components/ProductBox/hooks';
import { useBundleMeta } from 'Pages/Bundle/hooks/use-bundle-detail';
import { useDiscountCoupons, useFindBundleInBasket } from 'Pages/Bundle/hooks';
import getBundleLimitPerOrder from 'Helpers/getBundleLimitPerOrder';
import { useDirectQuantityBox } from 'Components/QuantityBox/Variants/DirectQuantityBox/hooks';
import { useStandardQuantityBox } from 'Components/QuantityBox/Variants/StandardQuantityBox/hooks';
import { AuthenticationContext } from 'Services/AuthenticationService/context';

import ProductBasketCount from './Components/ProductBasketCount';
import BundleProducer from './Components/BundleProducer';
import BundleTag from './Components/BundleTag';
import ButtonAddToFavorites from './Components/ButtonAddToFavorites';
import ProductSpecifications from './Components/Specifications';
import ProductTitleAndImage from './Components/TitleAndImage';
import { CARD_TYPE, IProductBoxProps } from './interfaces';
import styles from './styles.module.css';
import useFavoriteItem from './Components/ButtonAddToFavorites/hook';
import ProductBoxPrices from './Components/ProductBoxPrices';

const ProductBox = (props: IProductBoxProps) => {
	const localizationContext = useContext(LocalizationContext);
	const warehouseContext = useContext(WarehouseContext);
	const { isLoggedIn } = useContext(AuthenticationContext);

	const t = localizationContext.useFormatMessage();
	const { title: currencyTitle } = localizationContext.activeCurrency;

	const bundle = props.bundleData ?? null;

	const isCrossSell = props.isCrossSell ?? false;

	const { isCouponAvailable, handleOnAddToBasketWithDiscountCoupon } =
		useDiscountCoupons({ bundle: bundle ?? null });
	const itemInBasket = useFindBundleInBasket({ bundleId: bundle?.id });

	const bundleLimitPerOrder = getBundleLimitPerOrder(bundle?.orderLimitation);
	const wouldApplyingExceedOrderLimitation =
		typeof bundleLimitPerOrder === 'number' &&
		bundleLimitPerOrder <= (itemInBasket?.quantity ?? 0);

	const { bundleName, bundleUrl } = useBundleMeta(bundle);
	const bundleId = bundle?.id ?? '';
	const isTemporaryUnavailable = bundle?.flags.isTemporaryUnavailable ?? false;
	const isGift = bundle?.flags.isGift ?? false;
	const isForLogged = bundle?.flags.isForLogged ?? false;

	const showAddToBasketBtn = props.showAddToBasketBtn ?? true;
	const showSpecifications = props.showSpecifications ?? true;
	const showProducer = props.showProducer ?? true;
	const showAddToFavoritesBtn =
		(props?.showAddToFavoritesBtn ?? true) &&
		!isTemporaryUnavailable &&
		!isGift;

	const specificationDetails = bundle?.specificationDetails ?? [];

	const { shortVariety: producerName, component: flag } =
		getFlagSpecification(specificationDetails);

	const isLoading = props?.isLoading ?? false;

	const redirectToCrossSell = props?.redirectToCrossSell ?? true;

	const bundlePrices = bundle?.bundlePrices ?? null;

	const { basePrice, discountedPrice, isDiscounted } = bundlePrices ?? {};

	const availableQuantity = [warehouseContext.getQuantity(bundleId)].filter(
		(x): x is number => x !== undefined
	);

	const position = props.position ?? bundleId;

	const { basketQuantityPopover } = useProductBasketCount(bundle);
	const { quantityInBasket } = useDirectQuantityBox(bundle);

	const { handleAddItemToFavorites, handleRemoveItemFromFavorites } =
		useFavoriteItem(bundleId, {
			price:
				(isDiscounted
					? discountedPrice?.valueWithVat
					: basePrice?.valueWithVat) ?? 0,
			currency: currencyTitle,
			item_name: bundleName,
		});

	const { handleClick, handleMouseDown, preventDefaultEvent } =
		usePreventDragRedirect();

	const handleScrollToView = isCrossSell
		? () => null
		: () => {
				const element = document.getElementById(`bundle-link-key-${position}`);

				if (element) {
					element.scrollIntoView({ behavior: 'smooth', block: 'center' });
				}
		  };

	const standardQuantityBoxMethods = useStandardQuantityBox(
		bundle,
		redirectToCrossSell
	);

	const productBoxRef = useRef<HTMLDivElement>(null);

	const [enteredViewPort, setEnteredViewPort] = useState(false);

	const { inViewport } = useInViewport(
		productBoxRef,
		{
			root: document.body,
			rootMargin: '0px',
		},
		undefined,
		{
			onEnterViewport: () => {
				setEnteredViewPort(true);
			},
		}
	);

	return (
		<div
			className={cx('vinisto-wine__item', {
				loading: isLoading,
				[styles.cardMargin]: props.cardType === CARD_TYPE.SINGLE,
			})}
			id={`bundle-link-key-${position}`}
			ref={productBoxRef}
		>
			{isLoading ? (
				<ProductTitleAndImage
					isLoading
					bundle={null}
					isInViewport={enteredViewPort || inViewport}
				/>
			) : (
				<>
					<Link
						className="vinisto-wine__item-overlay"
						to={`/${t({
							id: 'routes.product.route',
						})}/${bundleUrl}`}
						rel="nofollow"
						onDragStart={preventDefaultEvent}
						onMouseDown={handleMouseDown}
						onMouseUp={preventDefaultEvent}
						onClick={handleClick}
					/>
					<ProductBasketCount text={basketQuantityPopover} />
					<div className="vinisto-wine__img-labels">
						{(bundle?.tags ?? []).map((tagDetail, index) => {
							return (
								<BundleTag
									tagDetail={tagDetail}
									key={`bundle-${bundleId}-tag-${tagDetail?.id ?? index}`}
									isLink={false}
								/>
							);
						})}
					</div>
					<Link
						to={`/${t({
							id: 'routes.product.route',
						})}/${bundleUrl}`}
						tabIndex={-1}
						onDragStart={preventDefaultEvent}
						onMouseDown={handleMouseDown}
						onMouseUp={preventDefaultEvent}
						onClick={handleClick}
					>
						<ProductTitleAndImage
							bundle={bundle}
							isInViewport={enteredViewPort || inViewport}
						/>
					</Link>
					{showAddToFavoritesBtn && (
						<ButtonAddToFavorites
							itemId={bundle?.id ?? ''}
							addToFavorites={handleAddItemToFavorites}
							removeItemFromFavorites={handleRemoveItemFromFavorites}
							carouselType={props?.carouselType ?? ''}
						/>
					)}
				</>
			)}
			{showProducer && (
				<BundleProducer
					flag={flag}
					name={producerName}
					isLoading={isLoading}
					isLink={false}
				/>
			)}
			{showSpecifications && (
				<div className="vinisto-wine__specifications">
					<ProductSpecifications
						specifications={specificationDetails}
						isLoading={isLoading}
					/>
				</div>
			)}
			<div className="vinisto-wine__bottom-info">
				<ProductBoxPrices
					bundleData={bundle}
					isLoading={isLoading}
				/>
				<div className="vinisto-wine__stock">
					<StockAvailability
						availableQuantity={availableQuantity}
						deliveryDate={warehouseContext.deliveryDate}
						isTemporaryUnavailable={isTemporaryUnavailable}
						fallback={<Skeleton width="80px" />}
					/>
				</div>
				{showAddToBasketBtn && !isTemporaryUnavailable && (
					<div
						className={cx(
							styles.aboveProductLink,
							styles.cta,
							'vinisto-wine__qunatity-box-wrapper'
						)}
						onClick={(e) => e.stopPropagation()}
						role="button"
						tabIndex={0}
					>
						{isCouponAvailable &&
						!wouldApplyingExceedOrderLimitation &&
						quantityInBasket === 0 &&
						!(isForLogged && !isLoggedIn) ? (
							<button
								className={cx(quantityBoxStyles.addToBasketButton, 'w-100')}
								onClick={() => {
									handleOnAddToBasketWithDiscountCoupon({
										redirectToCrossell: false,
									});
									handleScrollToView();
								}}
							>
								{t({ id: 'productbox.buyWithCoupon' })}
							</button>
						) : props?.quantityBoxType === QuantityBoxTypes.STANDARD ? (
							<StandardQuantityBox
								bundle={bundle}
								shouldRedirectToCrossSell={redirectToCrossSell}
								isLoading={isLoading}
								className={quantityBoxStyles.fullWidth}
								orderLimitation={bundle?.orderLimitation}
								carouselType={props?.carouselType ?? ''}
								afterAddToBasket={handleScrollToView}
								methods={standardQuantityBoxMethods}
							/>
						) : (
							<DirectQuantityBox
								bundle={bundle}
								isLoading={isLoading}
								className={quantityBoxStyles.fullWidth}
								orderLimitation={bundle?.orderLimitation}
								carouselType={props?.carouselType ?? ''}
								afterAddToBasket={handleScrollToView}
							/>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default ProductBox;
