import { FC } from 'react';

import { IIconProps } from './Interfaces';

const HeaderContactIcon: FC<IIconProps> = ({ className }) => {
	return (
		<svg
			width={14}
			height={15}
			viewBox="0 0 121 114"
			className={className}
		>
			<path
				fill="#901441"
				d="M12.09,108.8c-4.46,0-8.77,1.64-12.09,4.62V18.13C0,8.12,8.12,0,18.13,0h6.04v108.8h-12.09,0Z"
			/>
			<path
				fill="#901441"
				d="M108.8,0H36.27v108.8h27.33s57.29-.16,57.29-.16V12.09c0-6.68-5.41-12.09-12.09-12.09ZM77.84,18.5c6.85,0,12.41,5.55,12.41,12.41s-5.55,12.41-12.41,12.41-12.41-5.55-12.41-12.41,5.55-12.41,12.41-12.41ZM97.99,84.61h-40.65v-29.92c0-3.68,2.98-6.66,6.66-6.66h27.33c3.68,0,6.66,2.98,6.66,6.66v29.92Z"
			/>
		</svg>
	);
};

export default HeaderContactIcon;
