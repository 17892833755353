import {
	ComponentPropsWithoutRef,
	forwardRef,
	useEffect,
	useState,
} from 'react';
import { VinistoHelperDllEnumsUserTokenType } from 'vinisto_api_client/src/api-types/user-api';
import cn from 'classnames';

import Button from '../button';

import styles from './styles.module.css';

type Props = Omit<ComponentPropsWithoutRef<typeof Button>, 'children'> & {
	provider: VinistoHelperDllEnumsUserTokenType;
};

const OAuthButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
	const [image, setImage] = useState<string>('');

	useEffect(() => {
		const loadImage = async () => {
			let imgSrc = '';
			switch (props.provider) {
				case VinistoHelperDllEnumsUserTokenType.Google:
					imgSrc = (await import('assets/icons/google.svg')).default;
					break;
				case VinistoHelperDllEnumsUserTokenType.Facebook:
					imgSrc = (await import('assets/icons/facebook.svg')).default;
					break;
			}
			setImage(imgSrc);
		};

		loadImage();
	}, [props.provider]);

	return (
		<Button
			{...props}
			className={cn(styles.button, props.className)}
			ref={ref}
		>
			<img
				className={styles.img}
				src={image}
				alt={`${props.provider} logo`}
			/>
		</Button>
	);
});

OAuthButton.displayName = 'OAuthButton';

export default OAuthButton;
