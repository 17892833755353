import {
	Dispatch,
	FormEvent,
	SetStateAction,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { confirmAlert } from 'react-confirm-alert';
import EditIcon from 'Components/Icons/Edit';
import DeleteIcon from 'Components/Icons/Delete';
import CloseIcon from 'Components/Icons/Close';
import { LocalizationContext } from 'Services/LocalizationService';

import Info from './Components/Info';
import styles from './styles.module.css';
import { useCreateNote, useDeleteNote, useNotes, useUpdateNote } from './hooks';

import type { BundleNote } from '@/strapi-api/strapi-api';

type Props = {
	bundleId: string;
};

const BundleNotes = ({ bundleId }: Props) => {
	return (
		<div className={styles.wrapper}>
			<NoteForm bundleId={bundleId} />
			<div className={styles.notes}>
				<NoteList bundleId={bundleId} />
			</div>
		</div>
	);
};

const NoteForm = ({ bundleId }: { bundleId: string }) => {
	const [note, setNote] = useState('');

	const { useFormatMessage } = useContext(LocalizationContext);
	const t = useFormatMessage();

	const { mutate: createNote } = useCreateNote(bundleId, {
		onMutate: () => {
			setNote('');
		},
		onError: (note) => {
			setNote(note);
		},
	});

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		createNote(note);
	};

	return (
		<div className={styles.formContainer}>
			<div className={styles.mainHeadingWrap}>
				<h2 className={styles.mainHeading}>
					{t({
						defaultMessage: 'Vaše poznámky k produktu',
						id: 'bundle.notes.form.heading',
					})}
				</h2>
				<Info />
			</div>
			<form
				onSubmit={handleSubmit}
				className={styles.form}
			>
				<textarea
					placeholder={`${t({
						defaultMessage: 'Sem můžete psát poznámky, které vidíte pouze vy.',
						id: 'bundle.notes.form.placeholder',
					})}`}
					className={styles.textarea}
					value={note}
					onChange={(e) => setNote(e.target.value)}
				/>
				<button
					className={styles.saveButton}
					disabled={!note}
				>
					{t({
						defaultMessage: 'Uložit poznámku',
						id: 'bundle.notes.form.save',
					})}
				</button>
			</form>
		</div>
	);
};

function NoteList({ bundleId }: { bundleId: string }) {
	const { data: notes } = useNotes(bundleId);

	return notes?.map((note) => (
		<NoteItem
			key={note.id}
			{...note}
		/>
	));
}

const NoteItem = (note: BundleNote) => {
	const { useFormatMessage } = useContext(LocalizationContext);
	const t = useFormatMessage();

	const [isEditing, setIsEditing] = useState(false);
	const { mutate: deleteNote } = useDeleteNote(note);

	const handleOnDeleteNote = () => {
		confirmAlert({
			title: `${t({
				id: 'bundle.deleteNote',
			})}`,
			message: `${t({
				id: 'bundle.deleteNoteInfo',
			})}`,
			buttons: [
				{
					label: `${t({
						id: 'yes',
					})}`,
					onClick: () => deleteNote(),
				},
				{
					label: `${t({ id: 'no' })}`,
				},
			],
		});
	};

	if (isEditing) {
		return (
			<NoteFormEdit
				data={note}
				setIsEditing={setIsEditing}
			/>
		);
	}

	return (
		<div className={styles.noteItem}>
			<div className={styles.noteHeader}>
				<span className={styles.date}>
					{!!note.createdAt &&
						new Date(note.createdAt).toLocaleDateString('cs-CZ')}
				</span>
				<div className={styles.actions}>
					<button onClick={() => setIsEditing(true)}>
						<EditIcon
							height={17}
							width={17}
						/>
					</button>
					<button onClick={handleOnDeleteNote}>
						<DeleteIcon
							height={17}
							width={15}
						/>
					</button>
				</div>
			</div>
			<p>{note.note}</p>
		</div>
	);
};

interface NoteFormEditProps {
	data: BundleNote;
	setIsEditing: Dispatch<SetStateAction<boolean>>;
}

const NoteFormEdit = ({ data, setIsEditing }: NoteFormEditProps) => {
	const [note, setNote] = useState(data.note);
	const textareaRef = useRef<HTMLTextAreaElement>(null);

	const { useFormatMessage } = useContext(LocalizationContext);
	const t = useFormatMessage();

	const { mutate: updateNote } = useUpdateNote(data, {
		onMutate: () => {
			setNote('');
			setIsEditing(false);
		},
		onError: (note: string) => {
			setNote(note);
		},
	});

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		updateNote(note);
	};

	const adjustHeight = () => {
		const textarea = textareaRef.current;
		if (textarea) {
			textarea.style.height = '0px';
			textarea.style.height = `${textarea.scrollHeight}px`;
		}
	};

	useEffect(() => {
		adjustHeight();
		if (textareaRef.current) {
			textareaRef.current.focus();
			textareaRef.current.setSelectionRange(note.length, note.length);
		}
	}, [note.length]);

	useEffect(() => {
		const handleEscape = (e: KeyboardEvent) => {
			if (
				e.key === 'Escape' &&
				document.activeElement === textareaRef.current
			) {
				setIsEditing(false);
			}
		};

		window.addEventListener('keydown', handleEscape);
		return () => window.removeEventListener('keydown', handleEscape);
	}, [setIsEditing]);

	return (
		<form
			className={styles.editForm}
			onSubmit={handleSubmit}
		>
			<div className={styles.editContainer}>
				<span className={styles.editDate}>
					{!!data.createdAt &&
						new Date(data.createdAt).toLocaleDateString('cs-CZ')}
				</span>
				<textarea
					className={styles.editTextarea}
					value={note}
					onChange={(e) => setNote(e.target.value)}
					onInput={adjustHeight}
					ref={textareaRef}
				/>
			</div>
			<div className={styles.editActions}>
				<button
					className={styles.cancelButton}
					onClick={() => setIsEditing(false)}
				>
					<CloseIcon
						width={10}
						height={10}
					/>

					{t({
						defaultMessage: 'Zahodit změny',
						id: 'bundle.notes.form.discard',
					})}
				</button>
				<button
					className={styles.saveButton}
					disabled={!note}
				>
					{t({
						defaultMessage: 'Uložit poznámku',
						id: 'bundle.notes.form.save',
					})}
				</button>
			</div>
		</form>
	);
};

export default BundleNotes;
