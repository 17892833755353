import { useContext } from 'react';
import cx from 'classnames';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import getBundleImage, { IMAGE_SIZE_THUMB_64x80 } from 'Helpers/getBundleImage';
import { LocalizationContext } from 'Services/LocalizationService';
import BundleProducer from 'Components/ProductBox/Components/BundleProducer';
import getFlagSpecification from 'Helpers/getFlagSpecification';
import StockAvailability from 'Components/StockAvailability';
import { WarehouseContext } from 'Services/WarehouseService';
import { DirectQuantityBox } from 'Components/QuantityBox';
import { DeviceServiceContext } from 'Services/DeviceService';
import ProductBox from 'Components/ProductBox';
import Skeleton from 'react-loading-skeleton';
import { useDiscountCoupons } from 'Pages/Bundle/hooks';
import { getLocalizedPrice } from 'vinisto_shared/src/price/get-localized-price';
import { DiscountPercentage } from 'vinisto_ui';

import styles from './styles.module.css';

import { BundlePrice } from '@/domain/price';
import { Bundle } from '@/domain/bundle';

type Props = {
	bundle: Bundle;
	displayCta?: boolean;
};

const SearchResultBundleItem = ({ bundle, displayCta = false }: Props) => {
	const { isMobile } = useContext(DeviceServiceContext);
	const warehouseContext = useContext(WarehouseContext);
	const localizationContext = useContext(LocalizationContext);

	const getLocalizedValue = useLocalizedValue();
	const t = localizationContext.useFormatMessage();

	const name = getLocalizedValue(bundle.name);

	const couponData = useDiscountCoupons({ bundle });

	const availableQuantity = [warehouseContext.getQuantity(bundle.id)].filter(
		(x): x is number => x !== undefined
	);

	const { shortVariety: producerName, component: flag } = getFlagSpecification(
		bundle?.specificationDetails ?? []
	);

	const bundlePrices = bundle.bundlePrices;

	if (isMobile) {
		return (
			<div className="vinisto-search-productbox">
				<ProductBox
					bundleData={bundle}
					showAddToBasketBtn={displayCta}
					showSpecifications={false}
					showProducer={true}
					showAddToFavoritesBtn={false}
				/>
			</div>
		);
	}

	return (
		<div className={styles.wrapper}>
			<div className={styles.image}>
				<img
					src={getBundleImage(bundle.images ?? [], IMAGE_SIZE_THUMB_64x80)}
					alt={`${t({ id: 'alt.bundleImage' })}`}
				/>
			</div>
			<div className={styles.info}>
				<span className={styles.name}>{name}</span>
				{producerName && (
					<BundleProducer
						flag={flag}
						name={producerName}
						className={styles.producer}
					/>
				)}
			</div>
			<div className={styles.metadata}>
				<div className={styles.stock}>
					<StockAvailability
						availableQuantity={availableQuantity}
						deliveryDate={warehouseContext.deliveryDate}
						fallback={<Skeleton width="130px" />}
					/>
				</div>
				<Prices
					bundlePrices={bundlePrices}
					isSet={bundle?.flags.isSet ?? false}
					couponData={couponData}
				/>
			</div>
			{displayCta && (
				<div
					onClick={(e) => e.stopPropagation()}
					className={styles.cta}
					role="button"
					tabIndex={0}
				>
					<DirectQuantityBox bundle={bundle} />
				</div>
			)}
		</div>
	);
};

export default SearchResultBundleItem;

export const Prices = ({
	bundlePrices,
	isSet,
	couponData,
}: {
	bundlePrices: BundlePrice;
	isSet: boolean;
	couponData: {
		isCouponAvailable: boolean;
		priceWhenCouponApplied: number | null;
		priceWhenCouponAppliedWithoutVat: number | null;
	};
}) => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const {
		activeCurrency: { currency },
	} = localizationContext;

	const { isDiscounted, basePrice, discountedPrice } = bundlePrices ?? {};

	const {
		isCouponAvailable,
		priceWhenCouponApplied,
		priceWhenCouponAppliedWithoutVat,
	} = couponData;

	if (
		isCouponAvailable &&
		priceWhenCouponApplied &&
		priceWhenCouponAppliedWithoutVat
	) {
		return (
			<div className={styles.prices}>
				<div className={styles.pricesWithVAT}>
					<span className={cx(styles.basePrice, styles.hasDiscount)}>
						{basePrice.getFormatedValueWithVat()}
					</span>
					<span className={styles.discountedPrice}>
						{getLocalizedPrice({ price: priceWhenCouponApplied, currency })}
					</span>
					<DiscountPercentage
						className={styles.percentageDiscount}
						standardPriceWithVat={
							discountedPrice?.valueWithVat ?? basePrice?.valueWithVat ?? 0
						}
						discountedPriceWithVat={priceWhenCouponApplied}
					/>
				</div>
				<span className={styles.priceWithoutVAT}>
					{`${t({ id: 'basket.priceWithoutVAT' })} `}
					<span className={styles.value}>
						{getLocalizedPrice({
							price: priceWhenCouponAppliedWithoutVat,
							currency,
						})}
					</span>
				</span>
			</div>
		);
	}

	if (isSet && !isDiscounted) {
		return (
			<div className={styles.prices}>
				<div className={styles.pricesWithVAT}>
					<span className={styles.basePrice}>
						{discountedPrice?.getFormatedValueWithVat()}
					</span>
				</div>
				<span className={styles.priceWithoutVAT}>
					{`${t({ id: 'basket.priceWithoutVAT' })} `}
					<span className={styles.value}>
						{discountedPrice?.getFormatedValue()}
					</span>
				</span>
			</div>
		);
	}

	if (!isDiscounted) {
		return (
			<div className={styles.prices}>
				<div className={styles.pricesWithVAT}>
					<span className={styles.basePrice}>
						{basePrice?.getFormatedValueWithVat()}
					</span>
				</div>
				<span className={styles.priceWithoutVAT}>
					{`${t({ id: 'basket.priceWithoutVAT' })} `}
					<span className={styles.value}>{basePrice?.getFormatedValue()}</span>
				</span>
			</div>
		);
	}

	return (
		<div className={styles.prices}>
			<div className={styles.pricesWithVAT}>
				<span className={cx(styles.basePrice, styles.hasDiscount)}>
					{basePrice.getFormatedValueWithVat()}
				</span>
				<span className={styles.discountedPrice}>
					{discountedPrice?.getFormatedValueWithVat()}
				</span>
				<DiscountPercentage
					className={styles.percentageDiscount}
					standardPriceWithVat={basePrice.valueWithVat}
					discountedPriceWithVat={discountedPrice?.valueWithVat ?? 0}
				/>
			</div>
			<span className={styles.priceWithoutVAT}>
				{`${t({ id: 'basket.priceWithoutVAT' })} `}
				<span className={styles.value}>
					{discountedPrice?.getFormatedValue()}
				</span>
			</span>
		</div>
	);
};
