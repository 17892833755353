import {
	lazy,
	Suspense,
	useCallback,
	useContext,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import {
	filter,
	get,
	isEmpty,
	isEqual,
	map,
	orderBy,
	uniqueId,
} from 'lodash-es';
import { useInViewport } from 'react-in-viewport';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import {
	VinistoOrderDllModelsApiDeliveryDelivery,
	VinistoOrderDllModelsApiPaymentPayment,
} from 'vinisto_api_client/src/api-types/order-api';
import {
	VinistoBasketDllModelsApiBasketBasketBundle,
	VinistoBasketDllModelsApiBasketBasketDiscountCoupon,
} from 'vinisto_api_client/src/api-types/basket-api';
import { DocumentHeaderAction } from 'Components/DocumentHeader/constants';
import { GA_EVENT } from 'Hooks/useAnalytics/constants';
import { CTA_VISIBILITY_THRESHOLD } from 'Pages/CartShippingData/constants';
import useAnalytics from 'Hooks/useAnalytics';
import usePrevious from 'Hooks/usePrevious';
import usePriceActiveCurrency from 'Hooks/usePriceActiveCurrency';
import { DocumentHeaderContext } from 'Components/DocumentHeader/context';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { BasketContext } from 'Services/BasketService';
import { DeviceServiceContext } from 'Services/DeviceService';
import { LocalizationContext } from 'Services/LocalizationService';
import { OrderContext } from 'Services/OrderService/context';
import { TrackEvent } from 'Services/FacebookPixel';
import Loader from 'Components/View/Loader';
import { TEST_IDS } from 'Constants/test-ids';
import { ErrorBoundary } from '@sentry/react';
import { getLocalizedPrice } from 'vinisto_shared/src/price/get-localized-price';
import {
	useGetAllowedPayments,
	useGetDeliveriesByBasket,
} from 'Hooks/useGetDeliveries';

const FilterDropdownArrowIcon = lazy(
	() => import('Components/Icons/FilterDropdownArrow')
);
import usePaymentDetail from './Hooks/usePaymentDetail';
import useDeliveryDetail from './Hooks/useDeliveryDetail';
import { SKELETONS_NUM_PAYMENTS } from './constants';
import CartShippingPaymentHeader from './Components/CartShippingPaymentHeader';
import PaymentItem from './Components/PaymentItem';
import OrderOverview from './Components/OrderOverview';
import './styles.css';
import '../UserSection/Orders/styles.css';
import DeliveryList from './Components/DeliveryList';

type DeliveryData = VinistoOrderDllModelsApiDeliveryDelivery & {
	isLoading?: boolean;
};

const CartShippingPayment = () => {
	const { isLoggedIn, vinistoUser, anonymousUID } = useContext(
		AuthenticationContext
	);
	const basketContext = useContext(BasketContext);
	const localizationContext = useContext(LocalizationContext);
	const orderContext = useContext(OrderContext);
	const documentHeaderContext = useContext(DocumentHeaderContext);
	const deviceContext = useContext(DeviceServiceContext);

	const t = localizationContext.useFormatMessage();
	const currency = localizationContext.activeCurrency.currency;
	const countryOfSale = localizationContext.countryOfSale;
	const getPriceActiveCurrency = usePriceActiveCurrency();
	const history = useNavigate();
	const { sendEvent: sendAnalyticsEvent } = useAnalytics();

	const basketState = basketContext.basketState;
	const basketItems = get(basketState, 'items');
	const isBasketLoading = !basketItems;
	const [isShowFloatingFooter, setIsShowFloatingFooter] = useState(false);
	const ctaRef = useRef<HTMLDivElement>(null);
	const { inViewport: isCtaInViewport } = useInViewport(ctaRef, {
		// consider CTA button hidden behind header and footer
		rootMargin: `${deviceContext.headerHeight * -1}px 0px ${
			deviceContext.footerHeight * -1
		}px 0px`,
		threshold: CTA_VISIBILITY_THRESHOLD,
	});
	const parentRef = useRef<HTMLDivElement>(null);
	const [topDistance, setTopDistance] = useState(0);
	const [maxOrderOverviewHeight, setMaxOrderOverviewHeight] = useState('460px');
	const deliveryId = orderContext?.deliveryMethod?.id || null;
	const paymentId = orderContext?.paymentMethod?.id || null;

	const basketItemsGoogleAnalyticsData = get(
		basketContext,
		'basketItemsGoogleAnalyticsData'
	);
	const previousBasketGaData = usePrevious(basketItemsGoogleAnalyticsData);

	const [isAnalyticsSent, setIsAnalyticsSent] = useState(false);

	useEffect(() => {
		if (
			isAnalyticsSent ||
			isBasketLoading ||
			isEqual(previousBasketGaData, basketItemsGoogleAnalyticsData)
		)
			return;

		TrackEvent('track', 'InitiateCheckout', {
			content_type: 'product',
			content_ids: basketState.items.map(
				(bundle: VinistoBasketDllModelsApiBasketBasketBundle) =>
					bundle.bundleId ?? ''
			),
			value: basketState?.basketPrice ?? 0,
			currency,
		});

		sendAnalyticsEvent(GA_EVENT.BEGIN_CHECKOUT, {
			currency: basketState?.currency ?? '',
			value: basketState?.basketPrice ?? 0,
			items: basketItemsGoogleAnalyticsData,
		});

		setIsAnalyticsSent(true);
	}, [
		isBasketLoading,
		basketState,
		previousBasketGaData,
		basketItemsGoogleAnalyticsData,
		sendAnalyticsEvent,
		currency,
	]);

	useEffect(() => {
		if (!isEmpty(basketState) && isEmpty(basketItems)) {
			history(`/${t({ id: 'routes.cart.route' })}`);
		}
	}, [basketState, basketItems]);

	useEffect(() => {
		if (
			ctaRef.current &&
			get(getComputedStyle(ctaRef.current), 'display') !== 'none'
		) {
			setIsShowFloatingFooter(!isCtaInViewport);
		}
	}, [
		setIsShowFloatingFooter,
		isCtaInViewport,
		deviceContext.isMobile, // trigger on mobile/tablet viewport status changes
		deviceContext.isTablet,
	]);

	const deliveriesByBasketQuery = useGetDeliveriesByBasket({
		currency: currency,
		allowedCountry: countryOfSale,
		...(isLoggedIn
			? {
					userLoginHash: vinistoUser.loginHash,
			  }
			: {
					anonymousUser: anonymousUID.anonymousUserId,
			  }),
	});

	const deliveries: DeliveryData[] = useMemo(() => {
		if (!deliveriesByBasketQuery.isFetched) return [];

		const activeDeliveries = orderBy<DeliveryData>(
			filter(get(deliveriesByBasketQuery, 'data', []), 'isActive'),
			'order',
			'asc'
		);
		const filteredDeliveries = activeDeliveries?.find(
			(delivery) => delivery?.id === deliveryId
		);

		if (deliveryId && filteredDeliveries) {
			return [filteredDeliveries];
		}
		return activeDeliveries;
	}, [
		deliveriesByBasketQuery.isFetched,
		deliveriesByBasketQuery.data,
		deliveryId,
	]);

	const allowedPaymentsQuery = useGetAllowedPayments(
		{
			BasketId: basketState?.id,
			DeliveryId: String(deliveryId),
			Currency: currency,
			AllowedCountry: countryOfSale,
		},
		{
			enabled: Boolean(basketState?.id && deliveryId),
			cacheTime: 0,
			staleTime: 0,
			keepPreviousData: true,
		}
	);

	//@ts-expect-error misuse of react-query, types won't work correctly
	const payments: VinistoOrderDllModelsApiPaymentPayment[] = useMemo(() => {
		if (!deliveryId) return [];
		if (!allowedPaymentsQuery.isFetched)
			return map(Array(paymentId ? 1 : SKELETONS_NUM_PAYMENTS), () => ({
				isLoading: true,
			}));

		const activePayments = orderBy<VinistoOrderDllModelsApiPaymentPayment>(
			filter(get(allowedPaymentsQuery, 'data', []), 'isActive'),
			'order',
			'asc'
		);

		const filteredPayments = activePayments?.find(
			(payment) => payment?.id === paymentId
		);
		if (paymentId && filteredPayments) {
			return [filteredPayments];
		}

		return activePayments;
	}, [allowedPaymentsQuery, deliveryId, paymentId]);

	const deliveryDetail = useDeliveryDetail(deliveryId ?? '');

	const currentDeliveryPrice = deliveryDetail?.deliveryPrice;

	const paymentDetail = usePaymentDetail(paymentId ?? '');
	const paymentPriceData = useMemo(
		() => getPriceActiveCurrency(get(paymentDetail, 'data.prices', [])),
		[paymentDetail, getPriceActiveCurrency]
	);

	const discountCoupons = basketState?.discountCoupons;

	const discountCouponsList: VinistoBasketDllModelsApiBasketBasketDiscountCoupon[] =
		basketState?.discountCoupons?.discountCoupons ?? [];

	const appliedDiscountCouponsList = discountCouponsList.filter(
		(coupon) => coupon.isCouponApplied
	);

	const totalPriceWithVAT =
		(basketContext?.basketPriceWithVat ?? 0) +
		(currentDeliveryPrice?.valueWithVat ?? 0) +
		(paymentPriceData?.valueWithVat ?? 0);

	const totalPriceWithoutVAT =
		(basketContext?.basketPrice ?? 0) +
		(currentDeliveryPrice?.value ?? 0) +
		(paymentPriceData?.value ?? 0);

	const discountedTotalPriceWithVAT =
		(discountCoupons?.discountedBasketPriceWithVat ?? 0) +
		(currentDeliveryPrice?.valueWithVat ?? 0) +
		(paymentPriceData?.valueWithVat ?? 0);

	const discountedTotalPriceWithoutVAT =
		(discountCoupons?.discountedBasketPrice ?? 0) +
		(currentDeliveryPrice?.value ?? 0) +
		(paymentPriceData?.value ?? 0);

	const totalPriceBeforeDiscounts =
		basketContext.basketStandardPriceWithVat ?? 0;

	const isBasketDiscounted = totalPriceBeforeDiscounts > totalPriceWithVAT;

	const isLoading =
		isBasketLoading ||
		!get(deliveriesByBasketQuery, 'isFetched', false) ||
		((!!paymentId && !paymentDetail?.isLoaded) ?? false);

	useEffect(() => {
		deliveriesByBasketQuery.isFetched && deliveriesByBasketQuery.refetch();
		allowedPaymentsQuery.isFetched && allowedPaymentsQuery.refetch();
	}, [basketContext.basketState]);

	useEffect(() => {
		allowedPaymentsQuery.isFetched && allowedPaymentsQuery.refetch();
	}, [deliveryId]);

	const handleGoToShipping = useCallback(() => {
		history(`/${t({ id: 'routes.cart.shipping.route' })}`);
	}, [history]);

	const handleGoBack = useCallback(() => {
		history(`/${t({ id: 'routes.cart.route' })}`);
	}, [history]);

	const handleOnClearDelivery = useCallback(() => {
		orderContext.setDeliveryMethod(null);
	}, []);

	const handleOnClearPayment = useCallback(() => {
		orderContext.setPaymentMethod(null);
	}, []);

	useLayoutEffect(() => {
		setMaxOrderOverviewHeight(`${window.innerHeight - topDistance - 16}px`);
	}, [deviceContext, topDistance]);

	useLayoutEffect(() => {
		if (parentRef.current) {
			setTopDistance(
				window.pageYOffset + parentRef.current?.getBoundingClientRect()?.top
			);
		}
	}, [parentRef.current]);

	useEffect(() => {
		documentHeaderContext.dispatch({
			type: DocumentHeaderAction.setTitle,
			value: `${t(
				{ id: 'app.title.page' },
				{ title: `${t({ id: 'routes.cart.shippingPayment.name' })}` }
			)}`,
		});
	}, []);

	useEffect(() => {
		if (
			deliveryId &&
			!paymentId &&
			allowedPaymentsQuery.isFetched &&
			payments?.length === 1
		) {
			orderContext.setPaymentMethod({
				id: payments?.[0]?.id,
			});
		}
	}, [
		payments,
		allowedPaymentsQuery.isFetched,
		paymentId,
		deliveryId,
		countryOfSale,
		currency,
	]);

	return (
		<section id="content-wrapper">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<CartShippingPaymentHeader />
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row">
					<div className="vinisto-shipping__wrap">
						<ErrorBoundary>
							<div className="vinisto-shipping__big-col">
								<div>
									<h1 className="vinisto-heading underline mb-0">
										{t({
											id: 'cartShippingPayment.deliveryList.title',
										})}
									</h1>

									<div className="vinisto-shipping__items">
										{deliveriesByBasketQuery.isFetched &&
											deliveries?.length === 0 &&
											t({
												id: 'cartShippingPayment.deliveryList.empty',
											})}
										<DeliveryList
											deliveries={deliveries}
											isDeliveriesLoading={deliveriesByBasketQuery.isLoading}
										/>
									</div>
									<div className="text-end">
										<button
											className={`vinisto-btn vinisto-clear-btn vinisto-shipping__change-btn ${
												(!deliveryId ||
													get(deliveriesByBasketQuery?.data, 'count') === 1) &&
												'd-none'
											}`}
											onClick={handleOnClearDelivery}
										>
											{t({
												id: 'cartShippingPayment.paymentList.button.changeDeliveryMethod',
											})}
											<Suspense fallback={<Loader blank />}>
												<FilterDropdownArrowIcon
													id={`deliveries-${uniqueId()}-detail-toggle`}
													alt={t({
														id: 'cartShippingPayment.paymentList.button.changeDeliveryMethod',
													})}
													title=""
													className="FilterDropdownArrowIcon"
												/>
											</Suspense>
										</button>
									</div>
								</div>

								{deliveryId && (
									<div>
										<h1 className="vinisto-heading underline mb-0 mt-3 mt-xl-5">
											{t({
												id: 'cartShippingPayment.paymentList.title',
											})}
										</h1>

										<div className="vinisto-shipping__items">
											{allowedPaymentsQuery.isFetched &&
												payments?.length === 0 &&
												t({
													id: 'cartShippingPayment.paymentList.empty',
												})}
											{payments?.length > 0 &&
												payments?.map((payment, index) => {
													return (
														<div key={payment?.id ?? index}>
															<PaymentItem
																key={payment?.id ?? index}
																payment={payment}
																//@ts-expect-error misuse of react-query, types won't work correctly
																isLoading={payment?.isLoading}
																dataTestid={
																	TEST_IDS.BASKET_PAYMENT_TYPE +
																	'_' +
																	payment?.id
																}
															/>
														</div>
													);
												})}
										</div>
										<div className="text-end">
											<button
												className={`vinisto-btn vinisto-clear-btn vinisto-shipping__change-btn ${
													(!paymentId ||
														get(allowedPaymentsQuery?.data, 'count') === 1) &&
													'd-none'
												}`}
												onClick={handleOnClearPayment}
											>
												{t({
													id: 'cartShippingPayment.paymentList.button.changePaymentMethod',
												})}
												<Suspense fallback={<Loader blank />}>
													<FilterDropdownArrowIcon
														id={`payments-${uniqueId()}-detail-toggle`}
														alt={t({
															id: 'cartShippingPayment.paymentList.button.changePaymentMethod',
														})}
														title=""
														className="FilterDropdownArrowIcon"
													/>
												</Suspense>
											</button>
										</div>
									</div>
								)}

								<div className="vinisto-crosssell__ctas desktop-only">
									<button
										className="vinisto-crosssell__ctas__back vinisto-color-darker-gray"
										onClick={handleGoBack}
									>
										&lt; {t({ id: 'basket.backToCart' })}
									</button>
									<button
										type="button"
										disabled={!deliveryId || !paymentId}
										onClick={handleGoToShipping}
										className="vinisto-btn vinisto-success-btn vinisto-shipping__continue-btn"
										data-testid={
											TEST_IDS.BASKET_SHIPPING_CONTINUE_BUTTON_DESKTOP
										}
									>
										{t({ id: 'basket.orderContinueToShippingData' })}
									</button>
								</div>
							</div>
							<div
								ref={parentRef}
								className="vinisto-shipping__small-col"
							>
								<div
									style={{
										position: 'sticky',
										top: topDistance,
										height: maxOrderOverviewHeight,
									}}
								>
									<OrderOverview
										deliveryDetail={deliveryDetail}
										deliveryPriceWithVat={
											currentDeliveryPrice?.valueWithVat ?? undefined
										}
										paymentDetail={paymentDetail}
										paymentPriceWithVat={
											paymentPriceData?.valueWithVat ?? undefined
										}
										totalPriceWithVAT={
											appliedDiscountCouponsList.length
												? discountedTotalPriceWithVAT
												: totalPriceWithVAT
										}
										totalPriceWithoutVAT={
											appliedDiscountCouponsList.length
												? discountedTotalPriceWithoutVAT
												: totalPriceWithoutVAT
										}
										totalPriceBeforeDiscounts={totalPriceBeforeDiscounts}
										isLoading={isLoading}
										isBasketLoading={isBasketLoading}
										isBasketDiscounted={isBasketDiscounted}
									/>
								</div>
							</div>
						</ErrorBoundary>
					</div>
				</div>
			</div>

			{(deviceContext.isMobile || deviceContext.isTablet) && (
				<div className="container vinisto-crosssell vinisto-shipping">
					<div className="row">
						<div className="col-12 d-flex flex-column">
							<div className="vinisto-user-orders__orders__order-body__item__prices tablet-mobile-only w-100">
								<div className="vinisto-user-orders__orders__order-body__item__prices__total d-flex flex-row justify-content-between">
									<span className="vinisto-user-orders__orders__order-body__item__prices__big-total mobile-12">
										{`${t({ id: 'basket.totalPriceBig' })} `}
									</span>
									<span className="vinisto-user-orders__orders__order-body__item__prices__big-price">
										{isLoading ? (
											<Skeleton width="80px" />
										) : appliedDiscountCouponsList.length ? (
											getLocalizedPrice({
												price: discountedTotalPriceWithVAT,
												currency: currency,
											})
										) : (
											getLocalizedPrice({
												price: totalPriceWithVAT,
												currency: currency,
											})
										)}
									</span>
								</div>
								<div className="vinisto-user-orders__orders__order-body__item__prices__without-vat d-flex flex-row justify-content-between">
									{isLoading ? (
										<Skeleton width="100px" />
									) : (
										<>
											{`${t({
												id: 'basket.priceWithoutVAT',
											})} `}
											<span className="fw-bolder price-span">
												{appliedDiscountCouponsList.length
													? getLocalizedPrice({
															price: discountedTotalPriceWithoutVAT,
															currency: currency,
													  })
													: getLocalizedPrice({
															price: totalPriceWithoutVAT,
															currency: currency,
													  })}
											</span>
										</>
									)}
								</div>
							</div>
							<div
								className="vinisto-crosssell__ctas tablet-mobile-only w-100"
								ref={ctaRef}
							>
								<button
									type="button"
									disabled={!deliveryId || !paymentId}
									onClick={handleGoToShipping}
									className="vinisto-btn vinisto-user-orders__orders__order__cta__btn vinisto-crosssell__ctas__cta vinisto-success-btn vinisto-cart-btn-next w-100"
								>
									{t({ id: 'basket.orderContinueToShippingData' })}
								</button>
							</div>
						</div>
					</div>
				</div>
			)}

			{isShowFloatingFooter && (
				<div
					className="container vinisto-crosssell vinisto-crosssell--floating tablet-mobile-only"
					style={{ bottom: `${deviceContext.footerHeight}px` }}
				>
					<div className="row">
						<div className="col-12">
							<div className="vinisto-crosssell__ctas">
								<div className="vinisto-crosssell__price-continue">
									<div className="vinisto-user-orders__orders__order-body__item__prices w-100">
										<div className="vinisto-user-orders__orders__order-body__item__prices__total d-flex flex-row justify-content-between">
											<span className="vinisto-user-orders__orders__order-body__item__prices__big-total">
												{`${t({
													id: 'basket.totalPriceBig',
												})} `}
											</span>
											<span className="vinisto-user-orders__orders__order-body__item__prices__big-price">
												{isLoading ? (
													<Skeleton width="80px" />
												) : appliedDiscountCouponsList.length ? (
													getLocalizedPrice({
														price: discountedTotalPriceWithVAT,
														currency: currency,
													})
												) : (
													getLocalizedPrice({
														price: totalPriceWithVAT,
														currency: currency,
													})
												)}
											</span>
										</div>
										<div className="vinisto-user-orders__orders__order-body__item__prices__without-vat d-flex flex-row justify-content-between">
											{isLoading ? (
												<Skeleton width="100px" />
											) : (
												<>
													{`${t({
														id: 'basket.priceWithoutVAT',
													})} `}
													<span className="fw-bolder price-span">
														{appliedDiscountCouponsList.length
															? getLocalizedPrice({
																	price: discountedTotalPriceWithoutVAT,
																	currency: currency,
															  })
															: getLocalizedPrice({
																	price: totalPriceWithoutVAT,
																	currency: currency,
															  })}
													</span>
												</>
											)}
										</div>
									</div>
									<button
										type="button"
										disabled={!deliveryId || !paymentId}
										onClick={handleGoToShipping}
										className="vinisto-btn vinisto-user-orders__orders__order__cta__btn vinisto-crosssell__ctas__cta vinisto-success-btn vinisto-cart-btn-next w-100"
									>
										{t({ id: 'basket.orderContinueToShippingData' })}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</section>
	);
};

export default CartShippingPayment;
