import { ReactNode, useMemo } from 'react';

import { GoogleOAuthProvider } from './google/provider';
import { FacebookSDKProvider } from './facebook/provider';

export interface OAuthConfig {
	google?: {
		clientId: string;
		nonce?: string;
		onScriptLoadSuccess?: () => void;
		onScriptLoadError?: () => void;
	};
	facebook?: {
		appId: string;
		version?: string;
		xfbml?: boolean;
		locale?: string;
		nonce?: string;
		onScriptLoadSuccess?: () => void;
		onScriptLoadError?: () => void;
	};
}

interface OAuthProviderProps {
	config: OAuthConfig;
	children: ReactNode;
}

export const OAuthProvider = ({ config, children }: OAuthProviderProps) => {
	const ProviderTree = useMemo(() => {
		let element = children;

		if (config.facebook) {
			element = (
				<FacebookSDKProvider
					appId={config.facebook.appId}
					version={config.facebook.version}
					xfbml={config.facebook.xfbml}
					locale={config.facebook.locale}
					nonce={config.facebook.nonce}
					onScriptLoadSuccess={config.facebook.onScriptLoadSuccess}
					onScriptLoadError={config.facebook.onScriptLoadError}
				>
					{element}
				</FacebookSDKProvider>
			);
		}

		if (config.google) {
			element = (
				<GoogleOAuthProvider
					clientId={config.google.clientId}
					nonce={config.google.nonce}
					onScriptLoadSuccess={config.google.onScriptLoadSuccess}
					onScriptLoadError={config.google.onScriptLoadError}
				>
					{element}
				</GoogleOAuthProvider>
			);
		}

		return element;
	}, [config, children]);

	return <>{ProviderTree}</>;
};
