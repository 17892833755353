import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import NotFoundPage from 'Pages/NotFound';

import styles from './styles.module.css';
import { getPromos } from './getPromos';
import Banner from './Components/Banner';
import Offer from './Components/Offer';

import type { Promo } from '@/strapi-api/strapi-api';

const PromoPage: React.FC = () => {
	const [promo, setPromo] = useState<Promo | null>(null);
	const pathCode = window.location.pathname.split('/').pop() ?? '';
	const imageBaseUrl = import.meta.env.VITE_STRAPI_API_IMAGE_URI;

	const { data: promoData, isLoading } = useQuery(['promo', pathCode], () =>
		getPromos(pathCode)
	);

	useEffect(() => {
		const promoItem = promoData?.data?.data?.[0];
		if (promoItem) setPromo(promoItem);
	}, [promoData]);

	if (!isLoading && promoData?.data?.data?.length === 0) {
		return <NotFoundPage />;
	}

	return (
		<section id="content-wrapper">
			{promo && (
				<>
					<div
						className={styles.main}
						style={{
							backgroundImage: `url('${imageBaseUrl}${promo.HeroImage?.url}')`,
						}}
					>
						<h1 className={styles.h1}>{promo.Title}</h1>
						<div className={styles.uspWrap}>
							{promo.Banners?.map((banner, index) => (
								<Banner
									key={`banner-${index}`}
									title={banner.Title}
									subtitle={banner.Text}
									url={banner.Link}
									imageUrl={banner.Icon?.url}
								/>
							))}
						</div>
					</div>
					{promo.PromoBlocks?.map((offer, index) => (
						<Offer
							key={`offer-${index}`}
							title={offer.Title}
							promoText={offer.PromoText}
							imageUrl={offer.Image?.url}
							categoryLinks={offer.CategoryLinks}
							products={offer.Products}
						/>
					))}
				</>
			)}
		</section>
	);
};

export default PromoPage;
