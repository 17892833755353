import {
	lazy,
	Suspense,
	useCallback,
	useContext,
	useRef,
	useState,
} from 'react';
import { get, uniqueId } from 'lodash-es';
import { NavLink, useNavigate } from 'react-router-dom';
import { LocalizationContext } from 'Services/LocalizationService';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { ModalContext } from 'Components/Modal/context';
import { LOGIN_MODAL } from 'Components/Modal/constants';
const UserIcon = lazy(() => import('Components/Icons/User'));
import useOnClickOutside from 'Hooks/useOnClickOutside';
import { DeviceServiceContext } from 'Services/DeviceService';
import { UserAvatar } from 'Components/Avatar';
import Loader from 'Components/View/Loader';
import { TEST_IDS } from 'Constants/test-ids';

import styles from './styles.module.css';

const UserMenu = () => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const authenticationContext = useContext(AuthenticationContext);
	const deviceServiceContext = useContext(DeviceServiceContext);
	const modalContext = useContext(ModalContext);
	const userMenuRef = useRef<HTMLDivElement>(null);
	const [visibleDropdownMenu, setVisibleDropdownMenu] =
		useState<boolean>(false);
	const navigate = useNavigate();
	const isDesktop = get(deviceServiceContext, 'isDesktop');

	const handleCloseDropdownMenu = useCallback(() => {
		setVisibleDropdownMenu(false);
	}, []);

	useOnClickOutside([userMenuRef], handleCloseDropdownMenu);

	const handleOpenDropdownMenu = () => {
		isDesktop && setVisibleDropdownMenu(true);
	};

	const handleOnLogOut = () => {
		authenticationContext.handleOnLogOut();
		handleCloseDropdownMenu();
	};

	const handleOpenUserSection = () => {
		if (authenticationContext.isLoggedIn) {
			isDesktop
				? navigate(`/${t({ id: 'routes.user-section.route' })}/`)
				: setVisibleDropdownMenu((visibleDropdownMenu) => !visibleDropdownMenu);
		} else {
			modalContext.handleOpenModal(LOGIN_MODAL);
		}
	};

	return (
		<div
			className="vinisto-user-section-nav-link d-flex mx-2 pointer"
			ref={userMenuRef}
		>
			<div
				onClick={handleOpenUserSection}
				onKeyDown={handleOpenUserSection}
				onFocus={handleOpenDropdownMenu}
				onMouseOver={handleOpenDropdownMenu}
				onMouseLeave={() => {
					isDesktop && handleCloseDropdownMenu();
				}}
				role="presentation"
				className={styles.wrapper}
				data-testid={TEST_IDS.USER_SECTION_DESKTOP}
			>
				{authenticationContext.isLoggedIn &&
				(authenticationContext?.vinistoUser?.nickname ||
					authenticationContext?.vinistoUser?.email) ? (
					<UserAvatar
						size="sm"
						fw="light"
					/>
				) : (
					<Suspense fallback={<Loader blank />}>
						<UserIcon
							id={uniqueId()}
							alt={t({ id: 'alt.userSection' })}
							title={t({ id: 'alt.userSection' })}
							className={`UserIcon`}
						/>
					</Suspense>
				)}
				<span className={styles.text}>
					{authenticationContext.isLoggedIn
						? t({ id: 'navbar.labels.myVinisto' })
						: t({ id: 'navbar.actionButton.logIn' })}
				</span>
			</div>
			{authenticationContext.isLoggedIn && visibleDropdownMenu && (
				<div
					className="vinisto-user-section-nav-link__menu underline-effect underline-effect--vinisto"
					onMouseLeave={handleCloseDropdownMenu}
					onMouseOver={handleOpenDropdownMenu}
					onFocus={handleOpenDropdownMenu}
				>
					<ul className={styles.submenu}>
						<li>
							<NavLink
								className="underline-item"
								to={`${t({
									id: 'routes.user-section.route',
								})}/${t({
									id: 'routes.user-section.orders.route',
								})}`}
								aria-current="page"
								onClick={handleCloseDropdownMenu}
							>
								{t({ id: 'routes.user-section.myOrders.name' })}
							</NavLink>
						</li>

						<li>
							<NavLink
								className="underline-item"
								to={`${t({
									id: 'routes.user-section.route',
								})}/${t({
									id: 'routes.user-section.club-coupons.route',
								})}`}
								aria-current="page"
								onClick={handleCloseDropdownMenu}
							>
								{t({ id: 'routes.user-section.club-coupons.name' })}
							</NavLink>
						</li>

						<li>
							<NavLink
								className="underline-item"
								to={`${t({
									id: 'routes.user-section.route',
								})}/${t({
									id: 'routes.user-section.bought-products.route',
								})}`}
								aria-current="page"
								onClick={handleCloseDropdownMenu}
							>
								{t({ id: 'routes.user-section.bought-products.name' })}
							</NavLink>
						</li>

						<li>
							<NavLink
								className="underline-item"
								to={`${t({
									id: 'routes.user-section.route',
								})}/${t({
									id: 'routes.user-section.favorites.route',
								})}`}
								aria-current="page"
								onClick={handleCloseDropdownMenu}
							>
								{t({ id: 'routes.user-section.favorites.name' })}
							</NavLink>
						</li>

						<li className={styles.divider}>
							<NavLink
								className="underline-item"
								to={`${t({
									id: 'routes.user-section.route',
								})}/${t({
									id: 'routes.user-section.addresses.route',
								})}`}
								aria-current="page"
								onClick={handleCloseDropdownMenu}
							>
								{t({
									id: 'routes.user-section.addresses.name',
								})}
							</NavLink>
						</li>
						{/* <li>
                            <NavLink
                                className="underline-item"
                                to={`${t({ id: 'routes.user-section.route' })}/${t({ id: 'routes.user-section.watchdog.route' })}`}
                                aria-current="page"
                            >
                                {t({ id: 'routes.user-section.watchdog.name' })}
                            </NavLink>
                        </li> */}
						<li>
							<NavLink
								className="underline-item"
								to={`${t({
									id: 'routes.user-section.route',
								})}/${t({
									id: 'routes.user-section.settings.route',
								})}`}
								aria-current="page"
								onClick={handleCloseDropdownMenu}
							>
								{t({ id: 'routes.user-section.settings.name' })}
							</NavLink>
						</li>
						<li className={styles.divider}>
							<NavLink
								onClick={handleOnLogOut}
								className="underline-item"
								to={``}
								aria-current="page"
							>
								<b>{t({ id: 'navbar.actionButton.logOut' })}</b>
							</NavLink>
						</li>
					</ul>
				</div>
			)}
		</div>
	);
};

export default UserMenu;
