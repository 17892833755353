import Skeleton from 'react-loading-skeleton';
import { Link, useMatch } from 'react-router-dom';
import { LinkTile } from 'vinisto_ui';
import cx from 'classnames';
import { isExternalLink } from 'vinisto_shared';

import styles from './styles.module.css';

import { Link_Widget_Types } from '@/domain/link-widget/enums';

interface LinkWidget {
	id: string | null | undefined;
	name: string | null | undefined;
	imageLocator: string | null | undefined;
	to: string | null | undefined;
	type?: Link_Widget_Types;
}

interface LinkWidgetListProps {
	isLoading: boolean;
	linkWidgets?: LinkWidget[];
	className?: string;
	itemClassName?: string;
}

const LinkWidgetList = ({
	isLoading,
	linkWidgets,
	className,
	itemClassName,
}: LinkWidgetListProps) => {
	const isHomepage = useMatch('/');

	if (!linkWidgets) return null;

	return (
		<div className={cx(styles.list, className)}>
			{isLoading ? (
				<Skeleton count={8} />
			) : (
				linkWidgets.map((linkWidget) => {
					const { id, name, imageLocator, type, to } = linkWidget;
					const url = getLinkUrl(linkWidget);

					switch (true) {
						case type === Link_Widget_Types.External || isExternalLink(url):
							return (
								<a
									key={id}
									href={url}
									target="_blank"
									rel="noopener noreferrer"
									className={styles.link}
								>
									<LinkTile
										className={itemClassName}
										key={id}
										title={name ?? ''}
										img={{
											src: imageLocator ?? '',
											alt: name ?? '',
										}}
									/>
								</a>
							);
						case type === Link_Widget_Types.Specification:
							return (
								<Link
									to={isHomepage ? url : `.${to}`}
									key={id}
									className={styles.link}
								>
									<LinkTile
										className={itemClassName}
										key={id}
										title={name ?? ''}
										img={{
											src: imageLocator ?? '',
											alt: name ?? '',
										}}
									/>
								</Link>
							);

						default:
							return (
								<Link
									key={id}
									to={url}
									className={styles.link}
								>
									<LinkTile
										className={itemClassName}
										key={id}
										title={name ?? ''}
										img={{
											src: imageLocator ?? '',
											alt: name ?? '',
										}}
									/>
								</Link>
							);
					}
				})
			)}
		</div>
	);
};

export default LinkWidgetList;

const getLinkUrl = (linkWidget: LinkWidget) => {
	const { to, type } = linkWidget;

	const cleanedPathname = window.location.pathname.replace(/\/+$/, '');
	const cleanedTo = (to ?? '').replace(/^\/+/, '');

	const fullPath = `${cleanedPathname}/${cleanedTo}`;
	// Check if there's nothing between cleanedPathname and cleanedTo
	// If there is, we insert 'produkty' between them. Then there shouldn't be an invalid url.
	const insertProdukty =
		cleanedPathname === '' ||
		cleanedTo === '' ||
		(!cleanedPathname.includes('/') && !cleanedTo.includes('/'));

	switch (type) {
		case Link_Widget_Types.External:
			return to ?? '/';
		case Link_Widget_Types.Category:
		case Link_Widget_Types.Blog:
			return `${to ?? ''}`;
		case Link_Widget_Types.Specification:
			return insertProdukty
				? `${cleanedPathname}/produkty/${cleanedTo}`
				: fullPath;
		default:
			return '/';
	}
};
