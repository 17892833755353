import { FC } from 'react';

import { IIconProps } from './Interfaces';

const HeaderChatIcon: FC<IIconProps> = ({ className }) => {
	return (
		<svg
			className={className}
			width={18}
			height={18}
			viewBox="0 0 32 31"
		>
			<g>
				<path
					fill="#920b3f"
					d="M31.99,4.65C31.98,1.57,30.43,.01,27.39,0,19.82,0,12.26,0,4.7,0,1.53,.01,.01,1.55,0,4.72,0,9.46,0,14.2,0,18.94c0,3.61,.77,4.61,4.39,5.56,0,1.45-.07,2.84,.03,4.21,.04,.62,.26,1.42,.69,1.75,.28,.21,1.18-.15,1.65-.47,2.29-1.56,4.48-3.25,6.8-4.76,.79-.51,1.83-.86,2.76-.89,3.67-.1,7.35-.03,11.02-.05,3.06-.01,4.63-1.58,4.64-4.62,.01-5.01,.01-10.01,0-15.02ZM8.6,13.79c-1.19-.09-1.95-.69-1.97-1.88-.02-1.17,.66-1.93,1.84-1.95,1.16-.02,1.93,.67,1.96,1.85,.03,1.18-.7,1.82-1.83,1.98Zm7.4-.03c-1.19,0-1.9-.74-1.9-1.9,0-1.16,.73-1.89,1.9-1.89,1.19,0,1.78,.76,1.93,1.85-.14,1.2-.74,1.95-1.94,1.94Zm7.45,0c-1.21-.01-1.88-.77-1.87-1.92,0-1.17,.76-1.88,1.92-1.88,1.19,0,1.78,.77,1.9,1.87-.15,1.21-.76,1.94-1.95,1.93Z"
				/>
			</g>
		</svg>
	);
};

export default HeaderChatIcon;
