import { useContext, useEffect, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { get, isNaN } from 'lodash-es';
import {
	DelimitedNumericArrayParam,
	NumberParam,
	useQueryParam,
	withDefault,
} from 'use-query-params';
import { DocumentHeaderAction } from 'Components/DocumentHeader/constants';
import { DocumentHeaderContext } from 'Components/DocumentHeader/context';
import { FavoritesContext } from 'Services/FavoritesService';
import { LocalizationContext } from 'Services/LocalizationService';
import { DeviceServiceContext } from 'Services/DeviceService';
import PaginationNav from 'Components/Pagination';

import BreadCrumbsUserSection from '../Breadcrumbs';

import ListDesktop from './ListDesktop';
import ListMobile from './ListMobile';

import './styles.css';

const Favorites = () => {
	const t = useContext(LocalizationContext).useFormatMessage();
	const favoritesContext = useContext(FavoritesContext);
	const { isMobile, isTablet } = useContext(DeviceServiceContext);
	const documentHeaderContext = useContext(DocumentHeaderContext);

	const { favoriteItemsWithTemp, favoritesState } = favoritesContext;
	const isLoading = favoritesState.loading;

	const [page, setPage] = useQueryParam(
		'page',
		withDefault(DelimitedNumericArrayParam, [1])
	);

	const currentPage =
		get(page, 'length', 0) > 1 ? get(page, '[1]', 1) : get(page, '[0]', 1);
	const [limit] = useQueryParam<number>('limit', withDefault(NumberParam, 20));

	const bundlesCount = favoritesState.favoritesData.length;
	const bundlesCountWithTemp = favoriteItemsWithTemp.length;

	const bundlesToLoadMore = () => {
		const bundlesLeft = bundlesCountWithTemp - currentPage * limit;
		if (bundlesLeft < 1) return 0;
		if (bundlesLeft > limit) return limit;
		return bundlesLeft;
	};

	const bundles = useMemo(() => {
		if (isLoading)
			return Array.from({ length: limit }, () => ({ isLoading: true }));

		const start = ((page[0] ?? 1) - 1) * limit;
		const end = (page[page.length - 1] ?? page[0] ?? 1) * limit;
		return favoriteItemsWithTemp.slice(start, end);
	}, [favoriteItemsWithTemp, isLoading, limit, page]);

	useEffect(() => {
		if (
			page.length > 2 ||
			page.some((pageNum) => isNaN(pageNum)) ||
			(page.length === 2 && (page[0] ?? 1) > (page[1] ?? 2))
		) {
			setPage([1]);
		}
	}, [page, setPage]);

	useEffect(() => {
		documentHeaderContext.dispatch({
			type: DocumentHeaderAction.setTitle,
			value: `${t(
				{ id: 'app.title.page' },
				{ title: `${t({ id: 'routes.user-section.favorites.name' })}` }
			)}`,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleOnLoadMore = () =>
		setPage([page[0] ?? 1, (page[1] ?? page[0] ?? 1) + 1]);
	const handleOnSelectNextPage = () => setPage([currentPage + 1]);
	const handleOnSelectPreviousPage = () =>
		currentPage > 1 && setPage([currentPage - 1]);
	const totalPaginationPages = Math.ceil(bundlesCountWithTemp / limit);
	const handleOnSelectPage = (page: number) => setPage([page]);

	return (
		<>
			<BreadCrumbsUserSection />
			<div className="vinisto-user-orders">
				<h1 className="vinisto-user-favorites-header">
					{t({ id: 'routes.user-section.favorites.name' })}
				</h1>

				<div className="vinisto-user-orders__header">
					<div className="vinisto-user-orders__header__sort desktop-only"></div>
					<div className="vinisto-user-orders__header__order-count">
						{isLoading ? (
							<Skeleton width="90px" />
						) : (
							t(
								{
									id: 'userSection.favorites.itemsCounter',
								},
								{ count: bundlesCount }
							)
						)}
					</div>
				</div>

				{bundlesCountWithTemp === 0 && !isLoading && (
					<div className="vinisto-user-orders__no-order text-center">
						<span>
							{t({ id: 'userSection.favorites.noFavorites' })}
							<br />
							{t({
								id: 'userSection.favorites.saveToFavorites',
							})}
						</span>
					</div>
				)}

				{isMobile || isTablet ? (
					<ListMobile favoritesData={bundles} />
				) : (
					<ListDesktop favoritesData={bundles} />
				)}

				<PaginationNav
					currentPage={currentPage}
					itemsToLoadMore={bundlesToLoadMore()}
					handleOnLoadMore={handleOnLoadMore}
					handleOnSelectPreviousPage={handleOnSelectPreviousPage}
					handleOnSelectNextPage={handleOnSelectNextPage}
					totalPaginationPages={totalPaginationPages}
					handleOnSelectPage={handleOnSelectPage}
				/>
			</div>
		</>
	);
};

export default Favorites;
