export enum PRODUCT_TYPE {
	WINES = 'wines',
	DESTILATES = 'destilates',
	SETS = 'sets',
}

export const WINES_CATEGORY_ID = '653f868cd37f300ae212479f';
export const DESTILATES_CATEGORY_ID = '655a5dc1d276cfb77aaa265b';

export const WINES_URL =
	'/kategorie/vina/Akce/ano/skladem/ano/stitky/vinisto+KLUB';
export const DESTILATES_URL =
	'/kategorie/destilaty/Akce/ano/skladem/ano/stitky/vinisto+KLUB';
export const SETS_URL =
	'/kategorie/sety/Akce/ano/skladem/ano/stitky/vinisto+KLUB';
