import { FC } from 'react';

import { IIconProps } from './Interfaces';

const HeaderBottleWineIcon: FC<IIconProps> = ({ className }) => {
	return (
		<svg
			width={7}
			height={21}
			viewBox="0 0 47 152"
			className={className}
		>
			<path
				fill="901441"
				d="M46.26,123.01v-58.11c.06-.34.09-.68.09-1.03v-14.55c-.01-2.55-1.61-4.91-4.2-6.19l-10.97-5.4V9.73c0-.07.01-.13.01-.2v-4.78c0-.84-.55-1.62-1.43-2.04L24.72.32c-.89-.42-1.98-.42-2.86,0l-5.03,2.39c-.89.42-1.43,1.2-1.43,2.04v.43s0,0,0,0l-.11,32.49-11.08,5.46C1.6,44.41,0,46.77,0,49.33v14.55c0,.5,0,1.11,0,1.57l.15,57.17c0,.57-.04.93-.04,1.38l-.11,13.98c0,2.46,1.6,4.74,4.19,5.97l14.78,7.02c2.6,1.24,5.8,1.24,8.4,0l14.76-7.01c2.24-1.07,3.74-2.91,4.11-4.99l.08-.69c0-.1.01-.2.01-.3l-.08-13.98c0-.32,0-.67-.01-.99Z"
			/>
		</svg>
	);
};

export default HeaderBottleWineIcon;
