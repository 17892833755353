import { isExternalLink } from 'vinisto_shared';
import { Link } from 'react-router-dom';
import { ReactNode } from 'react';

import styles from './styles.module.css';

interface BannerListingProps {
	title?: string;
	subtitle?: string;
	imageUrl?: string;
	buttonText?: string;
	buttonLink: string;
}

const BannerListing = ({
	title,
	subtitle,
	imageUrl,
	buttonText,
	buttonLink,
}: BannerListingProps) => {
	const WrapperComponent = ({ children }: { children: ReactNode }) =>
		isExternalLink(buttonLink) ? (
			<a
				href={buttonLink}
				className={styles.overlay}
				target="_blank"
				rel="noreferrer"
			>
				{children}
			</a>
		) : (
			<Link
				to={buttonLink}
				className={styles.overlay}
			>
				{children}
			</Link>
		);

	return (
		<div
			className={styles.banner}
			style={{ backgroundImage: `url('${imageUrl}')` }}
		>
			<WrapperComponent>
				<div className={styles.top}>
					<div className={styles.title}>{title}</div>
				</div>
				<div className={styles.bottom}>
					{subtitle && <div className={styles.subtitle}>{subtitle}</div>}
					{buttonText && <span className={styles.button}>{buttonText}</span>}
				</div>
			</WrapperComponent>
		</div>
	);
};

export default BannerListing;
