import api from '@/api';
import { GetLinkMenusResponseContract } from '@/api-types/linkwidgets-api';

/** Get menus (desktop and mobile) */
const getMenu = async () => {
	// @ts-expect-error Special case for linkwidgets-api
	const res = await api.get<GetLinkMenusResponseContract>(
		`linkwidgets-api/link-menus`,
		undefined,
		{
			headers: {
				["X-Api-Key"]: import.meta.env.VITE_LINK_WIDGET_API_KEY
			}
		}
	);

	if (res.desktopMenu === undefined) {
		throw new Error('No desktopMenu data in response');
	}

	if (res.mobileMenu === undefined) {
		throw new Error('No mobileMenu data in response');
	}

	// Filter excluded 
	const out = {
		desktopMenu: res.desktopMenu?.filter((element) => {
			return element.flags?.includes("ExcludedFromMenu") ? false : true
		}),
		mobileMenu: res.mobileMenu?.filter((element) => {
			return element.flags?.includes("ExcludedFromMenu") ? false : true
		})
	};

	return out;
};


const LinkWidgetsService = {
	getMenu,
};

export { LinkWidgetsService };
