import { useContext, useEffect } from 'react';
import cx from 'classnames';
import { DocumentHeaderAction } from 'Components/DocumentHeader/constants';
import { PHONE_NUMBER } from 'Components/Navbar/constants';
import { formatPhoneNumber } from 'Components/Navbar/helpers';
import Config from 'Config';
import { LocalizationContext } from 'Services/LocalizationService';
import { DocumentHeaderContext } from 'Components/DocumentHeader/context';
import ImageLocal from 'Components/View/ImageLocal';
import ContainerFullWidth from 'Components/View/ContainerFullWidth';
import Card from 'Components/View/Card';
import Container from 'Components/View/Container';

import styles from './styles.module.css';

const Contact = () => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const documentHeaderContext = useContext(DocumentHeaderContext);

	useEffect(() => {
		documentHeaderContext.dispatch({
			type: DocumentHeaderAction.setTitle,
			value: `${t(
				{ id: 'app.title.page' },
				{ title: `${t({ id: 'routes.contact.name' })}` }
			)}`,
		});
	}, []);

	return (
		<section id="content-wrapper">
			<ContainerFullWidth>
				<Card className={styles.headingCard}>
					<ImageLocal
						fileName="contact-page-phone-icon.svg"
						alt=""
						className="d-none d-sm-block"
					/>
					<h1 className="h2 text-center">Máte dotaz? Jsme tu pro Vás!</h1>
					<ImageLocal
						fileName="contact-page-phone-icon.svg"
						alt=""
						className="d-none d-sm-block"
					/>
				</Card>
			</ContainerFullWidth>
			<Container>
				<div className="col-12 col-xl-6 mb-3 mb-xl-0">
					<Card className={styles.termsCard}>
						<div className={styles.termsWrap}>
							<div>
								<ImageLocal
									fileName="contact-big-ico-1.svg"
									alt=""
									className={styles.termsImg}
								/>
							</div>
							<div className={styles.termsText}>
								<h2 className="color-primary h3">Zákaznické centrum</h2>
								<p>
									Máte nějaké otázky, potřebujete poradit, nebo vás něco zajímá?
									Naše zákaznické centrum je tu pro vás a připraveno se vám plně
									věnovat. Stačí napsat, nebo zavolat.
								</p>
								<div className={styles.termsCtaWrap}>
									<div
										className={cx(
											styles.termsCta,
											'underline-effect underline-effect--vinisto'
										)}
									>
										<ImageLocal
											fileName="contact-small-phone.svg"
											alt=""
										/>
										<div className="d-flex flex-column">
											<span className="color-primary">
												{formatPhoneNumber(PHONE_NUMBER)}
											</span>
											<span className="color-primary">Po-Ne; 8:00-20:00</span>
										</div>
									</div>
									<div
										className={cx(
											styles.termsCta,
											'underline-effect underline-effect--vinisto'
										)}
									>
										<ImageLocal
											fileName="contact-small-mail.svg"
											alt=""
										/>
										<a
											href={`mailto:${Config.market.supportEmail}`}
											className="underline-item"
										>
											{Config.market.supportEmail}
										</a>
									</div>
								</div>
							</div>
						</div>
					</Card>
				</div>
				<div className="col-12 col-xl-6">
					<Card className={styles.termsCard}>
						<div className={styles.termsWrap}>
							<div>
								<ImageLocal
									fileName="contact-big-ico-2.svg"
									alt=""
									className={styles.termsImg}
								/>
							</div>
							<div className={styles.termsText}>
								<h2 className="color-primary h3">Péče o prodejce</h2>
								<p>
									Máte zájem začít prodávat na platformě, máte k prodeji nějaké
									otázky, nebo již u nás své produkty nabízíte a potřebujete
									vyřešit nějaký problém?
								</p>
								<div className={styles.termsCtaWrap}>
									<div
										className={cx(
											styles.termsCta,
											'underline-effect underline-effect--vinisto'
										)}
									>
										<ImageLocal
											fileName="contact-small-phone.svg"
											alt=""
										/>
										<div className="d-flex flex-column">
											<span className="color-primary">
												{Config.market.b2bPhoneNumber}
											</span>
											<span className="color-primary">Po-Pá; 8:00-20:00</span>
										</div>
									</div>
									<div
										className={cx(
											styles.termsCta,
											'underline-effect underline-effect--vinisto'
										)}
									>
										<ImageLocal
											fileName="contact-small-mail.svg"
											alt=""
										/>
										<a
											href={`mailto:${Config.market.b2bSupportEmail}`}
											className="underline-item"
										>
											{Config.market.b2bSupportEmail}
										</a>
									</div>
								</div>
							</div>
						</div>
					</Card>
				</div>
			</Container>
			<Container>
				<div className="col-12 col-xl-6 mb-3 mb-xl-0">
					<Card className="pb-3">
						<div className={styles.termsText}>
							<h2 className="color-primary h3">
								Fakturační údaje a sídlo společnosti
							</h2>
							<div className="d-flex g-3 flex-wrap justify-content-between">
								<p className="mb-0">
									<span className="fw-bolder">vinisto s.r.o.</span>
									<br />
									Společnost zapsaná u MS v Praze, odd. C vložka 324492
									<br />
									Jankovcova 1057/6, 170 00 Praha 7<br />
									IČ: 08746893
									<br />
									DIČ: CZ08746893
									<br />
									<br />
									Bankovní spojení: {Config.market.bankAccount}
									<br />
									Bankovní spojení (EUR): {Config.market.bankAccountEUR}
								</p>
								<ImageLocal
									fileName="contact-qr.svg"
									alt=""
								/>
							</div>
						</div>
					</Card>
				</div>
				<div className="col-12 col-xl-6">
					<Card className="pb-3">
						<div className={styles.termsText}>
							<h2 className="color-primary h3">Logistické centrum a sklad</h2>
							<p className="mb-0">
								Sanderova 1366/26, 170 00 Praha 7<br />
								Provozní doba: Po-Pá; 8-16h
								<br />
								Telefon:{' '}
								<span className="color-primary fw-bolder">
									{formatPhoneNumber(PHONE_NUMBER)}
								</span>
								<br />
							</p>
						</div>
					</Card>
				</div>
			</Container>
		</section>
	);
};

export default Contact;
