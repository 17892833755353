import { dayjsInstance as dayjs } from 'Services/Date';
import {
	ArticlesGetArticleByUrlDetailParams,
	VinistoCmsDllModelsApiCmsArticleCmsArticle,
	VinistoCmsDllModelsApiCmsArticleCmsArticlePreview,
	VinistoCmsDllModelsApiReturnCmsArticleReturn,
	VinistoCmsDllModelsApiReturnCmsArticlesReturn,
	VinistoHelperDllBaseAuthorizationParameters,
	VinistoHelperDllBaseBaseReturn,
	VinistoHelperDllBaseError,
	VinistoProductDllModelsApiCommonBundlesCountReturn,
} from 'vinisto_api_client/src/api-types/cms-api';
import { SpecificationDetail } from 'Services/Specification/interfaces';
import { apiServiceInstance } from 'Services/ApiService';
import { SpecificationType } from 'Services/Specification/constants';
import { VinistoCmsDllModelsApiReturnCmsArticlesPreviewReturn } from 'vinisto_api_client/src/api-types/cms-api';

import { BLOG_ARTICLES_PUBLISHED_URI, BLOG_URI } from '../constants';
import PostTagAdapter from '../PostTag/adapter';
import {
	CmsPostListQueryArgument,
	CmsPostPublishedListQueryArgument,
} from '../interfaces';
import AuthorAdapter from '../Author/adapter';

import {
	BlogArticle,
	BlogArticlePreview,
	BlogArticleSpecificationApi,
} from './interfaces';
import { BUNDLE_COUNT_API_ENDPOINT } from './constants';
import { getProductType, getState } from './helpers';

import api from '@/api';
import { DEFAULT_CURRENCY } from '@/shared';

const postTagAdapter = new PostTagAdapter();
const authorAdapter = new AuthorAdapter();

const mapApiToDomain = (
	blogArticle: VinistoCmsDllModelsApiCmsArticleCmsArticle
): BlogArticle => {
	return {
		id: blogArticle.id ?? '',
		language: blogArticle.language ?? '',
		specificationDetails:
			blogArticle.specificationDetails as SpecificationDetail[],
		title: blogArticle.title ?? '',
		leadParagraph: blogArticle.perex ?? '',
		meta: blogArticle.metaDescription ?? '',
		perex: blogArticle.perex ?? '',
		url: blogArticle.url ?? '',
		lastEditDate: dayjs.unix(blogArticle.updatedDate ?? 0).toDate(),
		image: blogArticle.titleImageDetail ?? null,
		content: blogArticle.body ?? '',
		productType: getProductType(blogArticle),
		productListTitle: blogArticle.carouselListingTitle ?? '',
		state: getState(blogArticle),
		authors: blogArticle.authors ?? [],
		readingTime: blogArticle.readingTime ?? 0,
		publishDate: dayjs.unix(blogArticle.publishDate ?? 0).toDate(),
		tags:
			blogArticle.tagDetails
				?.map((tag) => tag.id)
				.filter((tag): tag is string => !!tag) ??
			blogArticle.tags ??
			[],
		tagDetails:
			blogArticle.tagDetails?.map((tag) => postTagAdapter.fromApi(tag)) ?? [],
		//@ts-expect-error fix interface a stejně to používá api :)
		bundles: blogArticle.bundles ?? [],
		bundleDetails: blogArticle.bundleDetails ?? [],
		authorDetails:
			blogArticle.authorDetails?.map((author) =>
				authorAdapter.fromApi(author)
			) ?? [],
	};
};

const mapSpecificationValuesByType = (specification: SpecificationDetail) => {
	if (
		specification.definition.specificationType ===
			SpecificationType.DECIMAL_NUMBER ||
		specification.definition.specificationType ===
			SpecificationType.DECIMAL_NUMBER_IMPERIAL ||
		specification.definition.specificationType === SpecificationType.NUMBER ||
		specification.definition.specificationType ===
			SpecificationType.NUMBER_IMPERIAL
	) {
		return specification.value.allowedValues?.map(Number);
	}
	return specification.value.allowedValues;
};

const mapSpecificationsToApi = (
	specification: SpecificationDetail
): BlogArticleSpecificationApi => {
	return {
		specificationType: specification.definition.specificationType,
		specificationDefinitionId: specification.definition.id,
		allowedValues: mapSpecificationValuesByType(specification) ?? [],
	};
};

const getArticle = async (
	slug: string,
	params: Omit<ArticlesGetArticleByUrlDetailParams, 'articleUrl'>
) =>
	api
		.get<VinistoCmsDllModelsApiReturnCmsArticleReturn>(
			`${BLOG_URI}/${slug}/GetArticleByUrl`,
			{
				countryOfSale: params.countryOfSale,
				currency: params.currency ?? DEFAULT_CURRENCY,
			}
		)
		.then((payload) => {
			return payload.article ? mapApiToDomain(payload.article) : null;
		});

const getBundlesCount = (
	specifications: SpecificationDetail[],
	userLoginHash: string
) =>
	apiServiceInstance
		.post(
			BUNDLE_COUNT_API_ENDPOINT,
			{
				userLoginHash,
				specifications: specifications.map(mapSpecificationsToApi),
			},
			true
		)
		.then(
			(payload) =>
				(payload as VinistoProductDllModelsApiCommonBundlesCountReturn)
					?.bundlesCount ?? 0
		)
		.catch(() => 0);

const publishArticle = async (
	id: string,
	req: VinistoHelperDllBaseAuthorizationParameters
) =>
	apiServiceInstance.put<VinistoHelperDllBaseBaseReturn>(
		`${BLOG_URI}/${id}/PublishArticle`,
		req
	);

const draftArticle = async (
	id: string,
	req: VinistoHelperDllBaseAuthorizationParameters
) =>
	apiServiceInstance.put<VinistoHelperDllBaseBaseReturn>(
		`${BLOG_URI}/${id}/DraftArticle`,
		req
	);

const getList = async (req: CmsPostListQueryArgument[]) =>
	apiServiceInstance
		.get<VinistoCmsDllModelsApiReturnCmsArticlesReturn>(
			BLOG_URI,
			true,
			undefined,
			req
		)
		.then((payload) => {
			const articles = payload?.articles ?? null;
			return {
				...payload,
				articles:
					payload && payload?.count && payload?.count > 0
						? articles !== null
							? articles.map(mapApiToDomain)
							: null
						: null,
			};
		});

const getPreviewList = async (
	req: CmsPostPublishedListQueryArgument[]
): Promise<GetPreviewListSuccess> => {
	const { articlePreviews, count, error, isError } =
		await apiServiceInstance.get<VinistoCmsDllModelsApiReturnCmsArticlesPreviewReturn>(
			`${BLOG_ARTICLES_PUBLISHED_URI}/`,
			true,
			undefined,
			req
		);

	if (isError || count === undefined || !articlePreviews) {
		throw {
			isError: true,
			error: error || [],
		};
	}

	return {
		isError: false,
		count: count,
		articles: articlePreviews?.map(mapPreviewApiToDomain),
	};
};

type GetPreviewListSuccess = {
	isError: false;
	count: number;
	articles: BlogArticlePreview[];
};

type GetPreviewListError = {
	isError: true;
	error: VinistoHelperDllBaseError[];
};

const mapPreviewApiToDomain = (
	data: VinistoCmsDllModelsApiCmsArticleCmsArticlePreview
): BlogArticlePreview => {
	return {
		id: data.id ?? '',
		publishDate: dayjs.unix(data.publishDate ?? 0).toDate(),
		perex: data.perex ?? '',
		title: data.title ?? '',
		titleImageId: data.titleImageId ?? '',
		url: data.url ?? '',
		authorDetails:
			data.authorDetails?.map((author) => authorAdapter.fromApi(author)) ?? [],
		readingTime: data.readingTime ?? 0,
	};
};

const BlogService = {
	getArticle,
	mapApiToDomain,
	getBundlesCount,
	publishArticle,
	draftArticle,
	getList,
	getPreviewList,
};

export type { GetPreviewListSuccess, GetPreviewListError };

export default BlogService;
