import ReactDOM from 'react-dom/client';

import VinistoEshopApp from './App';

import 'react-loading-skeleton/dist/skeleton.css';
import './assets/styles/bootstrap/bootstrap.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './assets/styles/fonts.css';
import './assets/styles/utility.css';
import './assets/styles/template.css';
import './assets/styles/responsive.css';
import './assets/styles/notifications.css';
import './assets/styles/alice-carousel.css';
import 'vinisto_ui/src/assets/styles/variables.css';

import 'Services/SentryService';

window.addEventListener('vite:preloadError', () => {
	window.location.reload();
});

const root = document.getElementById('vinisto_eshop_root');

if (!root) {
	throw new Error('Root element not found');
}

const VinistoAppRoot = ReactDOM.createRoot(root);

VinistoAppRoot.render(<VinistoEshopApp />);
