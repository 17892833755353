import { useCallback, useContext, useEffect, useMemo } from 'react';
import cx from 'classnames';
import { DocumentHeaderAction } from 'Components/DocumentHeader/constants';
import { LocalizationContext } from 'Services/LocalizationService';
import { DocumentHeaderContext } from 'Components/DocumentHeader/context';
import { Link } from 'react-router-dom';
import userSectionStyles from 'Pages/UserSection/styles.module.css';
import ordersStyles from 'Pages/UserSection/Orders/styles.module.css';
import PaginationNav from 'Components/Pagination';
import { useQuery } from '@tanstack/react-query';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import {
	BooleanParam,
	DelimitedNumericArrayParam,
	useQueryParam,
	useQueryParams,
	withDefault,
} from 'use-query-params';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import { WarehouseContext } from 'Services/WarehouseService';

import BreadCrumbsUserSection from '../Breadcrumbs';

import styles from './styles.module.css';
import BoughtProduct from './BoughtProduct';
import {
	BOUGHT_PRODUCT_SORTING_COLUMNS,
	DEFAULT_LIMIT_PER_PAGE,
} from './BoughtProduct/constants';

import api from '@/api';
import {
	UserBundlesListParams,
	VinistoHelperDllEnumsUserBundleSortableColumns,
	VinistoOrderDllModelsApiReturnDataUserBundlesReturn,
} from '@/api-types/order-api';

const BoughtProducts = () => {
	const { dispatch } = useContext(DocumentHeaderContext);
	const { useFormatMessage } = useContext(LocalizationContext);
	const t = useFormatMessage();
	const getLocalizedValue = useLocalizedValue();
	const userId = useContext(AuthenticationContext).vinistoUser?.id;
	const language = useContext(LocalizationContext).activeLanguageKey;
	const warehouseContext = useContext(WarehouseContext);

	useEffect(() => {
		dispatch({
			type: DocumentHeaderAction.setTitle,
			value: `${t(
				{ id: 'app.title.page' },
				{ title: `${t({ id: 'routes.user-section.bought-products.name' })}` }
			)}`,
		});
	}, [dispatch, t]);

	const [page, setPage] = useQueryParam(
		'page',
		withDefault(DelimitedNumericArrayParam, [1])
	);

	const SortingParam = {
		encode(value: VinistoHelperDllEnumsUserBundleSortableColumns) {
			return `${value}`;
		},

		decode(strValue: string | (string | null)[] | null | undefined) {
			return strValue
				? (strValue as VinistoHelperDllEnumsUserBundleSortableColumns)
				: undefined;
		},
	};

	const [sorting, setSorting] = useQueryParams({
		column: withDefault(
			SortingParam,
			VinistoHelperDllEnumsUserBundleSortableColumns.ORDER_DELIVERY_TIME
		),
		isDescending: withDefault(BooleanParam, true),
	});

	const params: UserBundlesListParams = {
		UserId: userId ?? undefined,
		Language: language,
		SortingColumn: sorting.column,
		IsSortingDescending: sorting.isDescending,
		Offset: (Number(page[0]) - 1) * DEFAULT_LIMIT_PER_PAGE,
		Limit:
			page.length === 1
				? DEFAULT_LIMIT_PER_PAGE
				: (Number(page[1]) - Number(page[0]) + 1) * DEFAULT_LIMIT_PER_PAGE,
	};

	const boughtProductsQuery = useQuery(
		['boughtProducts', params, page],
		async () => {
			const response =
				await api.get<VinistoOrderDllModelsApiReturnDataUserBundlesReturn>(
					'order-api/user-bundles',
					params
				);

			if (response) {
				const bundleIds = response.userBundles
					?.map((userBundle) => userBundle.bundle?.id)
					.filter((id): id is string => id !== null && id !== undefined);

				if (bundleIds && bundleIds.length > 0)
					warehouseContext.fetchQuantity(bundleIds);
				return response;
			}
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: true,
		}
	);

	const currentPage = (page.length ?? 0) > 1 ? page[1] ?? 1 : page[0] ?? 1;

	const bundlesCount = boughtProductsQuery.data?.count ?? 0;

	const boughtBundlesToLoadMore = useMemo(() => {
		const ordersLeft = bundlesCount - currentPage * DEFAULT_LIMIT_PER_PAGE;
		if (ordersLeft < 1) return 0;
		if (ordersLeft > DEFAULT_LIMIT_PER_PAGE) return DEFAULT_LIMIT_PER_PAGE;
		return ordersLeft;
	}, [bundlesCount, currentPage]);

	const totalPaginationPages =
		bundlesCount <= DEFAULT_LIMIT_PER_PAGE
			? 0
			: Math.ceil(bundlesCount / DEFAULT_LIMIT_PER_PAGE);

	const handleOnSelectPage = useCallback(
		(page: number) => setPage([page]),
		[setPage]
	);

	const handleOnIncreasePage = useCallback(() => {
		setPage([currentPage + 1]);
	}, [currentPage, setPage]);

	const handleOnDecreasePage = useCallback(() => {
		currentPage > 1 && setPage([currentPage - 1]);
	}, [currentPage, setPage]);

	const handleOnLoadMore = useCallback(() => {
		setPage((oldPage) => [
			oldPage?.[0] ?? 1,
			(oldPage?.[1] ?? oldPage?.[0] ?? 1) + 1,
		]);
	}, [setPage]);

	const handleOnSelectSorting = useCallback(
		(sortingColumn: (typeof BOUGHT_PRODUCT_SORTING_COLUMNS)[number]) => {
			setSorting({
				column: sortingColumn.column,
				isDescending: sortingColumn.isDescending,
			});
		},
		[setSorting]
	);

	return (
		<>
			<BreadCrumbsUserSection />
			<h1 className={userSectionStyles.userSectionMainHeader}>
				{t({ id: 'routes.user-section.bought-products.name' })}
			</h1>
			<div>
				<div className={cx(ordersStyles.sorting, 'mb-1')}>
					{BOUGHT_PRODUCT_SORTING_COLUMNS.map((sortingColumn, index) => (
						<button
							key={index}
							onClick={() => handleOnSelectSorting(sortingColumn)}
							className={cx(ordersStyles.sortingButton, {
								[ordersStyles.active]:
									sorting.column === sortingColumn.column &&
									sorting.isDescending === sortingColumn.isDescending,
							})}
						>
							{t({ id: sortingColumn.label })}
						</button>
					))}
				</div>
				{(!boughtProductsQuery.data?.userBundles ||
					boughtProductsQuery.data?.userBundles?.length === 0) && (
					<div className={styles.noProducts}>
						<span>
							{t(
								{ id: 'userSection.bought-products.empty' },
								{
									link: (
										<Link
											to="/"
											className="color-primary fw-bolder"
										>
											{t({
												id: 'userSection.bought-products.startShopping',
											})}
										</Link>
									),
								}
							)}
						</span>
					</div>
				)}
				{boughtProductsQuery.data?.userBundles?.map((userBundle, index) => (
					<BoughtProduct
						key={index}
						image={userBundle.image}
						bundleId={`${userBundle.bundle?.id}`}
						bundleName={getLocalizedValue(userBundle.bundle?.name ?? [])}
						bundleUrl={getLocalizedValue(userBundle.bundle?.url ?? [])}
						bundleSpecificationDetails={userBundle.specificationDetails}
						bundle={userBundle.bundle}
						orders={userBundle.orders}
						rating={userBundle.evaluation}
						refetchBoughtProducts={boughtProductsQuery.refetch}
					/>
				))}

				{!!boughtProductsQuery.data?.userBundles?.length && (
					<PaginationNav
						currentPage={currentPage}
						totalPaginationPages={totalPaginationPages}
						itemsToLoadMore={boughtBundlesToLoadMore}
						handleOnLoadMore={handleOnLoadMore}
						handleOnSelectPreviousPage={handleOnDecreasePage}
						handleOnSelectNextPage={handleOnIncreasePage}
						handleOnSelectPage={handleOnSelectPage}
						className="pe-1"
					/>
				)}
			</div>
		</>
	);
};

export default BoughtProducts;
