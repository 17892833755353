import { useCallback, useContext, useState } from 'react';
import cx from 'classnames';
import { useForm } from 'react-hook-form';
import { PreloaderContext } from 'Components/Preloader/context';
import { LocalizationContext } from 'Services/LocalizationService';
import { Validators } from 'Components/Form';
import Form from 'Components/Forms';
import { ServicesService } from 'vinisto_api_client';

import { RegisterFormSchema } from './interfaces';
import styles from './styles.module.css';

const RegisterForm = () => {
	const localizationContext = useContext(LocalizationContext);
	const preloaderContext = useContext(PreloaderContext);

	const t = localizationContext.useFormatMessage();

	const [isSubmitSuccessful, setIsSubmitSuccessful] = useState<boolean>();

	const methods = useForm<RegisterFormSchema>({
		mode: 'onTouched',
		defaultValues: {
			name: '',
			email: '',
			identityId: '',
			phone: '',
			isVatPayer: false,
		},
	});

	const handleOnSubmit = useCallback(
		(values: RegisterFormSchema) => {
			preloaderContext.togglePreloader(true);

			ServicesService.sendContactForm({
				name: values.name,
				mail: values.email,
				ico: values.identityId,
				phone: values.phone,
				isVatPayer: values.isVatPayer,
			})
				.then(() => {
					methods.reset();
					setIsSubmitSuccessful(true);
				})
				.catch(() => {
					setIsSubmitSuccessful(false);
				})
				.finally(() => preloaderContext.togglePreloader(false));
		},
		[methods, preloaderContext]
	);

	const requiredMessage = `${t({ id: 'form.input.required' })}`;

	return (
		<Form.Provider {...methods}>
			<Form onSubmit={methods.handleSubmit(handleOnSubmit)}>
				<Form.InputField
					name="name"
					id="name"
					label={`${t({ id: 'becomeSupplier.form.name.label' })}`}
					placeholder={`${t({ id: 'becomeSupplier.form.name.placeholder' })}`}
					rules={{
						required: { message: requiredMessage, value: true },
					}}
				/>
				<Form.InputField
					name="email"
					id="email"
					label={`${t({ id: 'becomeSupplier.form.email.label' })}`}
					placeholder={`${t({ id: 'becomeSupplier.form.email.placeholder' })}`}
					rules={{
						required: { message: requiredMessage, value: true },
						validate: (value: string) => {
							const errorMsg = Validators.validateEmail(value);
							return errorMsg ? `${t({ id: errorMsg })}` : true;
						},
					}}
				/>
				<div className="row">
					<div className="col-12 col-sm-6">
						<Form.PhoneField
							name="phone"
							id="phone"
							label={`${t({ id: 'becomeSupplier.form.phone.label' })}`}
							placeholder={`${t({
								id: 'becomeSupplier.form.phone.placeholder',
							})}`}
							rules={{
								required: { message: requiredMessage, value: true },
							}}
						/>
					</div>
					<div className="col-12 col-sm-6">
						<Form.InputField
							name="identityId"
							id="identityId"
							label={`${t({ id: 'becomeSupplier.form.identityId.label' })}`}
							placeholder={`${t({
								id: 'becomeSupplier.form.identityId.placeholder',
							})}`}
							rules={{
								required: { message: requiredMessage, value: true },
								validate: (value: string) => {
									const errorMsg = Validators.validateIco(value);
									return errorMsg ? `${t({ id: errorMsg })}` : true;
								},
							}}
							inputMode="numeric"
						/>
					</div>
				</div>
				<Form.Checkbox
					{...methods.register('isVatPayer')}
					name="isVatPayer"
					id="isVatPayer"
				>
					{`${t({ id: 'becomeSupplier.form.isVatPayer.label' })}`}
				</Form.Checkbox>

				<button
					type="submit"
					className="vinisto-btn vinisto-success-btn w-100"
				>
					{t({
						id: 'becomeSupplier.form.submit',
					})}
				</button>
				{isSubmitSuccessful === true && (
					<p className={cx(styles.msg, styles.success, 'mt-3')}>
						{t({ id: 'becomeSupplier.form.submit.success' })}
					</p>
				)}
				{isSubmitSuccessful === false && (
					<p className={cx(styles.msg, styles.error, 'mt-3')}>
						{t({ id: 'becomeSupplier.form.submit.error' })}
					</p>
				)}
			</Form>
		</Form.Provider>
	);
};

export default RegisterForm;
