import { useContext, useEffect } from 'react';
import cx from 'classnames';
import { LocalizationContext } from 'Services/LocalizationService';
import { DocumentHeaderAction } from 'Components/DocumentHeader/constants';
import userSectionStyles from 'Pages/UserSection/styles.module.css';
import ordersStyles from 'Pages/UserSection/Orders/styles.module.css';
import { useQueryParam } from 'use-query-params';
import { DocumentHeaderContext } from 'Components/DocumentHeader/context';

import BreadCrumbsUserSection from '../Breadcrumbs';

import ActionsProducts from './ActionProducts';
import DiscountCoupons from './DiscountCoupons';
import { ACTION_PRODUCTS_URI_PARAM } from './constants';

const UserSectionClubDiscounts = () => {
	const { dispatch } = useContext(DocumentHeaderContext);
	const { useFormatMessage } = useContext(LocalizationContext);
	const t = useFormatMessage();

	useEffect(() => {
		dispatch({
			type: DocumentHeaderAction.setTitle,
			value: `${t(
				{ id: 'app.title.page' },
				{ title: `${t({ id: 'routes.user-section.club-coupons.name' })}` }
			)}`,
		});
	}, [dispatch, t]);

	const [openedTab, setOpenedTab] = useQueryParam('tab');

	return (
		<>
			<BreadCrumbsUserSection />
			<h1 className={userSectionStyles.userSectionMainHeader}>
				{t({ id: 'routes.user-section.club-coupons.name' })}
			</h1>
			<div>
				<div className={cx(ordersStyles.sorting, 'mb-1')}>
					<button
						onClick={() => setOpenedTab(null)}
						className={cx(ordersStyles.sortingButton, {
							[ordersStyles.active]: openedTab === undefined,
						})}
					>
						{t({ id: 'routes.user-section.club-coupons.discount-coupons' })}
					</button>
					<button
						onClick={() => setOpenedTab(ACTION_PRODUCTS_URI_PARAM)}
						className={cx(ordersStyles.sortingButton, {
							[ordersStyles.active]: openedTab === ACTION_PRODUCTS_URI_PARAM,
						})}
					>
						{t({ id: 'routes.user-section.club-coupons.action-products' })}
					</button>
				</div>
				{openedTab === ACTION_PRODUCTS_URI_PARAM ? (
					<ActionsProducts />
				) : (
					<DiscountCoupons />
				)}
			</div>
		</>
	);
};

export default UserSectionClubDiscounts;
