import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { NotificationsContext } from 'Services/NotificationService';

import {
	createBundleNote,
	deleteBundleNote,
	getBundleNotes,
	updateBundleNote,
} from './api';

import type { BundleNote } from '@/strapi-api/strapi-api';

const getQueryKey = (bundleId: string, userEmail: string | null) => [
	'bundleNotes',
	bundleId,
	userEmail ?? '',
];

export const useNotes = (bundleId: string) => {
	const { vinistoUser } = useContext(AuthenticationContext);

	return useQuery({
		queryKey: getQueryKey(bundleId, vinistoUser?.email ?? ''),
		queryFn: () => getBundleNotes(bundleId, vinistoUser?.email ?? ''),
	});
};

export const useCreateNote = (
	bundleId: string,
	options?: {
		onMutate?: () => void;
		onSuccess?: () => void;
		onError?: (note: string) => void;
	}
) => {
	const { vinistoUser } = useContext(AuthenticationContext);
	const { handleShowSuccessNotification, handleShowErrorNotification } =
		useContext(NotificationsContext);
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (note: string) =>
			createBundleNote(bundleId, vinistoUser?.email ?? '', note),
		onMutate: async (newNote) => {
			options?.onMutate?.();

			const queryKey = getQueryKey(bundleId, vinistoUser?.email ?? '');
			await queryClient.cancelQueries({ queryKey });

			const previousNotes = queryClient.getQueryData<BundleNote[]>(queryKey);

			const optimisticNote: BundleNote = {
				id: Date.now(),
				bundleId,
				note: newNote,
				userEmail: vinistoUser?.email ?? '',
				createdAt: new Date().toISOString(),
			};

			if (previousNotes) {
				queryClient.setQueryData<BundleNote[]>(queryKey, [
					optimisticNote,
					...previousNotes,
				]);
			}

			return { previousNotes };
		},
		onSuccess: () => {
			handleShowSuccessNotification('bundle.notes.form.save.success');
			options?.onSuccess?.();
		},
		onError: (_, prevNote, context) => {
			if (context?.previousNotes) {
				const queryKey = getQueryKey(bundleId, vinistoUser?.email ?? '');
				queryClient.setQueryData(queryKey, context.previousNotes);
			}

			handleShowErrorNotification('bundle.notes.form.save.error');

			options?.onError?.(prevNote);
		},
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: getQueryKey(bundleId, vinistoUser?.email ?? ''),
			});
		},
	});
};

export const useUpdateNote = (
	note: BundleNote,
	options?: {
		onMutate?: () => void;
		onError?: (note: string) => void;
		onSuccess?: () => void;
	}
) => {
	const queryClient = useQueryClient();
	const { handleShowSuccessNotification, handleShowErrorNotification } =
		useContext(NotificationsContext);

	return useMutation({
		mutationFn: (content: string) =>
			updateBundleNote(
				note.documentId as unknown as number,
				note.bundleId,
				note.userEmail ?? '',
				content
			),
		onMutate: async (newContent) => {
			const queryKey = getQueryKey(note.bundleId, note.userEmail ?? '');
			await queryClient.cancelQueries({ queryKey });

			const previousNotes = queryClient.getQueryData<BundleNote[]>(queryKey);

			if (previousNotes) {
				queryClient.setQueryData<BundleNote[]>(
					queryKey,
					previousNotes.map((n) =>
						n.id === note.id ? { ...n, note: newContent } : n
					)
				);
			}

			options?.onMutate?.();

			return { previousNotes };
		},
		onSuccess: () => {
			handleShowSuccessNotification('bundle.notes.form.edit.success');
			options?.onSuccess?.();
		},
		onError: (_, prevNote, context) => {
			if (context?.previousNotes) {
				const queryKey = getQueryKey(note.bundleId, note.userEmail ?? '');
				queryClient.setQueryData(queryKey, context.previousNotes);
			}

			handleShowErrorNotification('bundle.notes.form.edit.error');

			options?.onError?.(prevNote);
		},
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: getQueryKey(note.bundleId, note.userEmail ?? ''),
			});
		},
	});
};

export const useDeleteNote = (note: BundleNote) => {
	const queryClient = useQueryClient();
	const { handleShowSuccessNotification, handleShowErrorNotification } =
		useContext(NotificationsContext);

	return useMutation({
		mutationFn: () => deleteBundleNote(note.documentId as unknown as number),
		onMutate: () => {
			// Optimistically remove note from cache
			const queryKey = getQueryKey(note.bundleId, note.userEmail ?? '');
			const previousNotes = queryClient.getQueryData<BundleNote[]>(queryKey);

			if (previousNotes) {
				queryClient.setQueryData<BundleNote[]>(
					queryKey,
					previousNotes.filter((n) => n.id !== note.id)
				);
			}

			// Return context for rollback
			return { previousNotes };
		},
		onSuccess: () =>
			handleShowSuccessNotification('bundle.notes.form.delete.success'),
		onError: (_, __, context) => {
			if (context?.previousNotes) {
				const queryKey = getQueryKey(note.bundleId, note.userEmail ?? '');
				queryClient.setQueryData(queryKey, context.previousNotes);
			}

			handleShowErrorNotification('bundle.notes.form.delete.error');
		},
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: getQueryKey(note.bundleId, note.userEmail ?? ''),
			});
		},
	});
};
