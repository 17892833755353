import QuantityBox from 'Components/QuantityBox';
import {
	PopoverTypes,
	QuantityBoxPlusBtnTypes,
	QuantityBoxPlusBtnVariants,
	QuantityBoxTypes,
} from 'Components/QuantityBox/constants';

import { useDirectQuantityBox } from './hooks';
import { DirectQuantityBoxProps } from './interfaces';

const DirectQuantityBox = ({
	bundle,
	className,
	isLoading = false,
	plusBtnType = QuantityBoxPlusBtnTypes.STANDARD,
	orderLimitation,
	popoverType = PopoverTypes.COMPONENT,
	countPopover,
	carouselType,
	afterAddToBasket,
	isBasket = false,
}: DirectQuantityBoxProps) => {
	const methods = useDirectQuantityBox(bundle);

	return (
		<QuantityBox
			productId={bundle?.id}
			type={QuantityBoxTypes.DIRECT}
			plusBtnVariant={QuantityBoxPlusBtnVariants.SUCCESS}
			plusBtnType={plusBtnType}
			className={className}
			isLoading={isLoading}
			isForLogged={bundle?.flags.isForLogged ?? false}
			orderLimitation={orderLimitation}
			popoverType={popoverType}
			countPopover={countPopover}
			carouselType={carouselType}
			afterAddToBasket={afterAddToBasket}
			isBasket={isBasket}
			{...methods}
		/>
	);
};

export default DirectQuantityBox;
