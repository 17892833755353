import cx from 'classnames';
import { Link } from 'react-router-dom';

import styles from '../../styles.module.css';

import type {
	PromoBlockCategoryLinkComponent,
	PromoBlockProductComponent,
} from '@/strapi-api/strapi-api';

type OfferProps = {
	title: string | undefined;
	promoText?: string;
	imageUrl?: string;
	categoryLinks?: PromoBlockCategoryLinkComponent[];
	products?: PromoBlockProductComponent[];
};

const Offer = ({
	title,
	promoText,
	imageUrl,
	categoryLinks = [],
	products = [],
}: OfferProps) => {
	const imageBaseUrl = import.meta.env.VITE_STRAPI_API_IMAGE_URI;

	const renderCategoryLinks = () => (
		<div className={styles.hotLinks}>
			{categoryLinks.map((link, index) => (
				<Link
					to={link.Link || '/'}
					className={cx(link.Icon ? styles.hotLink : styles.link)}
					key={`category-${index}`}
				>
					{link.Icon && (
						<img
							src={`${imageBaseUrl}${link.Icon.url}`}
							alt={link.Title}
							className={styles.hotLinkImg}
						/>
					)}
					{link.Icon ? (
						<div className={styles.hotLinkText}>{link.Title}</div>
					) : (
						link.Title
					)}
				</Link>
			))}
		</div>
	);

	const renderProducts = () =>
		products && (
			<div className={styles.container}>
				<div className={styles.products}>
					{products.map((product, index) => (
						<Link
							to={product.Link || '/'}
							className={styles.product}
							key={`product-${index}`}
						>
							{product.Image && (
								<img
									src={`${imageBaseUrl}${product.Image.url}`}
									alt={product.Title}
									className={styles.productImg}
								/>
							)}
							<div className={styles.productName}>{product.Title}</div>
							<div className={styles.productText}>{product.Text}</div>
						</Link>
					))}
				</div>
			</div>
		);

	return (
		<>
			<div className={styles.container}>
				<h2 className={styles.h2}>{title}</h2>
				<div className={styles.wrap}>
					<div>
						<img
							src={`${imageBaseUrl}${imageUrl}`}
							alt={title}
							className="mw-100"
						/>
					</div>
					<div className="w-100">
						{promoText && <div className={styles.text}>{promoText}</div>}
						{renderCategoryLinks()}
					</div>
				</div>
			</div>
			{renderProducts()}
		</>
	);
};

export default Offer;
