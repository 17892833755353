import { lazy, Suspense, useContext, useEffect, useRef } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import Config from 'Config';
import { DocumentHeaderAction } from 'Components/DocumentHeader/constants';
import { DocumentHeaderContext } from 'Components/DocumentHeader/context';
import { LocalizationContext } from 'Services/LocalizationService';
import useSupportAvailability from 'Hooks/useSupportAvailability';
import Loader from 'Components/View/Loader';
const BecomeSellerIcon = lazy(() => import('Components/Icons/BecomeSeller'));
const BecomeSeller1Icon = lazy(() => import('Components/Icons/BecomeSeller1'));
const ContactBig2Icon = lazy(() => import('Components/Icons/ContactBig2'));
const OneIcon = lazy(() => import('Components/Icons/One'));
const TwoIcon = lazy(() => import('Components/Icons/Two'));
const ThreeIcon = lazy(() => import('Components/Icons/Three'));
const ContactSmallMailIcon = lazy(
	() => import('Components/Icons/ContactSmallMail')
);
const ContactSmallPhoneIcon = lazy(
	() => import('Components/Icons/ContactSmallPhone')
);
import Card from 'Components/View/Card';
import ContainerFullWidth from 'Components/View/ContainerFullWidth';
import Container from 'Components/View/Container';
import TextHighlighted from 'Components/View/TextHighlighted';
import { useMainHeaderHeight } from 'Hooks/useMainHeaderHeight';

import RegisterForm from './RegisterForm';
import styles from './styles.module.css';

const BecomeSeller = () => {
	const { useFormatMessageInstance } = useContext(LocalizationContext);
	const { dispatch } = useContext(DocumentHeaderContext);
	const headerOffset = useMainHeaderHeight();

	const t = useFormatMessageInstance;
	const supportAvailability = useSupportAvailability();

	const registerFormRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		dispatch({
			type: DocumentHeaderAction.setTitle,
			value: `${t(
				{ id: 'app.title.page' },
				{ title: `${t({ id: 'routes.becomeSupplier.name' })}` }
			)}`,
		});
	}, [dispatch, t]);

	const handleOnRegisterBtnClick = () => {
		if (registerFormRef.current) {
			// fixed header offset
			const yOffset = -headerOffset - 10;
			const y =
				registerFormRef.current.getBoundingClientRect().top +
				window.scrollY +
				yOffset;

			window.scrollTo({ top: y, behavior: 'smooth' });
		}
	};
	return (
		<section id="content-wrapper">
			<ContainerFullWidth>
				<Card className={styles.mainCard}>
					<Suspense fallback={<Loader blank />}>
						<BecomeSellerIcon className="d-none d-sm-block" />
					</Suspense>
					<h2 className="h2 text-center mb-0">
						{t({ id: 'becomeSupplier.title' })}
					</h2>
					<Suspense fallback={<Loader blank />}>
						<BecomeSellerIcon className="d-none d-sm-block" />
					</Suspense>
				</Card>
			</ContainerFullWidth>
			<ContainerFullWidth containerClassName="mt-0 text-center">
				<button
					className={cx(
						styles.btn,
						'vinisto-btn vinisto-bg-green px-5 mt-4 mb-0 mx-3'
					)}
					onClick={handleOnRegisterBtnClick}
				>
					{t({ id: 'becomeSupplier.btn.register' })}
				</button>
				<a
					href={Config.market.clientAdminUrl}
					className={cx(
						styles.btn,
						'vinisto-btn vinisto-bg px-5 mt-4 mb-0 mx-3'
					)}
					target="_blank"
					rel="noreferrer"
				>
					{t({ id: 'becomeSupplier.btn.login' })}
				</a>
			</ContainerFullWidth>
			<ContainerFullWidth>
				<div className={styles.dots}>
					<ul className={styles.list}>
						<li className={styles.listItem}>
							{t(
								{ id: 'becomeSupplier.benefits.text1' },
								{
									vinisto: (
										<TextHighlighted>
											{t({ id: 'becomeSupplier.benefits.text1.vinisto' })}
										</TextHighlighted>
									),
								}
							)}
						</li>
						<li className={styles.listItem}>
							{t(
								{ id: 'becomeSupplier.benefits.text2' },
								{
									vinisto: (
										<TextHighlighted>
											{t({ id: 'becomeSupplier.benefits.text2.vinisto' })}
										</TextHighlighted>
									),
								}
							)}
						</li>
						<li className={styles.listItem}>
							{t(
								{ id: 'becomeSupplier.benefits.text3' },
								{
									vinisto: (
										<TextHighlighted>
											{t({ id: 'becomeSupplier.benefits.text3.vinisto' })}
										</TextHighlighted>
									),
									lineBreak: <br />,
								}
							)}
						</li>
						<li className={styles.listItem}>
							{t(
								{ id: 'becomeSupplier.benefits.text4' },
								{
									vinisto: (
										<TextHighlighted>
											{t({ id: 'becomeSupplier.benefits.text4.vinisto' })}
										</TextHighlighted>
									),
									lineBreak: <br />,
								}
							)}
						</li>
						<li className={styles.listItem}>
							{t(
								{ id: 'becomeSupplier.benefits.text5' },
								{
									vinisto: (
										<TextHighlighted>
											{t({ id: 'becomeSupplier.benefits.text5.vinisto' })}
										</TextHighlighted>
									),
								}
							)}
						</li>
						<li className={styles.listItem}>
							{t(
								{ id: 'becomeSupplier.benefits.text6' },
								{
									vinisto: (
										<TextHighlighted>
											{t({ id: 'becomeSupplier.benefits.text6.vinisto' })}
										</TextHighlighted>
									),
								}
							)}
						</li>
					</ul>
				</div>
			</ContainerFullWidth>
			<ContainerFullWidth containerClassName="mt-0 text-center mb-4">
				<h2 className={styles.heading}>
					{t(
						{ id: 'becomeSupplier.hero.text1' },
						{
							vinisto: (
								<TextHighlighted>
									{t({ id: 'becomeSupplier.hero.text1.vinisto' })}
								</TextHighlighted>
							),
						}
					)}
				</h2>
				<h2 className={styles.heading}>
					{t(
						{ id: 'becomeSupplier.hero.text2' },
						{
							threeSimpleSteps: (
								<TextHighlighted>
									{t({ id: 'becomeSupplier.hero.text3' })}
								</TextHighlighted>
							),
						}
					)}
				</h2>
			</ContainerFullWidth>
			<Container>
				<div className="col-12 col-xl-4 mb-3 mb-xl-0">
					<div className="p-3 vinisto-font-18 text-center">
						<div className="mb-2">
							<Suspense fallback={<Loader blank />}>
								<OneIcon />
							</Suspense>
						</div>
						<h3 className="color-primary">
							{t({ id: 'becomeSupplier.steps.step1.title' })}
						</h3>
						<p>{t({ id: 'becomeSupplier.steps.step1.description' })}</p>
					</div>
				</div>
				<div className="col-12 col-xl-4 mb-3 mb-xl-0">
					<div className="p-3 vinisto-font-18 text-center">
						<div className="mb-2">
							<Suspense fallback={<Loader blank />}>
								<TwoIcon />
							</Suspense>
						</div>
						<h3 className="color-primary">
							{t({ id: 'becomeSupplier.steps.step2.title' })}
						</h3>
						<p>{t({ id: 'becomeSupplier.steps.step2.description' })}</p>
					</div>
				</div>

				<div className="col-12 col-xl-4">
					<div className="p-3 vinisto-font-18 text-center">
						<div className="mb-2">
							<Suspense fallback={<Loader blank />}>
								<ThreeIcon />
							</Suspense>
						</div>
						<h3 className="vinisto-color-success">
							{t({ id: 'becomeSupplier.steps.step3.title' })}
						</h3>
						<p>{t({ id: 'becomeSupplier.steps.step3.description' })}</p>
					</div>
				</div>
			</Container>
			<div
				className={cx('container', styles.registerFormWrapper)}
				ref={registerFormRef}
			>
				<div className="row justify-content-center">
					<div className={cx(styles.registerForm, 'col-12 col-md-8')}>
						<RegisterForm />
					</div>
				</div>
			</div>
			<Container>
				<div className="col-12 col-xl-6 mb-3 mb-xl-0">
					<Card className={styles.card}>
						<div className={styles.cardWrap}>
							<div>
								<Suspense fallback={<Loader blank />}>
									<BecomeSeller1Icon className={styles.cardIcon} />
								</Suspense>
							</div>
							<div className={styles.cardText}>
								<h2 className="color-primary h3">
									{t({ id: 'becomeSupplier.faq.title' })}
								</h2>
								<p className={styles.cardDesc}>
									{t(
										{ id: 'becomeSupplier.faq.text' },
										{
											vinisto: (
												<TextHighlighted>
													{t({
														id: 'becomeSupplier.faq.text.vinisto',
													})}
												</TextHighlighted>
											),
											lineBreak: <br />,
										}
									)}
								</p>
								<div className={styles.ctaWrap}>
									<Link
										to={`/${t({
											id: 'routes.faqSeller.route',
										})}`}
										className="vinisto-btn link-light vinisto-bg px-3"
									>
										{t({ id: 'becomeSupplier.faq.btn' })}{' '}
										<span className="fw-bolder">&gt;</span>
									</Link>
								</div>
							</div>
						</div>
					</Card>
				</div>
				<div className="col-12 col-xl-6">
					<Card className={styles.card}>
						<div className={styles.cardWrap}>
							<div>
								<Suspense fallback={<Loader blank />}>
									<ContactBig2Icon className={styles.cardIcon} />
								</Suspense>
							</div>
							<div className={styles.cardText}>
								<h2 className="color-primary h3">
									{t({ id: 'becomeSupplier.support.title' })}
								</h2>
								<p className={styles.cardDesc}>
									{t({ id: 'becomeSupplier.support.text' })}
								</p>
								<div className={styles.ctaWrap}>
									<div
										className={cx(
											styles.cta,
											'underline-effect underline-effect--vinisto'
										)}
									>
										<Suspense fallback={<Loader blank />}>
											<ContactSmallPhoneIcon />
										</Suspense>
										<div className="d-flex flex-column">
											<span className="color-primary">
												{Config.market.b2bPhoneNumber}
											</span>
											<span className="color-primary">
												{t(
													{ id: 'becomeSupplier.support.availability' },
													supportAvailability
												)}
											</span>
										</div>
									</div>
									<div
										className={cx(
											styles.cta,
											'underline-effect underline-effect--vinisto'
										)}
									>
										<Suspense fallback={<Loader blank />}>
											<ContactSmallMailIcon />
										</Suspense>
										<a
											href={`mailto:${Config.market.b2bSupportEmail}`}
											className="underline-item"
										>
											{Config.market.b2bSupportEmail}
										</a>
									</div>
								</div>
							</div>
						</div>
					</Card>
				</div>
			</Container>
		</section>
	);
};

export default BecomeSeller;
