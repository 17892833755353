import { useCallback, useContext, useState } from 'react';
import { get, set } from 'lodash-es';
import { LOGIN_MODAL } from 'Components/Modal/constants';
import { PASSWORD_TYPE } from 'Components/Form/Components/Password/constants';
import { requireEmail } from 'Components/Form/validators';
import { ModalContext } from 'Components/Modal/context';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { LocalizationContext } from 'Services/LocalizationService';
import {
	Form,
	InputCheckBox,
	InputEmail,
	InputPassword,
} from 'Components/Form';
import VinistoLink from 'Components/VinistoLink';
import { TEST_IDS } from 'Constants/test-ids';
import OAuthButton from 'vinisto_ui/src/components/oauth-button';
import { useOAuthLogin } from 'vinisto_shared/src/oauth';
import { NotificationsContext } from 'Services/NotificationService';

import styles from './styles.module.css';

import {
	VinistoHelperDllEnumsUserLoginHashType,
	VinistoHelperDllEnumsUserTokenType,
} from '@/api-types/user-api';

const isOauthFacebookEnabled =
	import.meta.env.VITE_OAUTH_FACEBOOK_ENABLED === 'true';

const isOauthGoogleEnabled =
	import.meta.env.VITE_OAUTH_GOOGLE_ENABLED === 'true';

const isOauthEnabled = isOauthFacebookEnabled || isOauthGoogleEnabled;

const RegistrationModal = () => {
	const modalContext = useContext(ModalContext);
	const authenticationContext = useContext(AuthenticationContext);
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const { handleOnOAuthLogIn } = useContext(AuthenticationContext);
	const { handleShowErrorNotification } = useContext(NotificationsContext);

	const handleOnRegister = (formValues: Record<any, any>) => {
		authenticationContext.handleOnRegister(
			formValues.email,
			formValues.password,
			!!formValues.isNewsletterActive,
			!!formValues.isAgreementCC
		);
	};

	const handleOpenLogInModal = useCallback(() => {
		modalContext.handleCloseModal();
		setTimeout(() => {
			modalContext.handleOpenModal(LOGIN_MODAL);
		}, 200);
	}, [modalContext]);

	const customRegistrationValidationFunction = (
		values: Record<any, any>
	): Record<any, any> => {
		const customValidationErrors = {};
		const isAgreementCC = get(values, 'isAgreementCC', null);

		if (!isAgreementCC) {
			set(
				customValidationErrors,
				'isAgreementCC',
				'modal.registration.isAgreementCC.requiredValidation'
			);
		}

		return customValidationErrors;
	};

	const [termsChecked, setTermsChecked] = useState<boolean>(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [termsClick, setTermsClick] = useState<boolean>(false);

	const handleInputOnClick = useCallback(() => {
		setTermsClick(true);
		setTermsChecked(!termsChecked);
	}, [termsChecked]);

	const { handleGoogleLogin, handleFacebookLogin } = useOAuthLogin({
		requestData: {
			hashType: VinistoHelperDllEnumsUserLoginHashType.SHOP,
			countryOfSale: localizationContext.countryOfSale,
			currency: localizationContext.activeCurrency.currency,
			language: localizationContext.activeLanguageKey,
		},
		onSuccess: handleOnOAuthLogIn,
		onError: () => {
			handleShowErrorNotification('notification.message.logIn.error');
		},
	});

	return (
		<div className="vinisto-popup__split">
			<div className="vinisto-popup__split__form">
				<Form
					submitCallback={handleOnRegister}
					submitText={'modal.registration.submitButtonText'}
					customValidationFunction={customRegistrationValidationFunction}
					initializationValues={{
						isAgreementCC: false,
						isNewsletterActive: false,
					}}
					submitDataTestid={TEST_IDS.REGISTRATION_SUBMIT_BUTTON}
				>
					<InputEmail
						validate={requireEmail}
						dataTestid={TEST_IDS.REGISTRATION_EMAIL_INPUT}
					/>

					<InputPassword
						type={PASSWORD_TYPE}
						dataTestid={TEST_IDS.REGISTRATION_PASSWORD_INPUT}
					/>

					<div className="vinisto-popup__checkboxes">
						<div className="d-flex mb-3">
							<InputCheckBox
								identifier={'isNewsletterActive'}
								name={'isNewsletterActive'}
								label={'modal.registration.isNewsletterActive.label'}
								isErrorVisible={false}
								dataTestid={TEST_IDS.REGISTRATION_NEWSLETTER_CHECKBOX}
							/>
						</div>
						<div className="d-flex mb-3">
							<InputCheckBox
								identifier={'isAgreementCC'}
								name={'isAgreementCC'}
								label={
									<>
										{t(
											{
												id: 'modal.registration.isAgreementCC.label',
											},
											{
												termsAndConditionsLink: (
													<VinistoLink
														to={`${t({
															id: 'routes.termsAndConditions.route',
														})}`}
														target="_blank"
														className="underline-item vinisto-color-success fw-normal"
													>
														{t({
															id: 'modal.registration.isAgreementCC.label.termsAndConditions',
														})}
													</VinistoLink>
												),
												privacyPolicyLink: (
													<VinistoLink
														to={`${t({
															id: 'routes.privacyProtection.route',
														})}`}
														target="_blank"
														className="underline-item vinisto-color-success fw-normal"
													>
														{t({
															id: 'modal.registration.isAgreementCC.label.privacyPolicy',
														})}
													</VinistoLink>
												),
											}
										)}
									</>
								}
								onClick={handleInputOnClick}
								showError
								dataTestid={TEST_IDS.REGISTRATION_TERMS_CHECKBOX}
							/>
						</div>
					</div>
				</Form>
				{isOauthEnabled && (
					<div className={styles.oauthWrapper}>
						<div className={styles.oauthTextWrapper}>
							<span>{t({ id: 'modal.registration.oauth.text' })}</span>
						</div>
						<div className={styles.oauthButtonsWrapper}>
							{isOauthGoogleEnabled && (
								<OAuthButton
									onClick={() => handleGoogleLogin()}
									provider={VinistoHelperDllEnumsUserTokenType.Google}
								/>
							)}
							{isOauthFacebookEnabled && (
								<OAuthButton
									onClick={() => handleFacebookLogin()}
									provider={VinistoHelperDllEnumsUserTokenType.Facebook}
								/>
							)}
						</div>
					</div>
				)}
			</div>
			<button
				onClick={handleOpenLogInModal}
				className="vinisto-btn vinisto-bg-outline-green mobile-only w-100"
			>
				{t({ id: 'modal.logIn.submitButtonText' })}
			</button>
			<div className="vinisto-popup__split__cta">
				<ul className="vinisto-popup__usp">
					<li>
						{t(
							{
								id: 'modal.registration.usp1.label',
							},
							{
								specialProducts: (
									<span className="fw-bold">
										{t({
											id: 'modal.registration.usp1.label.specialProducts',
										})}
									</span>
								),
								prices: (
									<span className="fw-bold">
										{t({
											id: 'modal.registration.usp1.label.prices',
										})}
									</span>
								),
								club: (
									<span className="fw-bold vinisto-color-success">
										{t({
											id: 'modal.registration.usp1.label.club',
										})}
									</span>
								),
							}
						)}
					</li>
					<li>
						{t(
							{
								id: 'modal.registration.usp2.label',
							},
							{
								status: (
									<span className="fw-bold">
										{t({
											id: 'modal.registration.usp2.label.status',
										})}
									</span>
								),
							}
						)}
					</li>
					<li>
						{t(
							{
								id: 'modal.registration.usp3.label',
							},
							{
								contests: (
									<span className="fw-bold">
										{t({
											id: 'modal.registration.usp3.label.contests',
										})}
									</span>
								),
								first: (
									<span className="fw-bold">
										{t({
											id: 'modal.registration.usp3.label.first',
										})}
									</span>
								),
							}
						)}
					</li>
				</ul>
				<div className="text-end desktop-only">
					<button
						onClick={handleOpenLogInModal}
						className="vinisto-btn vinisto-bg-outline-green"
					>
						{t({ id: 'modal.logIn.submitButtonText' })}
					</button>
				</div>
			</div>
		</div>
	);
};

export default RegistrationModal;
