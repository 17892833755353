import { FC } from 'react';

import { IIconProps } from './Interfaces';

const HeaderSomelierIcon: FC<IIconProps> = ({ className }) => {
	return (
		<svg
			width={12}
			height={14}
			viewBox="0 0 88 139"
			className={className}
		>
			<path
				fill="#921442"
				d="M82.93,83.84c-1.84-1.68-4.25-2.55-6.73-2.43-2.5.11-4.8,1.19-6.43,2.99l-12.46,13.15v2.53c0,7.47-5.57,13.89-12.95,14.94l-15.2,2.17-1.02-7.09,15.2-2.17c4.38-.63,7.55-4.85,6.66-9.4-.74-3.76-4.16-6.38-7.98-6.38H10.75c-5.94,0-10.75,4.81-10.75,10.75v25.08c0,5.94,4.81,10.75,10.75,10.75h34.63l38.24-41.76c3.4-3.8,3.09-9.69-.68-13.13h0Z"
			/>
			<path
				fill="#921442"
				d="M87.94,10.64v-.54c-.17-3.52-2.06-6.53-4.84-8.33-1.73-1.12-3.79-1.77-5.97-1.77H29.88c-2.34,0-4.54.74-6.33,2.01-2.58,1.82-4.33,4.75-4.48,8.09v25.53c0,4.38,2.4,8.45,6.24,10.64l21.98,12.52c.59.33,1.21.61,1.84.85.06.43.09.89.09,1.36,0,8.29.02,16.57.02,24.86,0,2.62,2.12,4.75,4.75,4.75h0c2.62,0,4.75-2.12,4.75-4.75,0-8.06.03-16.11,0-24.14,0-.76.26-1.84.37-2.54.65-.24.11-.06.72-.41l21.94-12.5c3.87-2.19,6.23-6.26,6.23-10.66V10.64h-.07,0Z"
			/>
		</svg>
	);
};

export default HeaderSomelierIcon;
