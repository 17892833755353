import { FC, useContext, useEffect } from 'react';
import cx from 'classnames';
import { DocumentHeaderAction } from 'Components/DocumentHeader/constants';
import { PHONE_NUMBER } from 'Components/Navbar/constants';
import { formatPhoneNumber } from 'Components/Navbar/helpers';
import Config from 'Config';
import { DocumentHeaderContext } from 'Components/DocumentHeader/context';
import { LocalizationContext } from 'Services/LocalizationService';
import VinistoLink, { VinistoAnchorLink } from 'Components/VinistoLink';
import ImageLocal from 'Components/View/ImageLocal';
import Card from 'Components/View/Card';
import ContainerFullWidth from 'Components/View/ContainerFullWidth';
import Container from 'Components/View/Container';

import styles from './styles.module.css';

const ComplaintsReturns: FC = () => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const documentHeaderContext = useContext(DocumentHeaderContext);

	useEffect(() => {
		documentHeaderContext.dispatch({
			type: DocumentHeaderAction.setTitle,
			value: `${t(
				{ id: 'app.title.page' },
				{ title: `${t({ id: 'routes.returns.name' })}` }
			)}`,
		});
	}, []);

	return (
		<section id="content-wrapper">
			<ContainerFullWidth>
				<Card className={styles.headingCard}>
					<ImageLocal
						fileName="return-policy-icon-1.svg"
						alt=""
						className="d-none d-sm-block"
					/>
					<h1 className="color-primary h2 text-center">
						Reklamace a vrácení zboží
					</h1>
					<ImageLocal
						fileName="return-policy-icon-1.svg"
						alt=""
						className="d-none d-sm-block"
					/>
				</Card>
			</ContainerFullWidth>
			<ContainerFullWidth>
				<Card className="text-center">
					<h2 className="color-primary h2">
						Jak postupovat při reklamaci a vrácení zboží?
					</h2>
					<p>
						Spletli jsme Vaši objednávku nebo Vám přišel poškozený balík?
						Upřímně nás to mrzí. K reklamacím a vrácení zboží se ve{' '}
						<span className="vinisto-color-success">vinistu</span> stavíme čelem
						a problémy s objednávkou s Vámi rychle vyřešíme.
					</p>
				</Card>
			</ContainerFullWidth>
			<ContainerFullWidth>
				<Card className="text-center">
					<h2 className="color-primary h2">Kam zaslat reklamované zboží?</h2>
					<div className={styles.ctaWrap}>
						<div
							className={cx(
								styles.cta,
								'underline-effect underline-effect--vinisto'
							)}
						>
							<ImageLocal
								fileName="return-policy-icon-2.svg"
								alt=""
							/>
							<div className="d-flex flex-column align-items-start">
								<span className="color-primary">vinisto s.r.o.</span>
								<span className="color-primary">
									Sanderova 1366/26, 170 00 Praha 7
								</span>
							</div>
						</div>
						<div
							className={cx(
								styles.cta,
								'underline-effect underline-effect--vinisto'
							)}
						>
							<ImageLocal
								fileName="contact-small-phone.svg"
								alt=""
							/>
							<div className="d-flex flex-column align-items-start">
								<a
									href={`tel:${PHONE_NUMBER}`}
									className="color-primary"
								>
									{formatPhoneNumber(PHONE_NUMBER)}
								</a>
								<span className="color-primary">Po-Pá; 8-20:00</span>
							</div>
						</div>
						<div
							className={cx(
								styles.cta,
								'underline-effect underline-effect--vinisto'
							)}
						>
							<ImageLocal
								fileName="contact-small-mail.svg"
								alt=""
							/>
							<a
								href={`mailto:${Config.market.supportEmail}`}
								className="underline-item"
							>
								{Config.market.supportEmail}
							</a>
						</div>
					</div>
				</Card>
			</ContainerFullWidth>
			<Container>
				<div className="col-12 col-xl-6 mb-3">
					<Card className="pb-3 text-left">
						<div className="vinisto-font-18">
							<h2 className={cx(styles.smallHeading, 'h3')}>
								Reklamace vadného zboží
							</h2>
							<p>
								Pro reklamaci zboží kontaktujte reklamační oddělení na{' '}
								<a
									href={`mailto:${Config.market.supportEmail}`}
									className="color-primary fw-bolder"
								>
									{Config.market.supportEmail}
								</a>{' '}
								nebo telefonicky na čísle{' '}
								<a
									href={`tel:${PHONE_NUMBER}`}
									className="color-primary fw-bolder"
								>
									{formatPhoneNumber(PHONE_NUMBER)}
								</a>
								. Před řešením reklamace si připravte:
							</p>
							<ul className={styles.list}>
								<li className={styles.listItem}>doklad o nákupu zboží,</li>
								<li className={styles.listItem}>číslo objednávky,</li>
								<li className={styles.listItem}>
									v případě, že nemáte doklad o nákupu, předložte jiný dokument
									dokládající datum zakoupení zboží v našem obchodě,
								</li>
								<li className={styles.listItem}>
									k reklamaci zboží využijte{' '}
									<VinistoAnchorLink
										href="/assets/documents/pdf/vinisto_reklamace_odstoupeni_formular_R01.pdf"
										className="color-primary fw-bolder text-decoration-underline"
										target="_blank"
										rel="noreferrer"
									>
										reklamační protokol
									</VinistoAnchorLink>
									, který připojíte ke zpětné zásilce.
								</li>
							</ul>
							<p>
								Po ověření objednávky začíná{' '}
								<span className="color-primary fw-bolder">
									30denní reklamační lhůta
								</span>
								. Během ní od Vás můžeme chtít dodání reklamované objednávky. Tu
								nám zašlete libovolnou přepravní službou v původním nebo
								adekvátním obalu tak, aby nedošlo k jejímu poškození.
							</p>
							<p>
								V případě, že nedodáte požadované zboží kompletní, a my ho
								potřebujeme ke zjištění reklamované vady, začíná lhůta pro
								reklamaci po dodání všech chybějících částí objednávky.
							</p>
						</div>
					</Card>
				</div>
				<div className="col-12 col-xl-6 mb-3">
					<Card>
						<div className="vinisto-font-18">
							<h2 className={cx(styles.smallHeading, 'h3')}>
								Odstoupení od smlouvy a vrácení zboží do 14 dnů
							</h2>
							<p>
								Při vrácení zboží se řídíme § 1829 Občanského zákoníku – podle
								něho má zákazník při nákupu mimo běžné obchodní prostory možnost
								odstoupení od smlouvy bez udání důvodu{' '}
								<span className="color-primary fw-bolder">
									do 14 dnů od dodání objednávky
								</span>
								.
							</p>
							<p>
								V případě vrácení zboží nám ho zašlete libovolnou přepravní
								službou nebo předejte na výše uvedené adrese nejpozději do 14
								dnů od odstoupení od kupní smlouvy. Objednávku dodejte v
								kompletním stavu{' '}
								<span className="color-primary fw-bolder">
									včetně případných dárků podle čl. IV odst. 15
								</span>{' '}
								<VinistoLink
									to={`/${t({
										id: 'routes.termsAndConditions.route',
									})}`}
									className="color-primary fw-bolder text-decoration-underline"
								>
									VOP
								</VinistoLink>{' '}
								internetového obchodu{' '}
								<span className="color-primary fw-bolder">vinisto.cz</span>.
							</p>
							<p>
								Vzhledem k povaze prodávaného zboží lze vrátit pouze{' '}
								<span className="color-primary fw-bolder">
									neotevřené lahve
								</span>{' '}
								a to ideálně v původním balení.{' '}
								<span className="color-primary fw-bolder">
									Za problémy způsobené nevhodným balením zpětné zásilky
									neručíme a případnému vrácení peněz nemusíme vyhovět.
								</span>
							</p>
						</div>
					</Card>
				</div>
				<div className="col-12 col-xl-6 mb-3">
					<Card>
						<div className="vinisto-font-18">
							<h2 className={cx(styles.smallHeading, 'h3')}>
								Reklamace zboží poškozeného přepravou
							</h2>
							<p>
								Přepravě a vhodnému balení věnujeme spoustu času. Může ale dojít
								k poškození zboží přepravou. Zákazníky proto upozorňujeme –{' '}
								<span className="color-primary fw-bolder">
									kontrolujte stav balíku ještě před jeho převzetím
								</span>
								. Krabice s vínem a destiláty od nás odcházejí ve stoprocentním
								stavu a stejně tak by měly dojít i k Vám.
							</p>
							<p>
								V případě podezření na poškození zboží přepravou{' '}
								<span className="color-primary fw-bolder">
									objednávku nepřebírejte
								</span>
								. Co nejdříve kontaktujte naše zákaznické centrum a o vzniklé
								vadě nás informujte.
							</p>
						</div>
					</Card>
				</div>
				<div className="col-12 col-xl-6 mb-3">
					<Card>
						<div className="vinisto-font-18">
							<h2 className={cx(styles.smallHeading, 'h3')}>
								Vrácení peněz za objednávku
							</h2>
							<p>
								Finanční prostředky zákazníkům vracíme v případě platby předem
								na stejný účet, ze kterého objednávku uhradili. V případě platby
								na dobírku si od Vás vyžádáme číslo účtu, na který chcete peníze
								poukázat.
							</p>
							<p>
								Peníze vracíme v co nejkratším časovém úseku, nejpozději však do
								14 dnů od uznání reklamace nebo v případě odstoupení od smlouvy
								od obdržení vráceného zboží. Dopravu zboží zpět k nám hradí
								zákazník. V případě uznané reklamace Vám tyto náklady vrátíme
								spolu s cenou zboží.
							</p>
							<p>
								Kompletní reklamační řád je součástí{' '}
								<VinistoLink
									to={`/${t({
										id: 'routes.termsAndConditions.route',
									})}`}
									className="color-primary fw-bolder text-decoration-underline"
								>
									Všeobecných obchodních podmínek
								</VinistoLink>
								.
							</p>
						</div>
					</Card>
				</div>
			</Container>
		</section>
	);
};

export default ComplaintsReturns;
