import Api from '@/strapi-api/client';

export const getPromos = (promoId: string) => {
	const data = Api.promos.getPromos({
		// @ts-expect-error wrongly typed
		['filters[promoId][$eq]']: promoId,
		// ['populate']: '*',
		['populate[Banners][populate][0]']: 'Icon',
		['populate']: 'HeroImage',
		['populate[PromoBlocks][populate][0]']: 'CategoryLinks',
		['populate[PromoBlocks][populate][1]']: 'CategoryLinks.Icon',
		['populate[PromoBlocks][populate][2]']: 'Products',
		['populate[PromoBlocks][populate][3]']: 'Image',
		['populate[PromoBlocks][populate][4]']: 'Products.Image',
	});
	return data;
};
