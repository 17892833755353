import { FC } from 'react';

import { IIconProps } from './Interfaces';

const HeaderPencilIcon: FC<IIconProps> = ({ className }) => {
	return (
		<svg
			width={14}
			height={14}
			viewBox="0 0 91 125"
			className={className}
		>
			<path
				fill="#911441"
				d="M65.82,20.18l-.54-18.56c-.02-.55-.04-1.11-.05-1.62l-16.26,10.03c-1.91,1.18-3.33,2.96-4.07,5.01-.74-2.05-2.15-3.83-4.07-5.01L24.57,0c-.02.51-.03,1.08-.05,1.62l-.54,18.56c-.1,3.26,1.44,6.57,4.26,8.29,0,0,12.05,6.78,13.92,7.72,1.66.83,3.52.54,5.17-.32l14.24-7.4c2.81-1.72,4.35-5.03,4.26-8.29h0Z"
			/>
			<path
				fill="#911441"
				d="M37.35,72.42l-13.08,7.59c-2.3,1.34-5.14,1.34-7.45,0l-13.1-7.59C1.43,71.08.01,68.62,0,65.95v-15.17c0-2.67,1.41-5.13,3.71-6.47l13.09-7.59c2.3-1.34,5.14-1.34,7.45,0l13.09,7.59c2.3,1.33,3.72,3.79,3.72,6.46v15.17c0,2.67-1.41,5.13-3.71,6.47h0Z"
			/>
			<path
				fill="#911441"
				d="M87,71.84l-13.08,7.59c-2.3,1.34-5.14,1.34-7.45,0l-13.1-7.59c-2.3-1.33-3.72-3.79-3.72-6.46v-15.17c0-2.67,1.41-5.13,3.71-6.47l13.09-7.59c2.3-1.34,5.14-1.34,7.45,0l13.09,7.59c2.3,1.33,3.72,3.79,3.72,6.46v15.17c0,2.67-1.41,5.13-3.71,6.47Z"
			/>
			<path
				fill="#911441"
				d="M62.51,115.94l-13.08,7.59c-2.3,1.34-5.14,1.34-7.45,0l-13.1-7.59c-2.3-1.33-3.72-3.79-3.72-6.46v-15.17c0-2.67,1.41-5.13,3.71-6.47l13.09-7.59c2.3-1.34,5.14-1.34,7.45,0l13.09,7.59c2.3,1.33,3.72,3.79,3.72,6.46v15.17c0,2.67-1.41,5.13-3.71,6.47Z"
			/>
		</svg>
	);
};

export default HeaderPencilIcon;
