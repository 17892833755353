import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { isExternalLink } from 'vinisto_shared';

type TInfoColumnProps = {
	links: {
		to: string | null;
		name: ReactNode;
		as?: 'link' | 'button';
	}[];
};

const InfoColumn = ({ links }: TInfoColumnProps) => {
	return (
		<ul className="nav flex-column underline-effect">
			{links?.map(({ name, to, as = 'link' }, index) => {
				return (
					<li
						key={index}
						className="nav-item"
					>
						{typeof name === 'string' && typeof to === 'string' ? (
							<NavLink
								to={to}
								className={cx({
									'vinisto-btn vinisto-success-btn p-2': as === 'button',
									'vnav-link p-0 underline-item': as === 'link',
								})}
								{...(isExternalLink(to)
									? { target: '_blank', rel: 'noopener noreferrer' }
									: {})}
							>
								{name}
							</NavLink>
						) : (
							<div className="vnav-link">{name}</div>
						)}
					</li>
				);
			})}
		</ul>
	);
};

export default InfoColumn;
