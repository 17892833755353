import { MouseEvent, Suspense, useCallback, useContext, useMemo } from 'react';
import cx from 'classnames';
import { debounce, get, isNaN, parseInt, uniqueId } from 'lodash-es';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { MIN_QUANTITY } from 'Components/ProductBox/Components/QuantityBox/constants';
import { CHANGE_DELAY, QUANTITY_TO_REMOVE } from 'Pages/Cart/constants';
import getBundleImage, { IMAGE_SIZE_THUMB_64x80 } from 'Helpers/getBundleImage';
import getFlagSpecification from 'Helpers/getFlagSpecification';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import { BasketContext } from 'Services/BasketService';
import { LocalizationContext } from 'Services/LocalizationService';
import BundleProducer from 'Components/ProductBox/Components/BundleProducer';
import Loader from 'Components/View/Loader';
import CloseSmallIcon from 'Components/Icons/CloseSmall';
import { VolumeDiscount } from 'vinisto_ui';
import { getDiscountPriceValues } from 'vinisto_shared/src/price/get-discount-prices';
import { getLocalizedPrice } from 'vinisto_shared/src/price/get-localized-price';
import { DirectQuantityBox } from 'Components/QuantityBox';

import { IActiveCartItemMobileProps } from './interfaces';
import styles from './../../styles.module.css';

import { bundleAdapter } from '@/index';

const ActiveCartItemMobile = ({
	cartItem,
	isLoading = false,
}: IActiveCartItemMobileProps) => {
	const { useFormatMessage } = useContext(LocalizationContext);
	const t = useFormatMessage();

	const basketContext = useContext(BasketContext);
	const changeCartItemQuantity = get(
		basketContext,
		'handleOnChangeItemQuantity',
		() => {}
	);

	const bundleId = cartItem?.bundleId ?? '';
	const bundleData = cartItem?.bundle ?? null;
	const supplierName = bundleData?.supplier?.nameWeb ?? '';

	const availableCount = bundleData?.availableCount ?? 0;
	const minAllowedQuantity = Math.min(availableCount, MIN_QUANTITY);
	const bundleQuantity = get(cartItem, 'quantity', minAllowedQuantity);

	const getLocalizedValue = useLocalizedValue();

	const currency = basketContext.basketState?.currency;

	const basePrice = cartItem?.itemPrice;

	const discountedPrice = cartItem?.itemDiscountPrice;

	const {
		volumeDiscountVolume,
		discountedPriceWithoutVat,
		discountedPriceWithVat,
		isDiscounted,
	} = getDiscountPriceValues({
		quantityInBasket: bundleQuantity,
		basePrice,
		discountedPrice,
	});

	const priceWithVat = discountedPriceWithVat ?? basePrice?.valueWithVat;

	const priceWithoutVat = discountedPriceWithoutVat ?? basePrice?.value;

	const totalPriceWithVAT = (priceWithVat ?? 0) * bundleQuantity;

	const totalPriceWithoutVAT = (priceWithoutVat ?? 0) * bundleQuantity;

	const originalTotalPriceWithVAT = isDiscounted
		? (basePrice?.valueWithVat ?? 0) * bundleQuantity
		: null;

	const totalSavings = Number(originalTotalPriceWithVAT) - totalPriceWithVAT;

	const { shortVariety: producerName, component: flag } = getFlagSpecification(
		get(bundleData, 'specificationDetails', [])
	);

	const changeQuantity = useCallback(
		debounce((value: any) => {
			const newQuantity = parseInt(value);
			if (!isNaN(newQuantity) && newQuantity >= QUANTITY_TO_REMOVE) {
				changeCartItemQuantity(
					Math.max(newQuantity, QUANTITY_TO_REMOVE),
					bundleId
				);
			}
		}, CHANGE_DELAY),
		[bundleId, changeCartItemQuantity]
	);

	const handleOnRemove = useCallback(
		(event?: MouseEvent<HTMLElement>) => {
			event?.preventDefault();
			event?.stopPropagation();
			changeQuantity.cancel();
			changeCartItemQuantity(QUANTITY_TO_REMOVE, bundleId);
		},
		[changeQuantity, bundleId, changeCartItemQuantity]
	);

	const bundle = useMemo(() => {
		if (!cartItem?.bundle) return null;
		// @ts-expect-error uncompatible enums/unions across apis
		return bundleAdapter.fromApi(cartItem.bundle);
	}, [cartItem]);

	return (
		<div
			className={cx(
				'vinisto-user-favorite',
				'vinisto-cart__item--mobile',
				{ discounted: isDiscounted },
				{ pointer: !isLoading }
			)}
		>
			<div className="vinisto-user-orders__orders__order-body">
				<div className="vinisto-user-orders__orders__order-body__item">
					{!isLoading && (
						<Link
							className="vinisto-wine__item-overlay"
							to={`/${t({
								id: 'routes.product.route',
							})}/${getLocalizedValue(get(bundleData, 'url', []))}`}
						/>
					)}
					<div
						className={styles.removeButton}
						onClick={handleOnRemove}
						role="button"
						tabIndex={0}
					>
						<Suspense fallback={<Loader blank />}>
							<CloseSmallIcon
								id={uniqueId()}
								alt={t({ id: 'alt.delete' })}
								title={``}
								className={`CloseSmallIcon`}
							/>
						</Suspense>
					</div>
					<div className="vinisto-user-orders__orders__order-body__item__info__img">
						{isLoading ? (
							<Skeleton
								containerClassName="w-100 h-100"
								height="100%"
							/>
						) : (
							<img
								src={getBundleImage(
									get(bundleData, 'images', []),
									IMAGE_SIZE_THUMB_64x80
								)}
								alt={`${t({ id: 'alt.bundleImage' })}`}
							/>
						)}
					</div>
					<div className="vinisto-user-orders__orders__order-body__item__info">
						<div className="vinisto-user-orders__orders__order-body__item__info__data">
							<div className="vinisto-user-orders__orders__order-body__item__info__data__name mb-0">
								{isLoading ? (
									<Skeleton width="140px" />
								) : (
									getLocalizedValue(get(bundleData, 'name', []))
								)}
							</div>
						</div>
						<div className="vinisto-user-orders__orders__order-body__item__info__data">
							<div>
								{isLoading ? (
									<Skeleton width="90%" />
								) : (
									<div className="vinisto-user-orders__orders__order-body__item__info__data__winery">
										<BundleProducer
											flag={flag}
											name={producerName}
										/>
									</div>
								)}
							</div>
							<div></div>
						</div>
						<div className="vinisto-user-orders__orders__order-body__item__info__data mt-2 flex-wrap align-items-start">
							<div className="vinisto-user-orders__orders__order-body__item__prices__without-vat w-100">
								{t(
									{ id: 'bundle.supplierSmall.name' },
									{
										name: (
											<span className="vinisto-color-success fw-bolder">
												{supplierName.length > 0
													? supplierName
													: t({
															id: 'productDetail.seller.name.others',
													  })}
											</span>
										),
									}
								)}
							</div>
							<div className="vinisto-wine__count mt-0">
								<div
									className="position-relative"
									style={{ zIndex: 10 }}
								>
									<DirectQuantityBox
										bundle={bundle}
										isLoading={isLoading}
										orderLimitation={bundle?.orderLimitation}
										isBasket={true}
									/>
								</div>
							</div>
							<div className="vinisto-user-orders__orders__order-body__item__prices">
								{isLoading ? (
									<Skeleton
										count={1.6}
										width="100px"
									/>
								) : (
									<>
										<div className="vinisto-user-orders__orders__order-body__item__prices__total">
											{isDiscounted && (
												<div className="vinisto-user-orders__orders__order-body__item__prices__big-price">
													{getLocalizedPrice({
														price: originalTotalPriceWithVAT ?? 0,
														// @ts-expect-error possible "undefined" issue
														currency,
													})}
												</div>
											)}
											<div className="vinisto-user-orders__orders__order-body__item__prices__big-price-discounted">
												{getLocalizedPrice({
													price: totalPriceWithVAT,
													// @ts-expect-error possible "undefined" issue
													currency,
												})}
											</div>
										</div>
										<div className="vinisto-user-orders__orders__order-body__item__prices__without-vat">
											{`${t({
												id: 'basket.priceWithoutVAT',
											})} `}
											<span className="fw-normal">
												{getLocalizedPrice({
													price: totalPriceWithoutVAT,
													// @ts-expect-error possible "undefined" issue
													currency,
												})}
											</span>
										</div>
									</>
								)}
							</div>
							{Boolean(volumeDiscountVolume) && (
								<VolumeDiscount.Badge>
									{t(
										{ id: 'volumeDiscount.cartItem.firstLine' },
										{
											amount: t(
												{ id: 'amount.pcs' },
												{ count: volumeDiscountVolume }
											),
										}
									)}
									<br />
									<div className="text-end">
										{' '}
										{t(
											{ id: 'volumeDiscount.cartItem.secondLine' },
											{
												savings: (
													<VolumeDiscount.Emphasized>
														{getLocalizedPrice({
															price: totalSavings,
															// @ts-expect-error possible "undefined" issue
															currency,
														})}
													</VolumeDiscount.Emphasized>
												),
											}
										)}
									</div>
								</VolumeDiscount.Badge>
							)}

							<div className="vinisto-user-favorite__delete-wrap d-none">
								<div className="vinisto-user-favorite__delete-wrap__text">
									{t({ id: 'basket.removed.label' })}
									<br />
									<button className="vinisto-btn vinisto-clear-btn vinisto-color-success pointer fw-bolder">
										{t({ id: 'basket.removed.returnBack' })}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ActiveCartItemMobile;
