import { FC } from 'react';

import { IIconProps } from './Interfaces';

const HeaderPeopleIcon: FC<IIconProps> = ({ className }) => {
	return (
		<svg
			width={20}
			height={12}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 129 69"
			className={className}
		>
			<circle
				fill="#921442"
				cx="100.11"
				cy="28.33"
				r="10.51"
			/>
			<path
				fill="#921442"
				d="M59.97,25.65c7.09,0,12.82-5.74,12.82-12.82S67.04,0,59.97,0s-12.82,5.74-12.82,12.82,5.74,12.82,12.82,12.82Z"
			/>
			<path
				fill="#921442"
				d="M108.25,43.08h-8.1v-.02h-8.4c-4.72,0-8.79,2.64-10.95,6.49v-12.14c0-3.81-3.08-6.89-6.89-6.89h-28.24c-3.8,0-6.89,3.08-6.89,6.89v10.31c-2.32-2.8-5.77-4.63-9.67-4.63h-8.1v-.02h-8.4c-6.95,0-12.6,5.65-12.6,12.6v12.6h20.85v-1.27l.02,1.3h17.91l8.4.05h25.21l8.4-.07h19.19v-1.27l.02,1.3h20.85v-12.6c0-6.95-5.65-12.6-12.6-12.6l-.02-.02Z"
			/>
			<circle
				fill="#921442"
				cx="20.97"
				cy="28.33"
				r="10.51"
			/>
		</svg>
	);
};

export default HeaderPeopleIcon;
