import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { BasketContext } from 'Services/BasketService';
import { WarehouseContext } from 'Services/WarehouseService';
import getBundleLimitPerOrder from 'Helpers/getBundleLimitPerOrder';
import { LocalizationContext } from 'Services/LocalizationService';
import { getDiscountPriceValues } from 'vinisto_shared/src/price/get-discount-prices';

import { ITEM_NOT_AVAILABLE } from './Components/OutOfStockCartItemList/constants';
import {
	BasketOutOfStockItem,
	OutOfStockBundle,
} from './Components/OutOfStockCartItemList/interfaces';

import { VinistoHelperDllEnumsCurrency } from '@/api-types/product-api';

export const useOosItems = ({
	basketItems,
	handleOnChangeItemQuantity,
}: {
	basketItems: OutOfStockBundle[];
	handleOnChangeItemQuantity: (
		count: number,
		bundleId: string,
		isMoveToTemp?: boolean
	) => Promise<boolean>;
}) => {
	const basketContext = useContext(BasketContext);
	const warehouseContext = useContext(WarehouseContext);
	const {
		activeCurrency,
		countryOfSale,
		convertToActiveCurrencyIfPriceCurrencyIsDifferent,
	} = useContext(LocalizationContext);
	const { currency } = activeCurrency;

	const [basketItemsOutOfStock, setBasketItemsOutOfStock] = useState<
		Map<string, BasketOutOfStockItem>
	>(new Map());

	useEffect(() => {
		if (!Array.isArray(basketItems) || basketContext.isUpdatingCount) return;
		const newItemsOos: typeof basketItemsOutOfStock = new Map();

		basketItems.forEach((bundle) => {
			const availableQuantity = warehouseContext.getQuantity(bundle.bundleId);
			const isTemporaryUnavailable =
				bundle?.bundle?.temporaryUnavailable ?? false;
			const isDisabled = !bundle?.bundle?.isEnabled;

			const bundleLimitPerOrder = getBundleLimitPerOrder(
				bundle?.bundle?.orderLimitation
			);

			if (
				(availableQuantity === undefined ||
					availableQuantity >= bundle.quantity) &&
				(!bundleLimitPerOrder || bundleLimitPerOrder >= bundle.quantity) &&
				!isTemporaryUnavailable &&
				!isDisabled
			) {
				return;
			} else if (bundle.temp) {
				return;
			}

			const savedOosItem = basketItemsOutOfStock.get(bundle.bundleId);

			if (savedOosItem === undefined) {
				newItemsOos.set(bundle.bundleId, {
					cartItemType: (() => {
						if (isTemporaryUnavailable)
							return ITEM_NOT_AVAILABLE.TEMPORARY_UNAVAILABLE;
						if (bundleLimitPerOrder)
							return ITEM_NOT_AVAILABLE.ORDER_LIMIT_EXCEEDED;
						if (availableQuantity && availableQuantity > 0)
							return ITEM_NOT_AVAILABLE.QUANTITY_LESS_THAN_SELECTED;
						if (isDisabled) return ITEM_NOT_AVAILABLE.IS_DISABLED;
						return ITEM_NOT_AVAILABLE.OUT_OF_STOCK;
					})(),
					bundleData: bundle,
					isTemporaryUnavailable,
					isDisabled,
					bundleLimitPerOrder,
					availableWarehouseQty: availableQuantity || 0,
					similarBundlesCarousel: {
						loading: true,
						bundles: [],
					},
					itemPrice: bundle?.itemPrice,
					itemDiscountPrice: bundle?.itemDiscountPrice,
				});
			}
		});

		setBasketItemsOutOfStock((prev) => {
			const itemsOosPresentInBasket: [string, BasketOutOfStockItem][] = [];
			// keep only OOS items still present in basket and were not removed
			prev.forEach((itemOos) => {
				if (
					basketItems.find(
						(bundle) => bundle.bundleId === itemOos.bundleData.bundleId
					) &&
					!itemOos.isBeingRemoved
				) {
					itemsOosPresentInBasket.push([itemOos.bundleData.bundleId, itemOos]);
				}
			});
			return new Map([...itemsOosPresentInBasket, ...Array.from(newItemsOos)]);
		});
	}, [basketItems, basketContext, warehouseContext, countryOfSale, currency]);

	const handleRemoveOosItemFromBasket = useCallback(
		(bundleId: string) => () => {
			const bundleToRemove = basketItemsOutOfStock.get(bundleId);

			if (bundleToRemove === undefined) return;
			/* if we simply remove bundle from OOS list, it will appear in general list of basket
				items until response from API is received */
			setBasketItemsOutOfStock((prev) => {
				prev.set(bundleId, {
					...bundleToRemove,
					isBeingRemoved: true,
				});
				return new Map(prev);
			});
			handleOnChangeItemQuantity(
				0,
				bundleId,
				bundleToRemove.availableWarehouseQty !== 0
			);
		},
		[basketItemsOutOfStock, handleOnChangeItemQuantity]
	);

	const totalPriceBasketOos = useMemo(() => {
		const sum = { sumWithVat: 0, sumWithoutVat: 0 };
		basketItemsOutOfStock.forEach((cartItem) => {
			const quantity =
				cartItem.cartItemType === ITEM_NOT_AVAILABLE.ORDER_LIMIT_EXCEEDED
					? cartItem.bundleData.quantity - Number(cartItem.bundleLimitPerOrder)
					: cartItem.bundleData.quantity;

			const basePrice = cartItem?.itemPrice;

			const discountedPrice = cartItem?.itemDiscountPrice;

			const { discountedPriceWithoutVat, discountedPriceWithVat } =
				getDiscountPriceValues({
					quantityInBasket: quantity,
					basePrice,
					discountedPrice,
				});

			const priceWithVat = discountedPriceWithVat ?? basePrice?.valueWithVat;

			const priceWithoutVat = discountedPriceWithoutVat ?? basePrice?.value;

			const totalPriceWithVAT =
				convertToActiveCurrencyIfPriceCurrencyIsDifferent({
					price: priceWithVat ?? 0,
					priceCurrency:
						basePrice?.currency ?? VinistoHelperDllEnumsCurrency.CZK,
					activeCurrency: currency,
				}) * quantity;
			const totalPriceWithoutVAT =
				convertToActiveCurrencyIfPriceCurrencyIsDifferent({
					price: priceWithoutVat ?? 0,
					priceCurrency:
						basePrice?.currency ?? VinistoHelperDllEnumsCurrency.CZK,
					activeCurrency: currency,
				}) * quantity;

			sum.sumWithVat = sum.sumWithVat + totalPriceWithVAT;
			sum.sumWithoutVat = sum.sumWithoutVat + totalPriceWithoutVAT;
		});
		return sum;
	}, [
		basketItemsOutOfStock,
		currency,
		convertToActiveCurrencyIfPriceCurrencyIsDifferent,
	]);

	const totalStandardPriceBasketOos = useMemo(() => {
		const sum = { sumWithVat: 0, sumWithoutVat: 0 };
		basketItemsOutOfStock.forEach((cartItem) => {
			const quantity =
				cartItem.cartItemType === ITEM_NOT_AVAILABLE.ORDER_LIMIT_EXCEEDED
					? cartItem.bundleData.quantity - Number(cartItem.bundleLimitPerOrder)
					: cartItem.bundleData.quantity;

			const basePrice = cartItem?.itemPrice;

			const totalPriceWithVAT =
				convertToActiveCurrencyIfPriceCurrencyIsDifferent({
					price: basePrice.valueWithVat ?? 0,
					priceCurrency:
						basePrice?.currency ?? VinistoHelperDllEnumsCurrency.CZK,
					activeCurrency: currency,
				}) * quantity;

			const totalPriceWithoutVAT =
				convertToActiveCurrencyIfPriceCurrencyIsDifferent({
					price: basePrice.value ?? 0,
					priceCurrency:
						basePrice?.currency ?? VinistoHelperDllEnumsCurrency.CZK,
					activeCurrency: currency,
				}) * quantity;
			sum.sumWithVat = sum.sumWithVat + totalPriceWithVAT;
			sum.sumWithoutVat = sum.sumWithoutVat + totalPriceWithoutVAT;
		});
		return sum;
	}, [
		basketItemsOutOfStock,
		currency,
		convertToActiveCurrencyIfPriceCurrencyIsDifferent,
	]);

	return {
		basketItemsOutOfStock,
		setBasketItemsOutOfStock,
		totalPriceBasketOos,
		totalStandardPriceBasketOos,
		handleRemoveOosItemFromBasket,
	};
};
