import { FC, lazy, Suspense, useContext } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import getBundleImage, { IMAGE_SIZE_THUMB_64x80 } from 'Helpers/getBundleImage';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import { LocalizationContext } from 'Services/LocalizationService';
import BundleProducer from 'Components/ProductBox/Components/BundleProducer';
import Loader from 'Components/View/Loader';
import { getLocalizedPrice } from 'vinisto_shared/src/price/get-localized-price';
import getBundleLimitPerOrder from 'Helpers/getBundleLimitPerOrder';

import { ITEM_NOT_AVAILABLE } from '../../constants';
import { OutOfStockCartItemDetailProps } from '../interfaces';
const CloseIcon = lazy(() => import('Components/Icons/Close'));

import styles from './styles.module.css';

const OutOfStockCartItemDesktop: FC<OutOfStockCartItemDetailProps> = ({
	bundle,
	flag,
	producerName,
	currency,
	isDiscounted,
	originalTotalPriceWithVAT,
	totalPriceWithVAT,
	totalPriceWithoutVAT,
	cartItemType,
	message,
	handleShowSimilarProducts,
	isSimilarBundlesAvailable,
	handleOnKeepInBasket,
	handleRemoveItemFromBasket,
}) => {
	const localizationContext = useContext(LocalizationContext);

	const t = localizationContext.useFormatMessage();
	const getLocalizedValue = useLocalizedValue();

	const bundleDetailUrl = `/${t({
		id: 'routes.product.route',
	})}/${getLocalizedValue(bundle.url)}`;

	const bundleLimitPerOrder = getBundleLimitPerOrder(bundle.orderLimitation);
	const availableQuantity = bundle.availableCount;

	const purchaseableQuantity = bundleLimitPerOrder ?? availableQuantity;

	return (
		<div
			className={cx(
				'vinisto-user-orders__orders__order-body__item',
				'vinisto-cart__item',
				'border-0',
				{ discounted: isDiscounted }
			)}
		>
			<div className="vinisto-user-orders__orders__order-body__item__info">
				<Link
					className="vinisto-wine__item-overlay"
					to={bundleDetailUrl}
				/>
				<div className="vinisto-user-orders__orders__order-body__item__info__img">
					<img
						src={getBundleImage(bundle.images, IMAGE_SIZE_THUMB_64x80)}
						alt={`${t({ id: 'alt.bundleImage' })}`}
					/>
				</div>
				<div className="vinisto-user-orders__orders__order-body__item__info__data">
					<div className="vinisto-user-orders__orders__order-body__item__info__data__name">
						{getLocalizedValue(bundle.name)}
					</div>
					<div className="vinisto-user-orders__orders__order-body__item__info__data__winery">
						<BundleProducer
							flag={flag}
							name={producerName}
						/>
					</div>
				</div>
			</div>

			<div className="vinisto-user-orders__orders__order__warning">
				<div className={styles.centerBox}>
					<span className={styles.warning}>{message}</span>
				</div>
			</div>

			<div className="vinisto-wine__option">
				<div className={styles.centerBox}>
					{cartItemType === ITEM_NOT_AVAILABLE.OUT_OF_STOCK ||
					cartItemType === ITEM_NOT_AVAILABLE.TEMPORARY_UNAVAILABLE ||
					cartItemType === ITEM_NOT_AVAILABLE.IS_DISABLED ? (
						<button
							type="button"
							onClick={handleShowSimilarProducts}
							disabled={!isSimilarBundlesAvailable}
							className={cx('vinisto-btn vinisto-success-btn', styles.btnCta)}
						>
							{t({ id: 'cart.outOfStockItem.chooseSimilarItem' })}
						</button>
					) : (
						<button
							type="button"
							onClick={handleOnKeepInBasket}
							className={cx('vinisto-btn vinisto-success-btn', styles.btnCta)}
						>
							{t(
								{
									id: purchaseableQuantity
										? 'cart.outOfStockItem.keepInBasket.pluralized'
										: 'cart.outOfStockItem.keepInBasket',
								},
								{ count: purchaseableQuantity }
							)}
						</button>
					)}

					<button
						className={cx('btn', styles.removeBtn)}
						onClick={handleRemoveItemFromBasket}
					>
						<Suspense fallback={<Loader blank />}>
							<CloseIcon
								alt={t({ id: 'alt.delete' })}
								className={styles.removeIcon}
							/>
						</Suspense>
						<span className={styles.warning}>{t({ id: 'alt.remove' })}</span>
					</button>
				</div>
			</div>

			<div className={cx('vinisto-cart__item__price-remove', styles.price)}>
				<div className="vinisto-user-orders__orders__order-body__item__prices">
					<div className="vinisto-user-orders__orders__order-body__item__prices__total">
						{`${t({ id: 'basket.totalPrice' })} `}
						<span className="vinisto-user-orders__orders__order-body__item__prices__big-price">
							{getLocalizedPrice({
								price: originalTotalPriceWithVAT ?? totalPriceWithVAT,
								currency,
							})}
						</span>
						{isDiscounted && (
							<span className="vinisto-user-orders__orders__order-body__item__prices__big-price-discounted">
								{getLocalizedPrice({ price: totalPriceWithVAT, currency })}
							</span>
						)}
					</div>
					<div className="vinisto-user-orders__orders__order-body__item__prices__without-vat">
						{`${t({ id: 'basket.priceWithoutVAT' })} `}
						<span className="fw-bolder price-span">
							{getLocalizedPrice({ price: totalPriceWithoutVAT, currency })}
						</span>
					</div>
				</div>
			</div>

			<div className="vinisto-user-favorite__delete-wrap d-none">
				<div className="vinisto-user-favorite__delete-wrap__text">
					{t({ id: 'basket.removed.label' })}
					<br />
					<span className="color-primary pointer fw-bolder">
						{t({ id: 'basket.removed.returnBack' })}
					</span>
				</div>
			</div>
		</div>
	);
};

export default OutOfStockCartItemDesktop;
