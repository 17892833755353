import { useCallback, useContext, useState } from 'react';
import cx from 'classnames';
import { Link, useNavigate } from 'react-router-dom';
import { LocalizationContext } from 'Services/LocalizationService';
import Rating from 'Components/Rating';
import FilterDropdownArrowIcon from 'Components/Icons/FilterDropdownArrow';
import getSrcSet from 'Helpers/getSrcSet';
import { BasketContext } from 'Services/BasketService';
import getFlagSpecification from 'Helpers/getFlagSpecification';
import { WarehouseContext } from 'Services/WarehouseService';
import { ModalContext } from 'Components/Modal/context';
import { FILL_NICKNAME_MODAL, REVIEW_MODAL } from 'Components/Modal/constants';
import { AuthenticationContext } from 'Services/AuthenticationService/context';

import { BoughtProductProps } from './interface';
import styles from './styles.module.css';

const BoughtProduct = ({
	image,
	bundleId,
	bundleName,
	bundleUrl,
	bundleSpecificationDetails,
	bundle,
	orders,
	rating,
	refetchBoughtProducts,
}: BoughtProductProps) => {
	const { useFormatMessage } = useContext(LocalizationContext);
	const t = useFormatMessage();
	const { handleOnAddToBasket } = useContext(BasketContext);
	const navigate = useNavigate();
	const warehouseContext = useContext(WarehouseContext);
	const { handleOpenModal } = useContext(ModalContext);
	const { nickname } = useContext(AuthenticationContext).vinistoUser;

	const { shortVariety: producerName, component: flag } = getFlagSpecification(
		bundleSpecificationDetails || []
	);

	const availableQuantity = warehouseContext.getQuantity(bundleId);
	const {
		isDeleted,
		isSaleOver,
		temporaryUnavailable: isTemporaryUnavailable,
		isGift,
		isSet,
	} = bundle ?? {};

	const [isOpen, setIsOpen] = useState(false);

	const srcSet = getSrcSet(image?.domainUrls);

	const handleBuyAgain = useCallback(
		async ({ redirectToCrossell = true }) => {
			await handleOnAddToBasket(
				1,
				String(bundleId),
				bundleUrl,
				undefined,
				redirectToCrossell
			).then(() => {
				navigate(`/${t({ id: 'routes.cart.route' })}`);
			});
		},
		[bundleId, bundleUrl, handleOnAddToBasket, navigate, t]
	);

	const handleOpenRatingModal = useCallback(
		() =>
			nickname
				? handleOpenModal(REVIEW_MODAL, {
						bundleId,
						forceReload: refetchBoughtProducts,
						title: t({ id: 'modal.review.modalTitle' }, { name: bundleName }),
				  })
				: handleOpenModal(FILL_NICKNAME_MODAL),
		[nickname, handleOpenModal, bundleId, refetchBoughtProducts]
	);

	return (
		<div className={styles.product}>
			<div className={styles.imageWrap}>
				<img
					srcSet={srcSet ?? undefined}
					sizes={`(max-width: 767.98px) 60px, 80px`}
					src={image?.domainUrls?.['original_png']}
					alt={bundleName}
					className={styles.image}
				/>
			</div>
			<div className={styles.productInfo}>
				<Link
					to={`/${t({ id: 'routes.product.route' })}/${bundleUrl}`}
					className={styles.productName}
				>
					{bundleName}
				</Link>
				<div className={styles.producer}>
					{flag}
					<span className={styles.producerName}>{producerName}</span>
				</div>
			</div>
			{orders != null && (
				<div className={styles.orders}>
					{orders.length === 0 ? (
						<span>-</span>
					) : orders.length === 1 ? (
						<Link
							to={`/${t({ id: 'routes.user-section.route' })}/${t({
								id: 'routes.user-section.orders.route',
							})}?id=${orders[0].id}`}
							className={styles.orderLink}
						>
							{t(
								{ id: 'userSection.order.title' },
								{ value: orders[0].orderNumber }
							)}
						</Link>
					) : (
						<div>
							<button
								className={cx(styles.ordersButton, isOpen && styles.open)}
								onClick={() => setIsOpen(!isOpen)}
							>
								{t(
									{ id: 'userSection.bought-products.orders' },
									{ value: orders.length }
								)}
								<FilterDropdownArrowIcon />
							</button>
							<div className={cx(styles.ordersWrap, isOpen && styles.open)}>
								<div className={styles.overflow}>
									{orders.map((order, index) => (
										<Link
											to={`/${t({ id: 'routes.user-section.route' })}/${t({
												id: 'routes.user-section.orders.route',
											})}?id=${order.id}`}
											key={index}
											className={styles.orderLink}
										>
											{t(
												{ id: 'userSection.order.title' },
												{ value: order.orderNumber }
											)}
										</Link>
									))}
								</div>
							</div>
						</div>
					)}
				</div>
			)}
			<div className={styles.buttons}>
				<div className={styles.ratingWrap}>
					{rating?.stars != null ? (
						<div className={styles.rating}>
							<Rating
								defaultValue={rating.stars / 2}
								isLarge
								readOnly
							/>
						</div>
					) : (
						<button
							className={cx(styles.rateButton, {
								invisible: isSet,
							})}
							onClick={handleOpenRatingModal}
						>
							{t({ id: 'userSection.boughtProduct.rateProduct' })}
						</button>
					)}
				</div>
				<div className={styles.buyAgainWrap}>
					<button
						className={styles.buyAgainButton}
						onClick={() => handleBuyAgain({ redirectToCrossell: false })}
						disabled={
							availableQuantity === 0 ||
							isDeleted ||
							isSaleOver ||
							isTemporaryUnavailable ||
							isGift
						}
					>
						{t({ id: 'userSection.order.btn.buyAgain' })}
					</button>
				</div>
			</div>
		</div>
	);
};

export default BoughtProduct;
