import { IIconProps } from './Interfaces';

const EditIcon = ({
	className,
	title = '',
	alt = '',
	onClick,
	width = 20,
	height = 20,
}: IIconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 20 20"
			className={className}
			onClick={onClick}
		>
			<title>{title}</title>
			<desc>{alt}</desc>
			<g>
				<path
					d="M14.789,49.393l1.223,1.22c-.894.9-1.778,1.8-2.676,2.692-1.9,1.878-3.79,3.757-5.707,5.614a2.606,2.606,0,0,1-1.012.6c-1.806.554-3.623,1.075-5.441,1.587-.967.272-1.364-.126-1.092-1.089q.808-2.856,1.653-5.7a1.29,1.29,0,0,1,.308-.528q4.238-4.237,8.491-8.458a.665.665,0,0,1,.11-.072l.983,1.032c-1.1,1.1-2.231,2.217-3.357,3.341q-2.42,2.417-4.832,4.843a1.318,1.318,0,0,0-.315.517c-.311,1.01-.579,2.033-.907,3.037-.133.407.177.521.348.737a.5.5,0,0,0,.611.208c.949-.3,1.914-.561,2.864-.86a1.432,1.432,0,0,0,.546-.357q3.977-3.961,7.937-7.938a2.215,2.215,0,0,0,.264-.421"
					transform="translate(0 -41.186)"
					fill="#4d4d4e"
				/>
				<path
					d="M53.39,66.9l1.225,1.283-8.367,8.512c-.127-.356-.158-.785-.389-.992s-.665-.2-.974-.276l8.5-8.527"
					transform="translate(-40.857 -60.885)"
					fill="#4d4d4e"
				/>
				<path
					d="M151.295,1.224c.59-.545,1.088-1.348,2.1-1.208a2.078,2.078,0,0,1,1.08.442c.963.889,1.881,1.828,2.787,2.775a1.722,1.722,0,0,1,.1,2.185c-.279.362-.608.686-.9,1.009l-5.177-5.2"
					transform="translate(-137.711 0)"
					fill="#4d4d4e"
				/>
				<path
					d="M127.883,24.214l1.1-1.033,5.354,5.418-1.073,1.019-5.386-5.4"
					transform="translate(-116.404 -21.097)"
					fill="#4d4d4e"
				/>
			</g>
		</svg>
	);
};

export default EditIcon;
