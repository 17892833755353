import { DocumentHeaderAction } from 'Components/DocumentHeader/constants';
import { DocumentHeaderContext } from 'Components/DocumentHeader/context';
import PaginationNav from 'Components/Pagination';
import useGetBlogPosts from 'Hooks/useGetBlogPosts';
import { POST_LIMIT } from 'Hooks/useGetBlogPosts/constants';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import PostTagService from 'Services/ApiService/Cms/PostTag';
import { CMS_QUERY_TYPES } from 'Services/ApiService/Cms/constants';
import { useCallback, useContext, useEffect, useState } from 'react';
import useFormatMessage from 'Hooks/useFormatMessage';
import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import {
	DelimitedNumericArrayParam,
	useQueryParam,
	withDefault,
} from 'use-query-params';

import BlogBreadcrumb from './Components/Breadcrumb';
import BlogMainPost from './Components/MainPost';
import BlogPostDetail from './Components/PostDetail';
import BlogPostList from './Components/PostList';
import BlogTagDescription from './Components/TagDescription';
import BlogTagList from './Components/TagList';
import { BlogContext } from './context';
import { getMetaDescription, getPrettyTagName } from './helpers';
import styles from './styles.module.css';

const Blog = () => {
	const location = useLocation();
	const t = useFormatMessage();
	const getLocalizedValue = useLocalizedValue();

	const [activeTag, setActiveTag] = useState<string | null>('');
	const [activePost, setActivePost] = useState<string | null>('');

	const [page, setPage] = useQueryParam(
		'page',
		withDefault(DelimitedNumericArrayParam, [1])
	);

	const postsQuery = useGetBlogPosts({
		activeTag: activeTag ?? '',
		page: page[0] ? page[0] : 1,
	});

	const tagsQuery = useQuery([CMS_QUERY_TYPES.TAGS], () =>
		PostTagService.getList([
			{
				key: 'Limit',
				value: 100, // Some random high number
			},
			{
				key: 'SearchPublishedArticles',
				value: true,
			},
			{
				key: 'SortingColumn',
				value: 'TIME',
			},
			{
				key: 'IsSortingDescending',
				value: false,
			},
		])
	);

	const { dispatch } = useContext(DocumentHeaderContext);

	const postCount = postsQuery?.data?.count ?? 0;
	const totalPaginationPages =
		postCount <= POST_LIMIT ? 0 : Math.ceil(postCount / POST_LIMIT);

	useEffect(() => {
		const urlParts = location.pathname.split('/');
		const lastSegment = urlParts.pop() ?? '';
		const isBlogTag = urlParts.includes('tag');
		const isComunityIndex =
			lastSegment ===
			t({
				id: 'routes.community.route',
			});

		if (isBlogTag) {
			setActiveTag(lastSegment);
			setActivePost('');
		} else if (isComunityIndex) {
			setActiveTag('');
			setActivePost('');
		} else {
			setActivePost(lastSegment);
			setActiveTag('');
		}
	}, [activeTag, location.pathname, location.search, t]);

	const prettyTagName = getPrettyTagName(
		tagsQuery.data,
		activeTag,
		getLocalizedValue
	);

	const metaDescription = getMetaDescription(
		tagsQuery.data,
		activeTag,
		getLocalizedValue
	);

	const pageTitle = `${t(
		{ id: 'app.title.page' },
		{
			title: activeTag ? prettyTagName : 'Blog',
		}
	)}`;

	useEffect(() => {
		if (!tagsQuery.data) return;

		dispatch({
			type: DocumentHeaderAction.set,
			value: {
				title: pageTitle,
				description: metaDescription,
			},
		});
	}, [
		activeTag,
		dispatch,
		pageTitle,
		metaDescription,
		prettyTagName,
		tagsQuery.data,
	]);

	const currentPage = page.pop() ?? 1;

	const handleOnSelectNextPage = () => setPage([currentPage + 1]);

	const handleOnSelectPreviousPage = () =>
		currentPage > 1 && setPage([currentPage - 1]);

	const handleOnSelectPage = useCallback(
		(page: number) => setPage([page]),
		[setPage]
	);

	return (
		<BlogContext.Provider
			value={{
				tags: tagsQuery.data,
				isTagsLoading: tagsQuery.isLoading,
				activeTagUrl: activeTag,
				posts: postsQuery.data?.articles ?? [],
				activePostUrl: activePost,
				isPostsLoading: postsQuery.isLoading,
			}}
		>
			<section
				id="content-wrapper"
				className={styles.blog}
			>
				{(!activePost || postsQuery.data?.articles?.length === 0) && (
					<>
						<BlogBreadcrumb />
						<BlogTagList />
					</>
				)}

				<BlogTagDescription key={activeTag} />
				{activePost ? (
					<BlogPostDetail />
				) : (
					<>
						<BlogMainPost />
						<BlogPostList />
						<div className="container">
							<PaginationNav
								className="noLoadmore"
								currentPage={currentPage}
								totalPaginationPages={totalPaginationPages}
								itemsToLoadMore={0}
								handleOnLoadMore={() => null}
								showLoadMore={false}
								handleOnSelectPreviousPage={handleOnSelectPreviousPage}
								handleOnSelectNextPage={handleOnSelectNextPage}
								handleOnSelectPage={handleOnSelectPage}
							/>
						</div>
					</>
				)}
			</section>
		</BlogContext.Provider>
	);
};

export default Blog;
