import { FC, lazy, Suspense, useContext, useEffect } from 'react';
import cx from 'classnames';
import { DocumentHeaderAction } from 'Components/DocumentHeader/constants';
import { DocumentHeaderContext } from 'Components/DocumentHeader/context';
import { LocalizationContext } from 'Services/LocalizationService';
import Card from 'Components/View/Card';
import Container from 'Components/View/Container';
import ContainerFullWidth from 'Components/View/ContainerFullWidth';
import TextHighlighted from 'Components/View/TextHighlighted';
import Loader from 'Components/View/Loader';
import ImageLocal from 'Components/View/ImageLocal';

const AboutUs1Icon = lazy(() => import('Components/Icons/AboutUs1'));
const AboutUs2Icon = lazy(() => import('Components/Icons/AboutUs2'));
const AboutUs3Icon = lazy(() => import('Components/Icons/AboutUs3'));
const AboutUs4Icon = lazy(() => import('Components/Icons/AboutUs4'));
const MichalTitle = lazy(() => import('Components/Icons/MichalTitle'));

import styles from './styles.module.css';

const AboutUs: FC = () => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const { dispatch } = useContext(DocumentHeaderContext);
	const title = `${t(
		{ id: 'app.title.page' },
		{ title: `${t({ id: 'routes.aboutUs.name' })}` }
	)}`;

	useEffect(() => {
		dispatch({
			type: DocumentHeaderAction.setTitle,
			value: title,
		});
	}, [dispatch, title]);

	return (
		<section id="content-wrapper">
			<ContainerFullWidth>
				<Card className={styles.headingWrap}>
					<Suspense fallback={<Loader blank />}>
						<AboutUs1Icon className="d-none d-sm-block" />
					</Suspense>
					<h2 className={styles.heading}>
						Tržiště. Služba. Platforma. Komunita. Jsme{' '}
						<TextHighlighted>vinisto.</TextHighlighted>
					</h2>
					<Suspense fallback={<Loader blank />}>
						<AboutUs1Icon className="d-none d-sm-block" />
					</Suspense>
				</Card>
			</ContainerFullWidth>

			<Container>
				<div className="col-12 col-xl-6 mb-3 mb-xl-0">
					<Card className="p-3 vinisto-font-18">
						<p>
							<TextHighlighted>#jsmevinisto.</TextHighlighted> Jedinečná
							symbióza fanoušků a milovníků vína a kvalitních destilátů s
							vinaři, destiléry a prodejci. Místo, kde každý ověřený producent a
							prodejce může jednoduše nabízet své zboží zákazníkům a místo, kde
							si mohou zákazníci vybírat z nespočtu vín a špičkových destilátů.
						</p>
						<p className="mb-0">
							Ochutnávejme, hodnoťme a pomáhejme budovat a zlepšovat komunitu
							vín a destilátů v České republice. Nakupovat a konzumovat je
							zábava, povznesme ji však na vyšší úroveň. Společně pomozme ke
							kultivaci prostředí a ukažme, že pouze produkt vyrobený s
							dostatečnou péčí si zaslouží pozornost široké veřejnosti.
						</p>
					</Card>
				</div>
				<div className="col-12 col-xl-6">
					<Card className="p-3 vinisto-font-18">
						<div className={styles.dots}>
							<ul className={styles.list}>
								<li className={styles.item}>
									vinař, destilér nebo prodejce nahraje na{' '}
									<TextHighlighted>vinisto</TextHighlighted> své produkty
								</li>
								<li className={styles.item}>
									zákazník si jednoduše nakoupí víno či destilát přímo od zdroje
								</li>
								<li className={styles.item}>
									<TextHighlighted className="me-1">vinisto</TextHighlighted>{' '}
									vše zabalí, pošle a druhý den máte objednávku u sebe
								</li>
								<li className={styles.item}>
									zákazník může zboží hodnotit a pomáhat tak ostatním ve výběru
								</li>
							</ul>
						</div>
					</Card>
				</div>
			</Container>

			<ContainerFullWidth>
				<Card className={styles.headingWrap}>
					<Suspense fallback={<Loader blank />}>
						<AboutUs2Icon className="d-none d-sm-block" />
					</Suspense>
					<h2 className={styles.heading}>
						<TextHighlighted>vinisto</TextHighlighted>. Červeným, portským, či
						koňakem to nekončí
					</h2>
					<Suspense fallback={<Loader blank />}>
						<AboutUs2Icon className="d-none d-sm-block" />
					</Suspense>
				</Card>
			</ContainerFullWidth>

			<Container>
				<div className="col-12 col-xl-6 mb-3 mb-xl-0">
					<Card className="p-3 vinisto-font-18">
						<div className={styles.dots}>
							<ul className={styles.list}>
								<li className={styles.item}>velký výběr produktů</li>
								<li className={styles.item}>rychlá a kvalitní doprava</li>
								<li className={styles.item}>
									speciální akce <br />a bezkonkurenční ceny
								</li>
								<li className={styles.item}>šetříme životní prostředí</li>
							</ul>
						</div>
					</Card>
				</div>
				<div className="col-12 col-xl-6">
					<Card className="p-3 vinisto-font-18">
						<p>
							Díky unikátnímu systému online tržiště nabízíme na našich
							stránkách velký výběr produktů s přímým propojením na vinaře,
							destiléra, nebo prodejce. Přímo ti rozhodují, který produkt bude
							zařazen do naší nabídky a díky silnému propojení se vznikající
							komunitou <TextHighlighted>Klubu vinisto</TextHighlighted> získají
							velmi rychlou zpětnou vazbu týkající se kvality daného produktu.
						</p>
						<p className="mb-0">
							Víno není jen bílé nebo červené. Díky tomuto modelu představujeme
							na <TextHighlighted>vinistu</TextHighlighted> velkou nabídku – ať
							jste milovníkem suchého bílého vína, vína portského nebo
							kvalitního ginu, na našich stránkách si vyberete. Navíc model
							tržiště pomáhá v udržování férové ceny a propracovanému systému
							slevových akcí.
						</p>
					</Card>
				</div>
			</Container>

			<ContainerFullWidth>
				<Card className={styles.headingWrap}>
					<Suspense fallback={<Loader blank />}>
						<AboutUs3Icon className="d-none d-sm-block" />
					</Suspense>
					<h2 className={styles.heading}>
						Budujte s námi <TextHighlighted>Klub vinisto</TextHighlighted>,
						bránu do vybrané společnosti
					</h2>
					<Suspense fallback={<Loader blank />}>
						<AboutUs3Icon className="d-none d-sm-block" />
					</Suspense>
				</Card>
			</ContainerFullWidth>

			<Container>
				<div className="col-12 col-xl-6 mb-3 mb-xl-0">
					<Card className="p-3 vinisto-font-18">
						<p>
							<TextHighlighted>vinisto</TextHighlighted> není obyčejný obchod s
							vínem. Jsme online tržiště. Jsme komunita. Jsme fanoušci a
							milovníci vína a destilátů.{' '}
							<TextHighlighted>vinisto</TextHighlighted> si klade za cíl
							propojit běžné zákazníky přímo s vinaři, destiléry a prodejci.
							Přesně proto pro Vás připravujeme{' '}
							<TextHighlighted>Klub vinisto</TextHighlighted>, který bude vždy
							základem naší komunikace a cesty. Spojení fanoušků vína a
							destilátů do jednoho celku. Registrujte se a hodnoťte, pište
							komentáře a články, pomáhejte s budováním silné komunity.
						</p>
						<p className="mb-0">
							Vašich zkušeností si vážíme, a proto připravujeme věrnostní
							systém, kterým každou Vaši aktivitu ohodnotíme. Pomozte v růstu
							lokálním, ovšem kvalitním subjektům, které si to zaslouží. Díky
							funkčnímu ekosystému{' '}
							<TextHighlighted>Klubu vinisto</TextHighlighted> bude každý
							zákazník lépe rozumět nabídce vín a destilátů a bude mu umožněno
							se snadněji rozhodnout a zorientovat při výběru produktu.{' '}
							<TextHighlighted>#jsmevinisto</TextHighlighted>
						</p>
					</Card>
				</div>
				<div className="col-12 col-xl-6">
					<Card className="p-3 vinisto-font-18">
						<div className={styles.dots}>
							<ul className={styles.list}>
								<li className={styles.item}>získávejte hodnotné odměny</li>
								<li className={styles.item}>pomozte kultivaci prostředí</li>
								<li className={styles.item}>
									speciální akce <br />a bezkonkurenční ceny
								</li>
								<li className={styles.item}>
									staňte se členem jedinečné komunity
								</li>
							</ul>
						</div>
					</Card>
				</div>
			</Container>

			<ContainerFullWidth>
				<Card className={styles.headingWrap}>
					<Suspense fallback={<Loader blank />}>
						<AboutUs4Icon className="d-none d-sm-block" />
					</Suspense>
					<h2 className={styles.heading}>
						Rozhovor s Michalem Kvíčalou, CEO a autorem{' '}
						<TextHighlighted>vinista</TextHighlighted>
					</h2>
					<Suspense fallback={<Loader blank />}>
						<AboutUs4Icon className="d-none d-sm-block" />
					</Suspense>
				</Card>
			</ContainerFullWidth>

			<ContainerFullWidth>
				<Card className="p-3">
					<div className={styles.interview}>
						<div className={styles.bubble}>
							<h3 className={styles.question}>
								Co je vlastně <TextHighlighted>vinisto</TextHighlighted>?
							</h3>
							<p className={cx(styles.answer, 'vinisto-font-18')}>
								<TextHighlighted>vinisto</TextHighlighted> je{' '}
								<span className="fw-bolder color-primary">
									technologicko-logistická platforma pro prodej vína a destilátů
								</span>
								, zkráceně tržiště. Zní to složitě, nicméně jak pro zákazníky,
								tak prodejce, je systém velmi jednoduchý, transparentní, s
								velkým přesahem do odbornosti, kterou produkty vína a destilátů
								potřebují. Tržiště, služba, platforma, komunita. To vše je{' '}
								<TextHighlighted>vinisto</TextHighlighted>.
							</p>
						</div>
						<div className={cx(styles.bubble, 'ms-auto')}>
							<h3 className={styles.question}>
								Říkáte, že <TextHighlighted>vinisto</TextHighlighted> je tržiště
								a nikoli e-shop. Co to znamená??
							</h3>
							<p className={cx(styles.answer, 'vinisto-font-18')}>
								Ano, <TextHighlighted>vinisto</TextHighlighted> je online
								tržiště a nikdy nechceme být pouze elektronickým obchodem. Věřím
								totiž, že tržnicový systém prodeje na internetu je budoucnost
								e-commerce a jelikož jsme vinaři a zároveň distributoři vína a
								destilátů, určili jsme si za cíl vytvořit specializované místo,
								které bude{' '}
								<span className="fw-bolder color-primary">
									vinaře a destiléry propojovat se zákazníky
								</span>
								. Také jim pomůže s logistikou, skladováním, balením a
								doručením. <TextHighlighted>vinisto</TextHighlighted> umožní
								prodávajícím budovat si kvalitní a silný prodejní kanál, a to
								čistě ve své vlastní režii – od toho jaké produkty budou
								prodávat až po to, za jakou cenu budou na{' '}
								<TextHighlighted>vinisto</TextHighlighted> nabízeny.
							</p>
						</div>
						<div className={styles.bubble}>
							<h3 className={styles.question}>
								A co tedy tržiště znamená z pohledu zákazníka?
							</h3>
							<p className={cx(styles.answer, 'vinisto-font-18')}>
								Pro <TextHighlighted>vinisto</TextHighlighted> jsou prodávající
								stejně důležití jako nakupující, tedy zákazníci. Zároveň, což je
								velmi důležité, je <TextHighlighted>vinisto</TextHighlighted>{' '}
								místem, kde si zákazník nakupí přímo od vinaře, destiléra,
								importéra či distributora. Místo, kde si zákazník bude moci
								jeden produkt nakoupit od více prodejců a vybrat si tak, od koho
								si daný produkt koupí. Velkou výhodou je tedy fakt, že nákupem
								daného zboží podpoříte jakéhokoli prodávajícího napřímo.
								Zákazník má díky tomu možnost podpořit jak velké importéry, tak
								malé české vinaře s vlastní lokální vinicí. Zároveň pokud si
								nakoupíte od více prodejců více různých produktů, přijdou vám v
								jedné zásilce, za jedny peníze.
							</p>
						</div>
						<div className={cx(styles.bubble, 'ms-auto')}>
							<h3 className={styles.question}>
								Pro <TextHighlighted>vinisto</TextHighlighted> je důležité
								komunita. Jakým způsobem bude tato komunita budována?
							</h3>
							<p className={cx(styles.answer, 'vinisto-font-18')}>
								Pro korektní fungování je komunita velmi důležitá a{' '}
								<TextHighlighted>vinisto</TextHighlighted> je zároveň místo, na
								kterém tvoříme silnou komunitu okolo vína a destilátu. Využíváme
								k tomu specializované články, uživatelské hodnocení, prezentace
								a detailní informace o všem a o všech – od produktů po prodejce.
								Cílem je vytvořit komunitu tvořenou z vinařů, destilérů,
								zákazníků, someliérů, fanoušků jednotlivých produktů a výrobců.
								Ultimátním cílem je komplexní uchopení všech témat okolo vín a
								destilátů, neboť podpora tohoto segmentu se nám v současné době
								jeví jako nedostatečná. Současně je potřeba tvořit komunitu tak,
								aby všichni výše zmínění byli ve výsledku takříkajíc na jedné
								lodi.
							</p>
						</div>
						<div className={styles.bubble}>
							<h3 className={styles.question}>
								Co od <TextHighlighted>vinista</TextHighlighted> můžeme
								očekávat?
							</h3>
							<p className={cx(styles.answer, 'vinisto-font-18')}>
								<TextHighlighted>vinisto</TextHighlighted> právě začíná, na
								některých funkcionalitách stále pracujeme, nicméně vaše
								shovívavost není na místě. Pokud se na webu nedozvíte, proč
								ještě něco není hotovo, případně Vám nebude něco fungovat,
								prosíme o vaši zpětnou vazbu, a to buď na podpora@vinisto.cz,
								nebo se můžete obrátit přímo na mě – Michala Kvíčalu,
								spolumajitele a CEO <TextHighlighted>vinista</TextHighlighted>.
								Kontaktovat mě můžete na emailu kvicala.michal@vinisto.cz.
								Jakákoli zpětná vazba pomůže v budování{' '}
								<TextHighlighted>vinista</TextHighlighted> a zlepšení funkčnosti
								celé platformy.
							</p>
						</div>
					</div>
					<div className={styles.boss}>
						<div className={styles.wrapper}>
							<ImageLocal
								fileName="michal-kvicala.webp"
								alt="Michal Kvíčala"
								title="Michal Kvíčala"
								className={styles.michal}
							/>
							<Suspense fallback={<Loader blank />}>
								<MichalTitle className={styles.title} />
							</Suspense>
						</div>
					</div>
				</Card>
			</ContainerFullWidth>
		</section>
	);
};

export default AboutUs;
