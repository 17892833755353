import { lazy, Suspense, useCallback, useContext } from 'react';
import cx from 'classnames';
import { Form } from 'react-final-form';
import { InputEmail, InputPassword } from 'Components/Form';
import { requireEmail } from 'Components/Form/validators';
import { LocalizationContext } from 'Services/LocalizationService';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import Loader from 'Components/View/Loader';
const AdvantageIcon = lazy(() => import('Components/Icons/Advantage'));
const AdvantageGroupIcon = lazy(
	() => import('Components/Icons/AdvantageGroup')
);

import styles from './styles.module.css';

interface RegisterFormProps {
	email?: string;
}

const RegisterForm = ({ email }: RegisterFormProps) => {
	const localizationContext = useContext(LocalizationContext);
	const authenticationContext = useContext(AuthenticationContext);

	const t = localizationContext.useFormatMessage();
	const handleOnSubmit = useCallback(
		(formValues: Record<any, any>): void => {
			authenticationContext.handleOnRegister(
				formValues.email,
				formValues.password,
				false,
				true
			);
		},
		[authenticationContext]
	);

	const initialValues = {} as Record<any, any>;

	if (email) {
		initialValues.email = email;
	}

	return (
		<Form
			initialValues={initialValues}
			onSubmit={handleOnSubmit}
			render={({ handleSubmit }) => {
				return (
					<form onSubmit={handleSubmit}>
						<h2
							className={cx(
								'vinisto-heading vinisto-color-success underline mt-2 mt-xl-4',
								styles.heading
							)}
						>
							{t(
								{
									id: 'order.finalRegisterForm.title',
								},
								{
									subtitle: (
										<span className="fw-bold">
											{t({ id: 'order.finalRegisterForm.title.subtitle' })}
										</span>
									),
								}
							)}
						</h2>
						<div className={styles.content}>
							<div className={styles.form}>
								<div className={styles.email}>
									<InputEmail validate={requireEmail} />
								</div>

								<div className={styles.passwordSubmit}>
									<div className={styles.password}>
										<InputPassword
											label={`${t({
												id: 'order.finalRegisterForm.password.label',
											})}`}
										/>
									</div>
									<button
										type="submit"
										className={cx(
											'vinisto-btn vinisto-success-btn',
											styles.submit
										)}
									>
										{t({
											id: 'order.finalRegisterForm.submit',
										})}
									</button>
								</div>
							</div>
							<div>
								<div className={styles.registerHeading}>
									{t({ id: 'order.finalRegisterForm.advantage' })}
								</div>
								<div className={styles.advantagesGrid}>
									<div>
										<Suspense fallback={<Loader blank />}>
											<AdvantageIcon alt={t({ id: 'alt.advantage' })} />
										</Suspense>
										<span>{t({ id: 'order.finalRegisterForm.text1' })}</span>
									</div>
									<div>
										<Suspense fallback={<Loader blank />}>
											<AdvantageIcon alt={t({ id: 'alt.advantage' })} />
										</Suspense>
										<span>{t({ id: 'order.finalRegisterForm.text2' })}</span>
									</div>
									<div>
										<Suspense fallback={<Loader blank />}>
											<AdvantageIcon alt={t({ id: 'alt.advantage' })} />
										</Suspense>
										<span>{t({ id: 'order.finalRegisterForm.text3' })}</span>
									</div>
									<div>
										<Suspense fallback={<Loader blank />}>
											<AdvantageIcon alt={t({ id: 'alt.advantage' })} />
										</Suspense>
										<span>{t({ id: 'order.finalRegisterForm.text4' })}</span>
									</div>
									<div>
										<Suspense fallback={<Loader blank />}>
											<AdvantageGroupIcon alt={t({ id: 'alt.advantage' })} />
										</Suspense>
										<span>{t({ id: 'order.finalRegisterForm.text5' })}</span>
									</div>
								</div>
							</div>
						</div>
					</form>
				);
			}}
		/>
	);
};

export default RegisterForm;
