import { useContext } from 'react';
import cx from 'classnames';
import { UserSectionLink } from 'Pages/UserSection/interfaces';
import { Link } from 'react-router-dom';
import useUserSectionLinks from 'Pages/UserSection/hooks';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { LocalizationContext } from 'Services/LocalizationService';

import styles from './styles.module.css';

const UserTileMenu = () => {
	const t = useContext(LocalizationContext).useFormatMessage();

	const { isLoggedIn } = useContext(AuthenticationContext);
	const links: UserSectionLink[] = useUserSectionLinks(isLoggedIn);

	return (
		<div className={styles.userTileMenuWrap}>
			{links.map((link, index) => (
				<Link
					to={
						`/${t({ id: 'routes.user-section.route' })}/` + (link.route || '#')
					}
					key={index}
					className={cx(styles.userTileMenuLink, {
						[styles.clubCoupons]: link.flags?.includes('clubCoupons'),
					})}
				>
					{link.icon ?? null}
					{link.name ?? ''}
				</Link>
			))}
		</div>
	);
};

export default UserTileMenu;
