import { IIconProps } from './Interfaces';

const ThreeBottlesIcon = ({ className }: IIconProps) => {
	return (
		<svg
			width={22}
			height={28}
			viewBox="0 0 22 28"
			className={className}
		>
			<g transform="translate(-525.249 -283.75)">
				<path
					d="M292.642,130.278a3.057,3.057,0,0,0-.919-2.193c-.379-.4-.755-.806-1.13-1.21a1.731,1.731,0,0,1-.5-1.232c.014-1.713,0-3.425,0-5.138a.556.556,0,0,0-.606-.483h-1.176a.555.555,0,0,0-.606.483c0,1.713-.012,3.425,0,5.138a1.72,1.72,0,0,1-.5,1.232q-.561.607-1.129,1.21a3.051,3.051,0,0,0-.919,2.193c.03,4.081.012,8.162.014,12.243a2.471,2.471,0,0,0,.029.44c.113.643.708.787,1.571.787h4.251c.861,0,1.456-.144,1.571-.787a2.609,2.609,0,0,0,.028-.44C292.629,138.44,292.612,134.359,292.642,130.278Zm-5.277.817h0l-.154,1.117,0,.011a4.24,4.24,0,0,1,.1-2.288,6.927,6.927,0,0,1,1.6-1.889C288.3,129.048,287.374,129.93,287.366,131.1Z"
					transform="translate(240.834 164.479)"
					fill="#f7fbf2"
					stroke="#707070"
					strokeWidth="1.5"
				/>
				<path
					d="M292.642,130.278a3.057,3.057,0,0,0-.919-2.193c-.379-.4-.755-.806-1.13-1.21a1.731,1.731,0,0,1-.5-1.232c.014-1.713,0-3.425,0-5.138a.556.556,0,0,0-.606-.483h-1.176a.555.555,0,0,0-.606.483c0,1.713-.012,3.425,0,5.138a1.72,1.72,0,0,1-.5,1.232q-.561.607-1.129,1.21a3.051,3.051,0,0,0-.919,2.193c.03,4.081.012,8.162.014,12.243a2.471,2.471,0,0,0,.029.44c.113.643.708.787,1.571.787h4.251c.861,0,1.456-.144,1.571-.787a2.609,2.609,0,0,0,.028-.44C292.629,138.44,292.612,134.359,292.642,130.278Zm-5.277.817h0l-.154,1.117,0,.011a4.24,4.24,0,0,1,.1-2.288,6.927,6.927,0,0,1,1.6-1.889C288.3,129.048,287.374,129.93,287.366,131.1Z"
					transform="translate(253.625 164.479)"
					fill="#f7fbf2"
					stroke="#707070"
					strokeWidth="1.5"
				/>
				<path
					d="M292.642,130.278a3.057,3.057,0,0,0-.919-2.193c-.379-.4-.755-.806-1.13-1.21a1.731,1.731,0,0,1-.5-1.232c.014-1.713,0-3.425,0-5.138a.556.556,0,0,0-.606-.483h-1.176a.555.555,0,0,0-.606.483c0,1.713-.012,3.425,0,5.138a1.72,1.72,0,0,1-.5,1.232q-.561.607-1.129,1.21a3.051,3.051,0,0,0-.919,2.193c.03,4.081.012,8.162.014,12.243a2.471,2.471,0,0,0,.029.44c.113.643.708.787,1.571.787h4.251c.861,0,1.456-.144,1.571-.787a2.609,2.609,0,0,0,.028-.44C292.629,138.44,292.612,134.359,292.642,130.278Zm-5.277.817h0l-.154,1.117,0,.011a4.24,4.24,0,0,1,.1-2.288,6.927,6.927,0,0,1,1.6-1.889C288.3,129.048,287.374,129.93,287.366,131.1Z"
					transform="translate(247.131 167.234)"
					fill="#f7fbf2"
					stroke="#707070"
					strokeWidth="1.5"
				/>
			</g>
		</svg>
	);
};

export default ThreeBottlesIcon;
